@import "/src/styles/mixins";
@import "/src/styles/pallete";

.totalInfo {
  @include fontRem(14, 500, 20);
  color: var(--black);
}

.heading {
  @include fontRem(20, 700, 20);
  color: var(--black) !important;
}
