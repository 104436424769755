@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tableWrapper {
  overflow: hidden;
  :global {
    .ant-table-wrapper {
      .ant-table-container {
        &::after,
        &::before {
          content: none !important;
        }
      }
    }
    .ant-table-body {
      min-height: 100px;
    }
  }

  :global {
    .ant-table {
      overflow: auto;
      background-color: transparent;
      table {
        padding-top: 12px;
      }
    }
    .ant-table-thead {
      tr {
        td {
          background-color: var(--white) !important;
          border-color: var(--white) !important;
        }
        th {
          color: var(--blueGray);
          background: transparent;
          padding: 0 8px;
          @include fontRem(14, 500, 20);
          height: 33px;
          border: 0;
          &::before {
            display: none;
          }
          &.ant-table-cell-fix-left,
          &.ant-table-cell-fix-right {
            background: var(--white);
          }
          &.ant-table-selection-column {
            text-align: initial;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        &.activeRow {
          td {
            background-color: rgba(55, 159, 255, 0.2) !important;
          }
        }

        td {
          background-color: var(--white) !important;
          padding: 8px;
          color: var(--black);
          border: 0;
          @include fontRem(14, 500, 20);
          cursor: pointer;
          div {
            margin: 0;
          }
        }
        &:hover {
          td {
            background-color: var(--tableHoverColor) !important;
          }
        }
      }
    }
    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        @include flex-center-vert;
        height: 30px;
        width: 30px !important;
        justify-content: center;
        button {
          color: var(--blueGrayWhite);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGrayWhite);
          padding: 0;
        }

        &.ant-pagination-total-text {
          order: 3;
          width: auto !important;
          color: var(--blueGrayWhite);
        }
        &.ant-pagination-item {
          width: auto;
          min-width: unset;
          margin-inline-end: 6px;
        }
        &.ant-pagination-item-active {
          background-color: transparent;
          border-color: var(--paginationActive);
          border-width: 2px;
          &:hover {
            color: var(--black);
          }
        }
        &.ant-pagination-prev,
        &.ant-pagination-next {
          background-color: var(--gray);

          a {
            @include flex-center-vert;
            justify-content: center;
            width: 100%;
            height: 100%;
            svg {
              width: 14px;
              height: 14px;
            }
          }
          &.ant-pagination-next {
            a {
              transform: rotate(180deg);
            }
          }
        }

        &.ant-pagination-options {
          width: auto;
          margin-inline: 20px;
          .ant-select-selector {
            border: none;
            color: var(--black);
            @include fontRem(14, 500);
            background-color: transparent;
            .ant-select-selection-item {
              color: var(--black);
              padding-inline-end: 14px;
            }
          }
          .ant-select-suffix {
            width: 9px;
            height: 5px;
            background-size: contain;
            background: no-repeat;
            mask: url("data:image/svg+xml;charset=utf8,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.43416 0L4.49414 3.0904L7.55412 0L8.49414 0.951402L4.49414 5L0.494141 0.951402L1.43416 0Z' fill='currentColor'/%3E%3C/svg%3E");
            background-color: var(--black);
            svg {
              display: none;
            }
          }
        }
        &.ant-pagination-jump-next,
        &.ant-pagination-jump-prev {
          align-items: flex-end;
          span {
            color: var(--black) !important;
          }
          .ant-pagination-item-ellipsis {
            color: var(--black);
          }
        }
      }
      .ant-select-dropdown {
        background-color: var(--white);
        .ant-select-item-option {
          @include flex-center;
          justify-content: center;
          * {
            color: var(--black);
            text-align: center;
          }
          &.ant-select-item-option-active {
            background-color: var(--gray);
          }
          &.ant-select-item-option-selected {
            background-color: var(--gray);
          }
        }
      }
    }
  }
}

.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
  [class*="tableRateItem"] {
    box-shadow: none;
    border: none;
    margin-left: -12px;
  }
}
.actionBlock {
  display: flex;
  gap: 15px;
  .trashIcon {
    width: 30px;
    height: 30px;
    @include flex-center-vert;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    background: var(--white);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .disabledTrashIcon {
    box-shadow: none;
    opacity: 0.6;
  }
}
.actionsWrapper {
  @include flex-center-vert;
  justify-content: flex-end;
  gap: 20px;
  button {
    width: 30px !important;
    height: 30px !important;
    background-color: var(--white);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    @include flex-center;
    border: 0;
    outline: none;
    transition: 0.1s;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed !important;
      svg {
        cursor: not-allowed;
      }
      &:hover {
        transform: scale(1);
      }
    }
    &:hover {
      transform: scale(1.1);
    }
    &:nth-child(2) {
      svg {
        path {
          fill: var(--blue);
        }
      }
    }
  }
  &.tooltipActionsWrapper {
    gap: 12px;
    button {
      &:nth-child(2) {
        svg {
          path {
            fill: initial;
          }
        }
      }
    }
  }
}

.emailBlock {
  margin-right: 3px;
  @include fontRem(14, 500, 17);
  color: var(--black);
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}
.heading {
  @include fontRem(16, 700, 20);
  color: var(--black);
}
.popoverOverlay {
  // width: 800px;
  .infoParagraph {
    display: block;
  }
  :global {
    .ant-divider {
      margin: 2px 0;
    }
    .ant-popover-inner {
      background-color: var(--white);
      border-radius: 6px;
      padding: 25px;
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
}
.popoverIcon {
  width: fit-content;
  @include flex-center-vert;
}
