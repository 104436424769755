@import "/src/styles/mixins";
@import "/src/styles/pallete";

.customDatePickerWrapper {
    &.error {
        :global {
            .react-datepicker-wrapper {
                input {
                    border-color: var(--persimmonRed);
                }
            }
        }
    }
    :global {
        .react-datepicker__day.react-datepicker__day--keyboard-selected {
            background-color: transparent;
        }
        .react-datepicker-popper {
            z-index: 4;
        }
        .react-datepicker {
            display: flex;
            font-family: inherit;
            background-color: var(--white);
            border: 1px solid var(--black);
            border-radius: 3px;

            .react-datepicker__triangle {
                display: none;
            }
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__month-text,
            .react-datepicker__quarter-text,
            .react-datepicker__year-text {
                width: 40px;
                margin: 0;

                border-radius: 0;
                color: var(--black);
                outline: none;

                > div {
                    padding: 0.166rem;
                }
                &:hover {
                    background-color: var(--gray);
                }
                &.react-datepicker__day--selected {
                    background-color: rgba(56, 141, 255, 0.4);
                }
            }
            .react-datepicker__day--disabled {
                cursor: not-allowed;
                opacity: 0.5;
                background-color: transparent !important;
            }
            .react-datepicker__day-name {
                color: rgba(34, 48, 60, 0.5);
                padding: 0.166rem;
            }
            .react-datepicker__day--in-range {
                background: rgba(56, 141, 255, 0.4);
                color: var(--black);
                font-weight: 500;
                font-family: "Inter", sans-serif !important;
            }
            .react-datepicker__day--in-selecting-range {
                background: rgba(56, 141, 255, 0.4);
                color: var(--black);
                font-weight: 500;
                font-family: "Inter", sans-serif !important;
            }
            .react-datepicker__header {
                background-color: var(--white);
                border-bottom: 0;
            }
            .react-datepicker__day-names {
                padding: 0 14px;
            }
        }
        .react-datepicker__day--outside-month {
            color: transparent !important;
            pointer-events: none;
            background-color: transparent !important;
        }
        .react-datepicker-wrapper {
            input {
                height: 40px;
                appearance: none;
                width: 100%;
                border: 1px solid var(--darkGrey);
                border-radius: 6px;
                padding: 12px 19px;
                color: var(--black);
                transition: 0.3s;
                background-color: var(--secondWhite);
            }
        }
    }
    .datepickerHeader {
        .headerContent {
            @include flex-center-vert;
            justify-content: space-between;
            padding: 12px 14px;
            .montsWrapper,
            .yearsWrapper {
                @include flex-center-vert;
                button {
                    margin-top: 0;
                    @include buttonNulled;
                    @include flex-center-vert;
                    &:first-child {
                        svg {
                            width: 15px;
                            transform: rotate(90deg);
                        }
                    }
                    &:last-child {
                        svg {
                            width: 15px;
                            transform: rotate(-90deg);
                        }
                    }
                    &:disabled {
                        opacity: 0.5;
                    }
                }
                p {
                    @include fontRem(15, 500, 17);
                    color: var(--black);
                }
            }
        }
    }
    .selectWrapper {
        list-style-type: none;
        > li {
            position: relative;
            padding: 0 10px;
            cursor: pointer;
            ul {
                position: absolute;
                top: calc(100% + 4px);
                left: -20px;
                width: calc(100% + 40px);
                list-style-type: none;
                border: 1px solid var(--blue);
                border-radius: 6px;
                height: 210px;
                overflow: auto;
                background-color: var(--white);
                display: none;
                z-index: 1;
                &.openedDropDown {
                    display: block;
                }
                &::-webkit-scrollbar {
                    display: none;
                }
                > li {
                    width: 100%;
                    padding: 12px 2px;
                    @include fontRem(14, 500, 17);
                    text-align: center;
                    cursor: pointer;
                    color: var(--black);
                    &:hover {
                        background-color: var(--gray);
                    }
                }
            }
        }
    }
}
