@import "src/styles/pallete";

.wrapper {
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    padding: 15px 55px 0 55px;
    background: var(--white);
    max-height: 544px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 3.83px 9.58px 0px #66769533;
    border-radius: 12px;
    gap: 32px;
    min-width: 600px;
    min-height: 544px;
    .heading {
      font-size: 20px;
      font-weight: 700;
      color: var(--black);
      text-align: center;
      margin: 0;
    }
    .helperText {
      font-size: 14px;
      font-weight: 500;
      color: var(--black);
    }
    .form {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      input {
        width: 300px;
      }
      button {
        width: 117px;
      }
    }
    .contact {
      margin-top: 20px;
      p {
        font-size: 14px;
        font-weight: 500;
        color: var(--black);
        text-align: center;
        line-height: 20px;
        .phone {
          text-decoration: none;
          color: var(--blue);
          margin-left: 4px;
        }
        .email {
          text-decoration: underline;
          color: var(--black);
          margin-left: 4px;
        }
      }
    }
    .copyright {
      margin-top: 27px;
      p {
        font-size: 12px;
        font-weight: 500;
        color: var(--black);
        span {
          font-weight: 700;
        }
      }
    }
  }
}