@import "/src/styles/mixins";
@import "/src/styles/pallete";

.checkList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .checkListItem {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    svg {
      width: 15px;
      height: 15px;
      margin-right: 12px;
    }
  }
}

.bold {
  @include fontRem(12, 500, 20);
  color: var(--darkBlack);
}

.uploadTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  @include fontRem(14, 600, 20);
  color: var(--black);
}

.fileTypeHeading {
    font-size: 12px;
    font-weight: 500;
    color: #252733;
}