@import "/src/styles/mixins";
@import "/src/styles/pallete";

.timerText {
  @include fontRem(14, 500, 20);
  color: var(--black);
  &.warning {
    color: var(--brightYellow);
  }
  &.danger {
    color: var(--persimmonRed);
  }
  &.isDefault {
    color: var(--black);
  }
}
