@import "/src/styles/mixins";
@import "/src/styles/pallete";

.fileItemWrapper {
    margin-right: -50px;
    padding-right: 50px;
    margin-bottom: 17px;
    padding-bottom: 17px;
    &:not(:last-child) {
        border-bottom: 1px solid var(--lightBlue);
    }
    &:last-child {
        padding-bottom: 0;
    }
    p {

        color: var(--black);
        @include fontRem(14, 500, 20);

        &.fileName {
            @include textShorten(1);
        }
    }
    .actionsWrapper {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 20px;
        button {
            width: 30px !important;
            height: 30px !important;
            background-color: var(--white);
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
            border-radius: 3px;
            @include flex-center;
            border: 0;
            outline: none;
            transition: 0.1s;
            &:disabled {
                opacity: 0.6;
                cursor: not-allowed !important;
                svg {
                    cursor: not-allowed;
                }
                &:hover {
                    transform: scale(1);
                }
            }
            &:hover {
                transform: scale(1.1);
            }
            &:nth-child(2) {
                svg {
                    path {
                        fill: var(--blue);
                    }
                }
            }
        }
    }
}
.crossing {
    position: relative;
    height: 47px;
    &::before {
        content: "";
        @include  absoluteCenter(vertical);
        width: calc(79.1666666667% - 20px);
        left: 10px;
        height: 1px;
        background-color: var(--black);
        z-index: 2;
    }

    p, h2 {
        position: relative;
        z-index: 2;
        opacity: 0.4;
        color: var(--black) !important;
    }
    :global {
        .ant-col {
            background-color: var(--rateCrossed);
            display: grid;
            align-items: center;
            &:last-child {
                background-color: transparent;
                align-self: center;
            }
        }
    }
}
