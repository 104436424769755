@import "/src/styles/mixins";
@import "/src/styles/pallete";

.progressBarWrapper {
    position: relative;
    :global {
        .ant-tooltip {
            top: -60px !important;
        }
        .ant-tooltip-arrow {
            display: none;
        }
        .ant-tooltip-content {
            
            .ant-tooltip-inner {
                background: #379fff !important;
                border-radius: 34px 34px 34px 0px;
                @include fontRem(14, 600, 20);
                width: 45px;
                height: 45px;
                @include flex-center;
                color: var(--mainWhite);
            }
        }
        .ant-slider-horizontal {
            height: 8px;
        }
        .ant-slider-rail {
            height: 8px;
            background-color: var(--gray);
            border-radius: 20px;
        }
        .ant-slider-step {
            height: 8px;
            .ant-slider-dot {
                display: none;
            }
        }
        .ant-slider-mark {
            margin-top: 13px;
            .ant-slider-mark-text {
                @include fontRem(14, 500, 20);
                color: #667695;
            }
        }
        .ant-slider-track {
            height: 8px;
            background-color: var(--blue);
            border-radius: 20px 0 0 20px;
        }
        .ant-slider-handle {
            width: 20px;
            height: 20px;
            top: -2px;
            &::before {
                content: none;
            }
            &::after {
                width: 20px !important;
                height: 20px !important;
                background-color: var(--blue);
                box-shadow: none;
            }
            &:focus {
                &::after {
                    box-shadow: none;
                }
            }
            &:hover {
                &::after {
                    box-shadow: 0 0 0 14px #bdcee5;
                }
            }
            &.ant-slider-handle-dragging {
                &::after {
                    background-color: #bdcee5;
                    box-shadow: 0 0 0 14px #bdcee5;
                }
            }
        }
    }
}
