@import "/src/styles/pallete";
@import "/src/styles/mixins";


.EmptyText {
    padding-top: 25px;
    color: var(--darkGrey);
    @include fontRem(16, 700, 20);
}
.verificationMainSelect {
    margin-bottom: 20px;
}