@import "/src/styles/mixins";
@import "/src/styles/pallete";

.payrollWrapper {
  zoom: 0.9;
  .cardsTitle {
    color: var(--black);
    @include fontRem(16, 700, 20);
    font-family: "Inter", sans-serif !important;
    margin-bottom: 8px;
  }

  .payrollCard {
    background: var(--gray);
    border-radius: 6px;
    border: 1px solid transparent;
    height: 80px;
    padding: 16px 10px 16px 24px;
    @include flex-center-vert;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      border-color: var(--darkGrey);
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    }
    .price {
      @include fontRem(20, 600, 20);
      font-family: "Inter", sans-serif !important;
      &.positivePrice {
        color: var(--blue);
      }
      &.negativePrice {
        color: var(--persimmonRed);
      }
      &.zeroPrice {
        color: var(--darkGrey);
      }
    }
    &.payRollCardActive {
      border-color: var(--black);
      background-color: var(--white);
    }
    &.firstElement {
      .price {
        text-decoration: underline;
      }
    }
    &.defaultCursor {
      cursor: default;
      .price {
        text-decoration: none !important;
      }
      &:hover {
        box-shadow: none;
        border-color: transparent;
      }
    }
    svg {
      width: 50px;
      height: 50px;
    }
  }
  &.wrapperLoading{


    position: relative;
    .loader {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include  flex-center;
      @include  absoluteCenter(both);
      z-index: 1;
      :global {
        .ant-spin {
          position: relative;
          z-index: 2;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--white);
        opacity: .8;
        z-index: 1;
      }
    }
  }
}

.payrollWrapperColored {

  margin: 0 -20px 0;
  padding: 27px 21px 33px;
  background-color: var(--gray);
  zoom: 1;
  .cardsTitle {
    margin-bottom: 0;
    @include fontRem(14, 600, 20);
  }
  .payrollCard {
    height: 60px;
    background-color: var(--white);
    padding: 10px 17px;
    .price {
      @include fontRem(14, 600, 20);
    }
  }

}

.skeletonRow {
  li {
    height: 80px!important;
  }
}