@import "/src/styles/mixins";
@import "/src/styles/pallete";

.formWrapper {
  form {
    gap: 0;
  }
}

.title {
  @include fontRem(24, 700, 29);
  text-align: center;
}

.tooltipWrapper {
  margin-top: 11px;
  position: relative;
  margin-bottom: 32px;
  > p {
    text-decoration-line: underline;
    color: #667695;
    @include fontRem(12, 600, 15);
    cursor: pointer;
  }
  &:hover {
    .tooltipContent {
      visibility: visible;
      opacity: 1;
    }
  }
  .tooltipContent {
    position: absolute;
    top: 100%;
    max-width: 360px;
    width: 100%;
    min-width: 350px;
    padding: 26px 22px;
    background-color: #fff;
    border-radius: 6px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    box-shadow: 0 4px 10px rgba(#667695, 0.2);
    h3 {
      margin-bottom: 7px;
      @include fontRem(16, 700, 19);
    }
    p {
      @include fontRem(14, 400, 17);
      color: #6e7491;
    }
  }
}
.tooltipItem {
  min-width: 320px;

  :global {
    .ant-tooltip-inner {
      padding: 26px 22px;
    }
  }
}
