@import "/src/styles/mixins";
@import "/src/styles/pallete";

.filterButton {
    padding: 11px 13px 11px 21px;
    @include flex-center;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(102, 118, 149, 0.15);
    border: 1px solid var(--lightBlue);
    border-radius: 6px;
    height: 42px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.05);
    }
    p {
        font-feature-settings:
            "clig" off,
            "liga" off;
        @include fontRem(14, 500, 20);
    }
    &.active {
        border-color: var(--black);
    }
    .colorPrefix {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 8px;
    }
}
