@import "/src/styles/mixins";
@import "/src/styles/pallete";

.switchWrapper {
    .switch {
        width: 42px;
        height: 20px !important;
        position: relative;
        background-color: rgba(102, 118, 149, 0.2);
        transition: 0.3s;
        &:hover {
            background-color: rgba(102, 118, 149, 0.2) !important;
            transform: scale(1.1);
        }
        :global {
            .ant-switch-handle {
                @include absoluteCenter(vertical);
                width: 24px !important;
                height: 24px !important;
                border-radius: 50% !important;
                inset-inline-start: 0;

                &::before {
                    border-radius: 50% !important;
                    background-color: #98a2b2;
                }
            }
        }
    }
    :global {
        .ant-switch-checked {
            transition: 0.3s;
            background-color: rgba(#379fff, 0.5);
            &:hover {
                background-color: rgba(#379fff, 0.5) !important;
                transform: scale(1.1);
            }
            .ant-switch-handle {
                &::before {
                    background-color: #379fff;
                }
                inset-inline-start: calc(100% - 23px) !important;
            }
        }
    }
}
