@import "/src/styles/mixins";
@import "/src/styles/pallete";

.loadStatusWrapper {
    @include flex-center-vert;
    gap: 10px;
    .loadsText {
        @include fontRem(16, 600, 20);
    }
    .loadStatusItems {
        @include flex-center-vert;
        flex-wrap: wrap;
        gap: 10px;
    }
    [class*="statusText"] {
        color: var(--black);
    }
    .withoutBackground {
        [class*="statusItem"] {
            padding: 0 !important;
            gap: 6px !important;
            background-color: transparent !important;
            p {
                color: var(--black);
            }
        }
    }
    .statusItem {
        border-radius: 6px;
        padding: 8px 12px;
        @include flex-center-vert;
        display: inline-flex;
        gap: 14px;
        .statusText {
            @include fontRem(12, 600, 15);
        }
        svg {
            min-width: 20px;
            max-width: 20px;
        }
    }
    .bothBlock {
        display: flex;
        gap: 14px;
    }
    .requestCheck {
        background: rgba(#c1cada, 0.2);
    }
    .denied {
        background: rgba(255, 84, 84, 0.2);
    }
    .notProcessed {
        background: rgba(#c1cada, 0.2);
    }
    .processing {
        background: rgba(#1c73e8, 0.2);
    }
    .customerPending {
        background: rgba(#ffe7d1, 1);
        p {
            color: #000;
        }
    }
    .carrierPending {
        background: rgba(#ffe7d1, 1);
        p {
            color: #000;
        }
    }
    .carrierPaid {
        background: rgba(#34a853, 0.2);
    }
}
