@import "/src/styles/mixins";
@import "/src/styles/pallete";

.topBanner {
    padding: 31px 61px 61px 48px;
    background-color: var(--white);
    border-radius: 12px;
    margin-bottom: 24px;
    .topBannerHeader {
        @include flex-center-vert;
        justify-content: space-between;
        .topBannerTitle {
            @include fontRem(24, 700, 20);
            color: var(--black);
            margin: 0;
        }
    }
    .topBannerSubTitle {
        @include fontRem(16, 700, 20);
        color: var(--black);
        margin: 0;
    }
    .requestCreditContent {
        margin-top: 42px;
    }
    .amountBlock {
        display: flex;
        margin-top: 24px;
         .amountText {
            @include fontRem(14, 500, 20);
            color: var(--black);
         }
         
    }
    .realAmount {
        margin-top: 18px;
        .amountFull {
            min-width: 90px;
            @include fontRem(14, 700, 20);
            padding: 5px;
            background-color: var(--blue);
            color: var(--mainWhite);
            border-radius: 3px;
            display: inline-block;
            text-align: center;
         }
    }
    .debtBlock {
        margin-top: 30px;
        .debtRow {
            margin-top: 18px;
            @include fontRem(14, 500, 20);
            color: var(--black);
        }
    }
}