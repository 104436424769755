@import "/src/styles/mixins";
@import "/src/styles/pallete";

.mainResult {
    padding: 43px 44px;
    .noResult {
        color: var(--darkGrey);
        @include fontRem(16, 700, 20);
    }
    .ResultWrapper {
        color: var(--black);
        .topWrapper {
            margin-bottom: 23px;
            p {
                font-weight: 600;
            }
            button {
                height: 36px !important;
            }
        }
        .text {
            @include fontRem(14, 500, 20);
            color: var(--darkGrey);
        }
        .strongText {
            @include fontRem(14, 600, 20);
        }
    }
}
