@import "src/styles/mixins";
@import "src/styles/pallete";
.customCommentMainWrapper {
    padding-bottom: 10px;
    .commentWrapper {
        .commentHeader {
            padding: 38px 48px;
            background: var(--white);
            border: 1px solid var(--drawerBorder);
            border-radius: 12px;
            margin-bottom: 20px;
            .textArea {
                .label {
                    margin-bottom: 26px;
                    @include fontRem(18, 700, 20);
                    color: var(--black);
                }
            }
        }
        .commentNotificationBody {
            padding: 38px 0 30px 48px;
            background: var(--white);
            border: 1px solid var(--drawerBorder);
            border-radius: 12px;
            .heading {
                @include fontRem(20, 700, 20);
                color: var(--black);
            }
            &.noNotification {
                :global {
                    .ant-tabs-nav {
                        .ant-tabs-tab {
                            cursor: default;
                            .ant-tabs-tab-btn {
                                cursor: default;
                            }
                        }
                    }
                }
            }
            :global {
                .ant-divider {
                    width: calc(100%) !important;
                }
                .ant-tabs-nav {
                    margin-bottom: 27px;
                    &::before {
                        content: none;
                    }
                    .ant-tabs-nav-list {
                        gap: 15px;
                    }
                    .ant-tabs-tab {
                        @include fontRem(14, 500, 20);
                        padding-bottom: 0;
                        color: var(--black);
                        transition: 0.1s;
                        margin-left: 0;

                        &:hover {
                            color: var(--black);
                        }
                        &.ant-tabs-tab-active {
                            .ant-tabs-tab-btn {
                                color: var(--black);
                            }
                        }

                        &.ant-tabs-tab-disabled {
                            display: none;
                        }
                    }
                    .ant-tabs-ink-bar {
                        background-color: var(--black);
                    }
                }
            }
            .addedTitle {
                margin-bottom: 10px;
                color: var(--black);
                @include fontRem(16, 700, 20);
            }
            .singleItem {
                padding-right: 48px;
                margin-bottom: 14px;
                padding-bottom: 0;
                &:not(:last-child) {
                    border-bottom: 1px solid var(--longBorder);
                }
                .commentName {
                    @include fontRem(14, 500, 20);
                    color: var(--black);
                    margin-bottom: 0;
                    @include flex-center-vert;
                    justify-content: space-between;
                    gap: 15px;
                    button {
                        padding: 6px;
                        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
                        border-radius: 3px;
                    }
                }
                .commentDate {
                    margin-bottom: 5px;
                    color: var(--blueGray);
                    @include fontRem(12, 400, 20);
                }
                .commentText {
                    color: var(--black);
                    @include fontRem(14, 400, 20);
                }
            }
            .notificaitonItem {
                @include fontRem(14, 400, 20);
                color: var(--black);
                a {
                    color: var(--blue);
                    text-decoration: underline;
                }
            }
            .noData {
                @include fontRem(14, 400, 20);
                color: var(--blueGray);
            }
        }
        &.active {
            border-radius: 12px;
            border: 3px solid var(--blue);
        }
        .viewMoreBtn {
            @include flex-center;
            margin-top: 50px;
            button {
                text-decoration: underline;
                padding-bottom: 0;
                transition: 0.3s;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
