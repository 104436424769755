@import "/src/styles/mixins";
@import "/src/styles/pallete";

.officeWrapper {
  .officesInfo {
    margin-bottom: 20px;
    background: var(--white);
    border: 1px solid var(--longBorder);
    border-radius: 12px;
    padding: 31px 61px 51px 49px;
  }
  .addedBlock {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .editOffices {
    background: var(--white);
    border: 1px solid var(--lightBlue);
    border-radius: 12px;
    padding: 31px 61px 61px 48px;
    margin-top: 20px;
  }
  .infoColumns {
    display: flex;
    & > span {
      @include fontRem(14, 600, 20);
      color: var(--black);
      margin-right: 3px;
    }
    & > a {
      text-decoration: underline;
    }
  }
  .rowsGap {
    padding-top: 40px;
  }
  .rows {
    padding-top: 10px;
  }
  upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .uploadSection {
    .allowedFormat {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
    }
    .uploadText * {
      @include fontRem(12, 400, 20);
      color: var(--black);
    }
  }
  .uploadHeading {
    @include fontRem(20, 600, 20);
    color: var(--black);
    margin-bottom: 30px;
    &::after {
      content: "*";
      @include fontRem(12, 500, 20);
      padding-left: 3px;
      vertical-align: super;
      color: var(--persimmonRed);
    }
  }
  .officesAddAuthorities,
  .payoutBlock {
    margin-top: 50px;
    p {
      margin-bottom: 64px;
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    p::after {
      content: "*";
      @include fontRem(12, 500, 20);
      margin-left: 3px;
      display: inline-block;
      color: var(--persimmonRed);
    }
  }
  .textWithLinks {
    display: flex;
    gap: 4px;
    a {
      text-decoration: underline;
    }
  }

  .officesAddAuthorities {
    margin-top: 30px;
    margin-bottom: 30px;
    p {
      margin-bottom: 0;
      @include fontRem(18, 700, 20);
    }
  }
  .allowedFormat {
    & > h2 {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
      padding-top: 18px;
    }
  }
  .headingBlock {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .phoneWrapper {
    flex-wrap: wrap;
    gap: 4px;
    .phoneItem {
      display: flex;
      gap: 4px;
    }
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.05);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
      :global {
        .top-banner-value {
          color: var(--activeLink);
        }
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.phoneLinkInArray {
  @include fontRem(14, 600, 20);
  color: var(--blue);
  margin-right: 3px;
  &:hover {
    text-decoration: underline;
  }
}
.phoneNameInArray {
  @include fontRem(14, 600, 20);
  color: var(--black);
}
