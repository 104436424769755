@import '/src/styles/mixins';
@import '/src/styles/pallete';

.payrollFooter {
  position: sticky;
  left: 0;
  bottom: 0;
  margin-top: 30px;
  box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
  border-radius: 12px 12px 0 0;
  background-color: var(--white);
  padding: 24px;
  z-index: 3;
  .footerRow {
    @include flex-center-vert;
    justify-content: space-between;
    .rightSide {
      @include flex-center-vert;
      justify-content: flex-end;
      gap: 40px;
      p {
        @include  fontRem(18, 600, 20);
        span {
          color: var(--blue);
        }
      }
      button {
        min-width: 168px;
      }
    }
  }
}