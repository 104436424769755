@import "/src/styles/mixins";
@import "/src/styles/pallete";

.authoritiesEmpty {
  padding-top: 25px;
  color: var(--darkGrey);
  @include fontRem(16, 700, 20);
}
.filters {
  padding-bottom: 20px;
  .reset {
    button {
      padding: 0 !important;
    }
  }
}
.counts {
  padding-bottom: 12px;
}
.filtersInfo {
  @include fontRem(14, 600, 20);
  color: var(--black);
}
.filtersCount {
  position: relative;
  color: var(--blue);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &::after {
    content: none;
    position: absolute;
    top: 0;
    left: calc(100% + 9px);
    width: 1px;
    height: 100%;
    background-color: var(--lightGray);
    opacity: 0.2;
  }
}
