@import "/src/styles/mixins";
@import "/src/styles/pallete";

.branchWrapper {
  form {
    @include flex-column;
    gap: 0;
  }
  .topbannerPairs {
    @include flex-center-vert;
    padding-right: 8px;
  }
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.05);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.addedBlock {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin-top: 40px;
  margin-bottom: 25px;
}
.disabledTrashIcon {
  opacity: 0.5;
}
.rowsGap {
  padding-top: 40px;
}
.rows {
  padding-top: 10px;
}
.allowedFormat {
  & > h2 {
    @include fontRem(12, 400, 20);
    color: var(--darkGrey);
    padding-top: 18px;
  }
}
.bracked {
  &:last-child {
    :global {
      .ant-divider {
        display: none;
      }
    }
  }
}
.brackedButtonWrapper {
  button {
    height: 40px;
  }
}
.uploadText * {
  @include fontRem(12, 400, 20);
  color: var(--black);
}
.branchesAddAuthorities,
.payoutBlock {
  margin-top: 50px;
  p {
    margin-bottom: 64px;
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
  p::after {
    content: "*";
    @include fontRem(12, 500, 20);
    margin-left: 3px;
    display: inline-block;
    color: var(--persimmonRed);
  }
}
.branchesAddAuthorities {
  margin-top: 30px;
  margin-bottom: 30px;
  p {
    margin-bottom: 0;
    @include fontRem(18, 700, 20);
  }
}
.heading {
  margin-bottom: 30px;
}

.addBrackeds {
  &::after {
    content: "*";
    @include fontRem(12, 500, 20);
    margin-left: 3px;
    display: inline-block;
    color: var(--persimmonRed);
  }
}

.percentText {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin-bottom: 12px;
}

.percent {
  @include fontRem(14, 500, 20);
  margin: 0;
}

.subHeading {
  @include fontRem(18, 700, 20);
  margin-bottom: 20px;
}

.trashIcon {
  margin-left: auto;
  box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
  border-radius: 3px;
  background: var(--white);
  cursor: pointer;
  @include flex-center;
  width: 30px;
  height: 30px;
}

.textWithLinks {
  margin-left: 4px;
  display: flex;
  gap: 4px;
  a {
    text-decoration: underline;
  }
  .phoneLinkInArray {
    @include fontRem(14, 600, 20);
    color: var(--blue);
    margin-right: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
  .phoneNameInArray {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
}

.tableWrapper {
  :global {
    .ant-table {
      overflow: auto;
      table {
        padding-top: 12px;
      }
    }
    .ant-table-thead {
      tr {
        th {
          background: transparent;
          padding: 0 6px;
          height: 33px;
          &::before {
            display: none;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          padding: 8px;
          div {
            margin: 0;
          }
        }
      }
    }
    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        button {
          color: var(--blueGray);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGray);
        }
        .ant-select-selector {
          border: none;
          color: var(--blueGray);
          @include fontRem(14, 500);
        }
      }
    }
  }
  .RowTextType {
    @include fontRem(14, 500, 20);
    color: var(--black);
  }
  .HeaderTitleWithSort {
    @include flex-center-vert;
    gap: 10px;
  }
  .headingCellText {
    @include fontRem(14, 500);
    color: var(--blueGray);
    margin: 0;
  }
  .poligonWrapper {
    @include flex-center-column;
    gap: 1px;
    cursor: pointer;
  }
}
.phoneLinkInArray {
  @include fontRem(14, 500, 20);
  color: var(--black);
  text-decoration: underline;
  margin-right: 3px;
  &:hover {
    color: var(--black);
  }
}

.phoneNameInArray {
  @include fontRem(14, 500, 20);
  color: var(--black);
}

.trashBlock {
  width: 30px;
  height: 30px;
  box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.actionColumn {
  @include flex-center-column;
  width: 30px;
  height: 30px;
  box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
  border-radius: 3px;
}
.grossInputWrapper {
  position: relative;
  .grossInput {
    color: transparent !important;
    position: relative;
    -webkit-text-fill-color: transparent !important;
  }
}

.grossInputSpan {
  @include absoluteCenter(both);
  @include fontRem(14, 700, 17);
  text-align: center;
}

.brackedsBlock {
  margin-top: 50px;
  :global {
    .ant-divider {
      margin: 17px 0;
    }
  }
}

.fieldName {
  color: var(--black);
  @include fontRem(14, 600, 20);
}
.popoverOverlay {
  .phoneLinkInArray {
    @include fontRem(14, 600, 20);
    color: var(--blue);
    margin-right: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
  .phoneNameInArray {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}