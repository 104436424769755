@import "/src/styles/mixins";
@import "/src/styles/pallete";

.aside {
    position: fixed;
    height: 100vh;
    left: 0;
    width: 240px;
    top: 0;
    background-color: var(--white);
    border-right: 2px solid var(--longBorder);
    transition: 0.3s;
    @include flex-column;
    padding-bottom: 180px;
    z-index: 10;
    .headerLogo {
        @include flex-center-vert;
    }
    &.collapseAside {
        width: 100px;
        .asideHeader {
            padding: 27px 10px;
            svg {
                height: 36px;
                width: 45px;
            }
            button {
                transform: rotate(180deg);
                svg {
                    height: 29px;
                }
            }
        }
        .asideMenuList {
            .menuList {
                list-style-type: none;
                li {
                    cursor: pointer;
                    a {
                        justify-content: center;
                        position: relative;
                        white-space: nowrap;
                        &:hover {
                            p {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                        span {
                            z-index: 2;
                        }
                        p {
                            position: absolute;
                            height: 100%;
                            background-color: var(--gray);
                            left: 0;
                            padding: 13px 75px;
                            z-index: 1;
                            @include flex-center-vert;
                            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
                            border-radius: 0px 50px 50px 0px;
                            visibility: hidden;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
    .asideHeader {
        padding: 27px 25px;
        @include flex-center-vert;
        justify-content: space-between;

        button {
            @include buttonNulled;
            transition: 0.3s;
            svg {
                display: block;
                path {
                    &:first-child {
                        fill: var(--black);
                    }
                }
            }
        }
    }
    .asideMenuList {
        flex-grow: 1;
        @include flex-column;
        .menuList {
            flex-grow: 1;
            margin-bottom: 20px;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            li {
                a {
                    padding: 10px 20px;
                    text-decoration: none;
                    color: var(--black);
                    @include fontRem(14, 700, 20);
                    @include flex-center-vert;
                    white-space: nowrap;
                    gap: 10px;
                    cursor: pointer;
                    span {
                        @include flex-center-vert;
                    }
                    svg {
                        path {
                            fill: currentColor;
                        }
                    }
                    &.activeLink {
                        color: var(--blue);
                        background-color: var(--gray);
                    }
                    &:hover {
                        color: var(--blue);
                        background-color: var(--gray);
                    }
                    &.disabledItem {
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                }
            }
        }
        .permissionDivider {
            margin-top: 22px;
            padding-top: 15px;
            border-top: 1px solid var(--longBorder);
        }
        .footerSidebar {
            position: absolute;
            bottom: 30px;
            width: 100%;
            text-align: center;
            p {
                @include fontRem(12, 400, 17);
                color: var(--secondLightBlue);
            }
            a {
                @include fontRem(12, 400, 17);
                color: var(--secondLightBlue);
                text-decoration: underline;
            }
            &.collapsedText {
                p {
                    @include flex-column;
                }
            }
        }
    }
    .skeletonWrapper {
        margin-top: 10px;
        margin-left: 20px;
        :global {
            .ant-skeleton-title {
                display: none !important;
            }
            .ant-skeleton-paragraph {
                li {
                    background-image: linear-gradient(
                        90deg,
                        rgba($black, 0.06) 25%,
                        rgba($black, 0.15) 37%,
                        rgba($black, 0.06) 63%
                    ) !important;
                }
            }
        }
        .leftSkeleton {
            :global {
                .ant-skeleton-title {
                    width: 100% !important;
                }
            }
        }
    }
}
