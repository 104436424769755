@import "src/styles/mixins";
@import "src/styles/pallete";

.notificationBox {
    position: relative;
    padding: 15px 55px 10px 30px;
    &::after {
        content: "";
        @include absoluteCenter(vertical);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        right: 20px;
        background-color: var(--blue);
        visibility: visible;
        opacity: 1;
    }
    &.read {
        cursor: auto;
        &::after {
            visibility: hidden;
            opacity: 0;
        }
    }
    p {
        margin-bottom: 0;
    }
    .notificationHeader {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        p {
            @include fontRem(14, 500);
            color: var(--black);
        }
    }
    .notificationBody {
        margin-bottom: 3px;
        display: inline-flex;
        flex-wrap: wrap;
        gap: 4px;

        p {
            @include fontRem(14, 400);
            color: var(--black);
            span {
                @include fontRem(14, 400);
                color: var(--black);
                &:not(:first-child) {
                    margin-left: 4px;
                }
                &.link {
                    color: var(--blue);
                    cursor: pointer;
                    &:hover {
                        color: var(--blueRadio);
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .notificationFooter {
        p {
            @include fontRem(10, 500, 20);
            color: var(--grayWhite);
        }
    }
}
