@import "/src/styles/mixins";
@import "/src/styles/pallete";

.rate {
    font-size: unset;
    @include flex-center-vert;

    :global {
        .ant-rate-star {
            &:not(:last-child) {
                margin-inline-end: 2px;
            }
            &.ant-rate-star-full {
                svg {
                    path {
                        fill: #fbbc05;
                    }
                }
            }
        }
    }
    &.canRate {
        * {
            cursor: pointer !important;
        }
    }
}

.rateDropdownWrapper {
    position: relative;
    margin-top: -3px !important;
    .rateShower {
        @include fontRem(12, 500, 20);
        color: var(--blue);
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }
    .rateShowerNone {
        color: var(--black);
        cursor: auto;
        user-select: none;
        text-decoration: none;
    }
    
}