@import "/src/styles/mixins";
@import "/src/styles/pallete";
.NewLoadIsEmpty {
    padding-top: 25px;
    color: var(--darkGrey);
    @include fontRem(16, 700, 20);
}
.loadsTabContainer {
    padding-top: 11px;
    padding-left: 25px;
    padding-right: 25px;
    .loadsTopBlock {
        @include flex-column;
        gap: 20px;
        margin-bottom: 30px;
    }
}

.loadsTabEmpty {
    padding-top: 25px;
    color: var(--darkGrey);
    padding-left: 30px;
    @include fontRem(16, 700, 20);
}

.filtersInfo {
    @include fontRem(14,600,20);
    color: var(--black);
}

.redConfirmationBlock {
    @include flex-center-vert;
    margin-right: 20px;
    gap: 10px;
    cursor: pointer;
}

.filtersCountRed,
.filtersCount {
    @include fontRem(14,600,20);
    color: var(--blue);
}

.filtersCountRed {
    color: var(--persimmonRed);
    &:hover {
        text-decoration: underline;
    }
}

.allUsersBlock {
    margin-top: 20px;
    margin-bottom: 20px;
}

.reset {
    padding-bottom: 5px;
}
