@import "/src/styles/mixins";

.truckCarrierRequest {
  background: var(--white);
  border: 1px solid var(--longBorder);
  border-radius: 12px;
  padding: 31px 61px 61px 48px;
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
  .bracked {
    &:last-child {
      :global {
        .ant-divider {
          display: none;
        }
      }
    }
  }
}

.subHeading {
  @include fontRem(18, 700, 20);
  margin-bottom: 20px;
}
