@import "/src/styles/mixins";

.loaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 500px;
    margin: auto;
    hr {
        width: inherit;
    }
}

.TruckLoader {
    position: relative;
    width: 120px;
    height: 30px;
    background-color: #444;
    animation: put-put 2s infinite, move-truck 4s infinite;
    background-image: url("../../../public/assets/images/loader-image.png");
     background-repeat: no-repeat;
     background-position: center;
    background-size: 70% auto;
    border-radius: 4px;

    -webkit-box-reflect: below 15px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.1, transparent), to(rgba(255, 255, 255, 0.1)));

    &:before,
    &:after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 2px solid #fff;
        background: #333;
        position: absolute;
        bottom: -10px;
    }
    &:before {
        left: 6px;
    }
    &:after {
        right: 6px;
    }

    &-cab {
        position: absolute;
        left: -35px;
        bottom: 0;
        width: 33px;
        height: 25px;
        background: #333;
        border-radius: 40% 0 4px 4px;

        -webkit-box-reflect: below 15px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.1, transparent), to(rgba(255, 255, 255, 0.1)));

        &:before,
        &:after {
            position: absolute;
            content: "";
            display: block;
            background: #333;
        }
        &:before {
            width: 20px;
            height: 15px;
            top: -15px;
            right: 0;
            border-radius: 100% 0 0 0;
        }
        &:after {
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: #444;
            left: 5px;
            border: 2px solid #fff;
            background: #333;
            position: absolute;
            bottom: -10px;
        }
    }
    &-smoke,
    &-smoke:after,
    &-smoke:before {
        position: absolute;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        right: -1px;
        bottom: -5px;
        border-radius: 50%;
        background: #333;
    }
    &-smoke {
        animation: smoke-1 1s infinite;
    }
    &-smoke:after {
        animation: smoke-2 2s infinite;
    }
    &-smoke:before {
        animation: smoke-3 3s infinite;
    }
}

@keyframes put-put {
    0% {
        margin-top: 0px;
        height: 50px;
    }
    5% {
        margin-top: -2px;
        height: 52px;
    }
    20% {
        margin-top: -1px;
        height: 50px;
    }
    35% {
        margin-top: 1px;
        height: 49px;
    }
    40% {
        margin-top: -1px;
        height: 51px;
    }
    60% {
        margin-top: 1px;
        height: 49px;
    }
    75% {
        margin-top: 0px;
        height: 50px;
    }
    80% {
        margin-top: -4px;
        height: 52px;
    }
    100% {
        margin-top: 1px;
        height: 49px;
    }
}
@keyframes smoke-1 {
    0% {
        opacity: 1;
    }
    15% {
        opacity: 1;
    }
    100% {
        right: -30px;
        bottom: 5px;
        width: 30px;
        height: 30px;
        opacity: 0;
    }
}
@keyframes smoke-2 {
    0% {
        opacity: 1;
    }
    15% {
        opacity: 1;
    }
    100% {
        right: -60px;
        bottom: 8px;
        width: 25px;
        height: 25px;
        opacity: 0;
    }
}
@keyframes smoke-3 {
    0% {
        opacity: 1;
    }
    15% {
        opacity: 1;
    }
    100% {
        right: -40px;
        bottom: 2px;
        width: 35px;
        height: 35px;
        opacity: 0;
    }
}

@keyframes move-truck {
    0% {
        margin-left: 90%;
        opacity: 1;
    }
    10% {
        opacity: 1;
    }
    50% {
        margin-left: 45%;
    }
    90% {
        opacity: 1;
    }
    100% {
        margin-left: 0;
        opacity: 1;
    }
}
