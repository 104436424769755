@import "/src/styles/mixins";
@import "/src/styles/pallete";

.permissionContainer {
    @include flex-column;
    .header {
        :global {
            .ant-typography-secondary {
                @include fontRem(20, 700, 20);
                color: var(--black);
            }
        }
    }
    .subHeader {
        :global {
            .ant-typography-secondary {
                @include fontRem(16, 700, 20);
                color: var(--black);
                text-transform: capitalize;
            }
        }
        .subHeaderTitle {
            @include fontRem(14, 600, 20);
        }
    }
    .secondary {
        background: var(--gray) !important;
    }
    :global {
        .ant-divider {
            width: calc(100% + 50px) !important;
            border-color: var(--gray) !important;
        }
    }
}

.error {
    color: var(--persimmonRed);
    @include fontRem(14, 500, 20);
}
