@import '/src/styles/mixins';
@import '/src/styles/pallete';

.excelBtnWrapper {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    background: var(--white);
    border: none;
    @include flex-center-vert;
    justify-content: center;
    transition: 0.3s;
    border: 1px solid transparent;
    &:hover {
        transform: scale(1.05);
        border: 2px solid var(--longBorder);
    }
}

.disabled {
    opacity: 0.4;
}
