.contentWrapper {
  margin-left: 240px;
  width: calc(100% - 240px);
  height: calc(100% - 80px);

  transition: 0.3s;
  &.growContent {
    margin-left: 100px;
    width: calc(100% - 100px);
  }
}
