@import "src/styles/mixins";
@import "src/styles/pallete";

.topbannerPairs {
  @include flex-center-vert;
  padding-right: 8px;
}
.columnsGap {
  padding-top: 14px;
}
[class*="tableRateItem"] {
  box-shadow: none;
  border: none;
}
.count {
  text-decoration: underline;
  cursor: pointer;
}

.copyIconText {
  @include flex-center-vert;
  display: inline-flex;
  gap: 12px;
  .copyIcon {
    padding: 7px;
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    cursor: pointer;
    background: var(--white);
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
  &.copyActive {
    > .infoParagraph {
      color: var(--activeLink);
    }
  }
  &:hover {
    .copyIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}

.favoriteItem {
  margin-left: 4px;
  border-radius: 50%;
  transition: 0.3s;
  > div {
    > div {
      box-shadow: none;
    }
  }
  &:hover {
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
  }
}
