@import "/src/styles/mixins";
@import "/src/styles/pallete";

.customerformWrapper {
  form {
    @include flex-column;
    gap: 0;
  }
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
    .ant-radio-group {
      + p {
        margin-top: 20px;
      }
    }
  }

  .radio {
    gap: 47px;
  }
  .addedBlock {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .rowsGap {
    padding-top: 40px;
  }
  .rows {
    padding-top: 10px;
  }
  .allowedFormat {
    & > h2 {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
      padding-top: 18px;
    }
  }
  .uploadText * {
    @include fontRem(12, 400, 20);
    color: var(--black);
  }
  .customersSubheader {
    margin-top: 22px;
    margin-bottom: 25px;
    p {
      margin-bottom: 30px;
      @include fontRem(18, 700, 20);
      color: var(--black);
    }
  }
  .heading {
    margin-bottom: 30px;
  }
  .resetPayment {
    button {
      padding: 0 !important;
    }
  }
  .actionBlock {
    display: flex;
    gap: 15px;
    .trashIcon {
      width: 30px;
      height: 30px;
      @include flex-center-vert;
      justify-content: center;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      background: var(--white);
      cursor: pointer;
    }
  }
  .emailBlock {
    margin-right: 3px;
    @include fontRem(14, 500, 17);
    color: var(--black);
  }

  .tableWrapper {
    :global {
      .ant-table {
        overflow: auto;
        table {
          padding-top: 12px;
        }
      }
      .ant-table-thead {
        tr {
          th {
            border: none;
            color: var(--darkGrey);
            background: transparent;
            padding: 0 6px;
            height: 33px;
            @include fontRem(14, 500, 20);
            &::before {
              display: none;
            }
            &:last-child {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }
        }
      }
      .ant-table-tbody {
        tr {
          @include fontRem(14, 500, 20);
          color: var(--black);
          td {
            border: none;
            padding: 8px;
            div {
              margin: 0;
            }
            &:last-child {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }
        }
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: none !important;
      }
      .ant-pagination {
        color: var(--blueGray);
        @include fontRem(14, 500);
        li {
          button {
            color: var(--blueGray);
            span {
              @include fontRem(14, 900);
            }
          }
          a {
            color: var(--blueGray);
          }
          .ant-select-selector {
            border: none;
            color: var(--blueGray);
            @include fontRem(14, 500);
          }
        }
      }
    }
    .RowTextType {
      @include fontRem(14, 500, 20);
      color: var(--black);
    }
    .HeaderTitleWithSort {
      @include flex-center-vert;
      gap: 10px;
    }
    .headingCellText {
      @include fontRem(14, 500);
      color: var(--blueGray);
      margin: 0;
    }
    .poligonWrapper {
      @include flex-center-column;
      gap: 1px;
      cursor: pointer;
    }
  }
  .templateImageArea {
    margin-top: 30px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}

.spinIcon {
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-name: rotateSpin;
  @keyframes rotateSpin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}