@import "/src/styles/mixins";
@import "/src/styles/pallete";

.selectDatePickerWrapper {
  position: relative;
  z-index: 9;
  &.openedWrapper {
    z-index: 9999;
  }
  label {
    margin-bottom: 4px;
    @include fontRem(14, 400, 20);
    color: var(--black);
  }
  :global {
    .react-datepicker-wrapper {
      display: none;
    }

    .react-datepicker-popper {
      transform: none !important;
      top: 100% !important;
      left: 0 !important;
    }
  }
  .datepickerMainList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    .datepickerAction {
      position: relative;
      z-index: 2;
      &:hover {
        .actionItem {
          border-color: var(--blue);
          filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
        }
      }
      .actionItem {
        background-color: var(--secondWhite);
        height: 40px;
        @include flex-center-vert;
        padding: 11px 11px 11px 17px;
        border-radius: 6px;
        border: 1px solid var(--black);
        position: relative;
        cursor: pointer;
        @include fontRem(14, 400, 17);
        &.openedPicker {
          border-color: var(--blue);
          border-radius: 6px 6px 0 0;
          filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
        }
        &::after {
          content: "";
          @include absoluteCenter(vertical);
          height: 22px;
          width: 1px;
          background-color: #c1cada;
          right: 37px;
          user-select: none;
          pointer-events: none;
        }
        span,.clearBtn  {
          @include absoluteCenter(vertical);
          user-select: none;
          pointer-events: none;
          right: 11px;
          @include flex-center-vert;
        }
        .clearBtn {
          pointer-events: all;
        }
      }
      .sublist {
        list-style-type: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--white);
        z-index: 890;
        border: 1px solid var(--black);
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        display: none;
        border-top: 0;
        &.openedList {
          display: block;
          border-color: var(--blue);
        }
        li {
          padding: 12px 28px;
          @include fontRem(14, 500, 17);
          cursor: pointer;
          &.selectedMonth {
            background-color: var(--gray);
          }
          &:hover {
            background-color: var(--gray);
          }
        }
      }
    }
  }
}
