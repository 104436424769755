@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  @include flex-column;

  .headingBlock {
    display: flex;
    align-items: center;
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }

  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    margin: 0;
  }
  .infoParagraphReset,
  .infoParagraphBracket,
  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
    &.negative {
      color: var(--persimmonRed);
    }
  }
  a.infoParagraph {
    color: var(--blue);
  }
}