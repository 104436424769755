@import "/src/styles/mixins";
@import "/src/styles/pallete";

.button {
    :global {
        .ant-btn-lg {
            height: unset;
            border-radius: unset;
        }
    }
    button {
        @include flex-center;
        box-shadow: none;
        flex-wrap: nowrap;
        border-color: transparent !important;
        height: unset;
        outline: none !important;
        &:focus-visible,
        &:focus,
        &:active {
            outline: none !important;
            outline-offset: 0 !important;
            transition: none !important;
            box-shadow: none !important;
        }
        :global {
            .ant-wave {
                display: none !important;
            }
        }
    }
    &.primary {
        button {
            color: #fff;
            border-radius: 6px;
            @include fontRem(18, 700, 22);
            padding: 14px 32px;
            height: unset;
            background-color: var(--primaryBtn);
            border: 0;
            &:hover {
                color: #fff;
                background-color: #3bb55b;
            }
            &:focus,
            &:active {
                background-color: var(--primaryBtn);
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }
    &.text {
        button {
            border: 0;
            color: #98a2b2;
            @include fontRem(18, 400, 20);
            background-color: transparent;
            &:hover, &:active {
                color: #98a2b2;
                background-color: transparent;
            }
        }
    }
    &.default {
        button {
            border: 0;
            background-color: #388dff;
            @include fontRem(18, 400, 20);
            padding: 16px 32px;
            border-radius: 6px;
            width: 100%;
            height: unset;
            color: #fff;
            @include fontRem(16, 600, 19);

            &:hover {
                background-color: darken($color: #388dff, $amount: 8);
                color: var(--white);
            }
            &:disabled {
                background-color: #388dff;
                opacity: 0.5;
                color: var(--mainWhite);
            }
        }
    }
    &.link {
        button {
            color: #388dff;
            @include fontRem(14, 600, 20);
            background-color: transparent;
            &:hover {
                color: #388dff;
                background-color: transparent;
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    &.panelAddButton {
        button {
            @include flex-center;
            @include fontRem(14, 600, 20);
            padding: 11px 18px 11px 13px;
            min-width: 160px;
            background-color: var(--primaryBtn);
            border-radius: 6px;
            color: #fff;
            &:disabled {
                opacity: 0.5;
            }
        }
    }
    &.comment {
        button {
            width: 100%;
            background: var(--white);
            border: 1px solid var(--blue) !important;
            border-radius: 6px;
            color: var(--blue);
            @include fontRem(14, 700, 17);
            padding: 11px 10px;
            &:focus,
            &:hover,
            &:active {
                color: var(--blue);
                background-color: transparent;
                box-shadow: 0 3px 18px rgba(#000, 0.16) !important;
            }
            &:disabled {
                box-shadow: none !important;
                opacity: 0.5;
            }
        }
    }

    &.actionBtn {
        button {
            background: var(--white);
            border: 1px solid var(--blue) !important;
            box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
            border-radius: 6px;
            color: var(--blue);
            @include fontRem(12, 500, 20);
            padding: 5px 32px;
            * {
                @include fontRem(12, 500, 20);
            }
            &:focus,
            &:hover,
            &:active {
                color: var(--blue);
                background-color: transparent;
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    &.gray {
        button {
            height: 100%;
            @include flex-center-vert;
            border: 0;
            &:hover {
                background-color: darken($color: #f0f2f5, $amount: 4);
                color: var(--darkGrey);
            }
            background-color: var(--gray);
            color: var(--darkGrey);
            @include fontRem(16, 600, 19);
        }
    }
    &.modalPrimary {
        button {
            background-color: var(--primaryBtn);
            color: #fff !important;
            @include fontRem(16, 600, 19);
            border: 0;
            &:focus,
            &:hover {
                background-color: lighten($color: #34a853, $amount: 4);
                color: #fff;
            }
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    &.danger {
        button {
            border: 1px solid transparent;
            background-color: #ff5454;
            @include fontRem(16, 600, 19);
            &:focus,
            &:hover,
            &:active {
                background-color: darken($color: #ff5454, $amount: 10);
            }
            &:disabled {
                color: rgba(255, 255, 255, 1);
                opacity: 0.5;
            }
        }
    }
    &.yellow {
        button {
            border: 0;
            background-color: #fbbc05;
            @include fontRem(16, 600, 19);
            &:focus,
            &:hover,
            &:active {
                background-color: #fbbc05;
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }
    &.warning {
        button {
            border: 0;
            background-color: #eea10b;
            @include fontRem(16, 600, 19);
            &:focus,
            &:hover,
            &:active {
                background-color: lighten($color: #eea10b, $amount: 10);
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    &.outlinedDanger {
        button {
            width: fit-content;
            background: var(--white);
            border: 1px solid #ff5454 !important;
            border-radius: 6px;
            color: #ff5454;
            @include fontRem(14, 700, 17);
            padding: 11px 10px;
            &:focus,
            &:hover,
            &:active {
                color: #ff5454;
                background-color: transparent;
                box-shadow: 0 3px 18px rgba(#000, 0.16) !important;
            }
            &:disabled {
                box-shadow: none !important;
                opacity: 0.5;
            }
        }
    }

    &.dark {
        button,
        a {
            border: 0;
            @include fontRem(18, 700, 22);
            color: var(--white) !important;
            background: var(--black) !important;
            border-radius: 30px;
            height: 50px;
            @include flex-center-vert;
            text-decoration: none;
            padding: 16px 32px;
            &:focus,
            &:hover {
                background: var(--black);
            }
        }
    }
    &.tableDark {
        button,
        a {
            border: 0;
            width: 100%;
            @include fontRem(18, 700, 22);
            color: var(--white) !important;
            background: var(--black) !important;
            border-radius: 6px;
            @include flex-center-vert;
            text-decoration: none;
            padding: 16px 32px;
            opacity: 0.9;
            &:focus,
            &:hover {
                opacity: 1;
            }
            &:disabled {
                background-color: var(--black) !important;;
                opacity: 0.7;
                color: var(--white) !important;
            }
        }
    }
    &.backToBtn {
        button {
            border: 0;
            background-color: var(--white);
            @include flex-center-vert;
            color: var(--black);
            padding: 11px 16px 11px 11px;
            height: 42px;
            @include fontRem(14, 600, 20);
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
            transition: 0.3s;
            svg {
                margin-right: 10px;
            }
            span {
                @include fontRem(14, 600, 20);
                @include flex-center-vert;
                gap: 11px;
                svg {
                    width: 13px;
                    path {
                        fill: var(--black);
                    }
                }
            }
            &:focus,
            &:hover,
            &:active {
                background-color: var(--white);
                color: var(--black);
                box-shadow: 0px 4px 16px rgba(102, 118, 149, 0.2);
                transform: scale(1.04);
            }
        }
    }
    &.viewDrawerEditBtn {
        display: flex;
        justify-content: flex-end;
    }
    &.ghost {
        button {
            border: 0;
            padding: 4px 15px !important;
            @include flex-center-vert;
            gap: 8px;
            color: #98a2b2 !important;
            @include fontRem(14, 500, 20);
            background-color: transparent;
            &:focus,
            &:hover {
                background-color: transparent;
            }
        }
    }
    &.customize {
        button {
            @include flex-center;
            background-color: var(--secondWhite);
            @include fontRem(12, 600, 20);
            padding: 10px 18px 11px 13px !important;
            height: 42px !important;
            border: 1px solid var(--lightGray) !important;
            border-radius: 6px;
            color: var(--lightGray);
            @media all and (max-width: 1360px) {
                padding: 10px 10px 11px 10px !important;
            }
            svg {
                height: 20px;
                margin-right: 10px;
                line,
                rect {
                    stroke: var(--lightGray);
                }
            }
            &:hover,
            &:focus {
                background-color: transparent;
                color: var(--blueGray);
                border: 1px solid var(--lightBlue) !important;
            }
            &:disabled {
                color: rgba(#fff, 0.5);
            }
        }
    }
    &.outlined {
        button {
            @include flex-center;
            background-color: var(--secondWhite);
            border: 1px solid var(--darkGrey) !important;
            color: var(--lightGray);
            &:hover,
            &:focus {
                background-color: var(--secondWhite);
                color: var(--lightGray);
                border: 1px solid var(--darkGrey) !important;
            }
            &:hover {
                transform: scale(1.05);
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }
    &.boldDark {
        button {
            background-color: var(--secondWhite);
            @include fontRem(16, 600, 20);
            padding: 16px 32px !important;
            height: 42px;
            border: 1px solid var(--black) !important;
            border-radius: 6px;
            color: var(--black);
            &:hover,
            &:focus {
                background-color: var(--secondWhite);
                color: var(--blueGray);
                border: 1px solid var(--lightBlue) !important;
            }
            &:disabled {
                color: rgba(#fff, 0.5);
            }
        }
    }
    &.middle {
        button {
            padding: 11.5px 27px;
            @include fontRem(16, 600, 19);
        }
    }
    &.large {
        button {
            padding: 14px 27px;
            @include fontRem(18, 600, 22);
            height: 50px !important;
        }
    }
    &.small {
        button {
            height: 30px;
            padding: 4px 27px;
            @include fontRem(14, 500, 17);
        }
    }
    &.smallBtn {
        button {
            margin-left: auto;
            width: auto !important;
            padding: 5px 15px !important;
            height: 36px !important;
        }
    }
}
.disabledState {
    opacity: 0.5;
    &:hover,
    &:active,
    &:focus {
        button {
            opacity: 0.5 !important;
        }
    }
}
