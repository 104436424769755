@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  @include flex-column;

  .secondHeading {
    margin-bottom: 25px;
  }
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div > label {
        @include flex-center-vert;
        .ant-radio {
          width: 26px;
          height: 26px;
          .ant-radio-inner {
            position: relative;
            width: 100%;
            height: 100%;
            background: var(--white);
            border: 1px solid var(--blueGray);
            &::after {
              transform: none;
              background: var(--blueRadio);
              width: 14px;
              height: 14px;
              margin: 0;
              @include absoluteCenter(both);
            }
          }
        }
        .ant-radio-checked {
          .ant-radio-inner {
            border: 1px solid var(--blueRadio);
          }
        }
        span {
          @include fontRem(14, 600, 20);
          color: var(--black);
        }
      }
      :global {
        .ant-typography {
          color: var(--black);
        }
      }
    }
  }
  .addedBlock {
    @include fontRem(18, 600, 20);
    color: var(--black);
    margin-top: 30px;
  }
  .editCustomers {
    background: var(--white);
    border: 1px solid var(--longBorder);
    border-radius: 12px;
    padding: 31px 61px 61px 48px;
    margin-top: 20px;
  }

  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
  .rowsGap {
    padding-top: 40px;
  }
  .rows {
    padding-top: 10px;
  }
  .customersAddAuthorities,
  .payoutBlock {
    margin-top: 50px;
    p {
      margin-bottom: 30px;
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    p::after {
      content: "*";
      @include fontRem(12, 500, 20);
      margin-left: 3px;
      display: inline-block;
      color: var(--persimmonRed);
    }
  }
  .customersAddAuthorities {
    margin-top: 30px;
    margin-bottom: 30px;
    p {
      @include fontRem(18, 700, 20);
    }
  }
  .allowedFormat {
    & > h2 {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
      padding-top: 18px;
    }
  }
  .headingBlock {
    display: flex;
    align-items: baseline;
    margin: 0;
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    margin: 0;
  }
  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
  }
  .textWithLinks {
    display: flex;
    gap: 4px;
    a {
      text-decoration: underline;
    }
  }

  .commentText {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .agentInfo,
  .payroll {
    margin-top: 43px;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .bottomBlock {
    margin-top: 5px;
    .heading {
      margin-bottom: 25px;
    }
  }
  .viewHeaderItem {
    margin-bottom: 11px;
  }
}
