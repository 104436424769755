@import "/src/styles/mixins";
@import "/src/styles/pallete";

.heading {
  @include fontRem(20, 700, 20);
  color: var(--black);
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0 !important;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}
.infoCommentParagraph {
  cursor: pointer;
  @include fontRem(14, 600, 20);
  color: var(--black);
  text-decoration: underline;
}
.copyIconText {
  @include flex-center-vert;
  display: inline-flex;
  gap: 12px;
  .copyIcon {
    padding: 7px;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    cursor: pointer;
    background: var(--white);
    visibility: hidden;
    opacity: 0;
  }
  &.copyActive {
    > .infoParagraph {
      color: var(--activeLink);
    }
  }
  &:hover {
    .copyIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}
.viewHeaderItem {
  margin-bottom: 11px;
}
.buttonsWrapper {
  button {
    width: 100%;
  }
}
