@import "/src/styles/mixins";
@import "/src/styles/pallete";

.rateModal {
    max-width: 720px;
    width: 100% !important;
    :global {
        .ant-modal-content {
            padding: 40px 45px 35px;
            padding: 28px 30px;
            text-align: left;
            background-color: var(--secondWhite);
            border: 1px solid var(--lightGray);
            .ant-modal-close {
                background: rgba(152, 162, 178, 0.1);
                @include flex-center-vert;
                justify-content: center;
                border-radius: 3px;
                position: absolute;
                width: 26px;
                height: 26px;
                top: 15px;
                right: 15px;
                svg {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }
            .ant-modal-title {
                text-align: left;
                @include fontRem(18, 700, 20);
                margin-bottom: 15px;
                background-color: var(--secondWhite);
                color: var(--black);
            }
        }
    }
    .rateModalButton {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 8px;
        button {
            border-radius: 3px !important;
        }
    }
    .showRateCount {
        @include flex-center-vert;
        margin-bottom: 20px;
        > p {
            margin: 0 5px 0 2px;
            @include fontRem(10, 500, 20);
            color: var(--darkGrey);
        }
    }
    .rateCurrent {
        margin-left: 20px;
        padding: 11px 17px;
        border: 1px solid #98a2b2;
        background-color: var(--white);
        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
        border-radius: 6px;
        display: inline-flex;
        font-size: 12px;
        border: 1px solid var(--gray);
        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
        border-radius: 6px;
        svg {
            width: 17px;
            height: 17px;
        }
        &.rateFirst {
            border: 1px solid #98a2b2;
        }
    }
    :global {
        .rate-shower {
            color: var(--black);
            cursor: default;
            text-decoration: none;
        }
    }
}
.rate {
    font-size: unset;
    @include flex-center-vert;
    :global {
        .ant-rate-star {
            &:not(:last-child) {
                margin-inline-end: 2px;
            }
            &.ant-rate-star-full {
                svg {
                    path {
                        fill: #fbbc05;
                    }
                }
            }
        }
    }
}

.tableRateItem {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 6px;
    height: 32px !important;
    &::after {
        content: none;
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 1;
        width: 7px;
        height: 9px;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.138672 9.34082V1.02832C0.138672 0.82832 0.213672 0.65332 0.363672 0.50332C0.513672 0.35332 0.688672 0.27832 0.888672 0.27832H6.38867C6.58867 0.27832 6.76367 0.35332 6.91367 0.50332C7.06367 0.65332 7.13867 0.82832 7.13867 1.02832V9.34082L3.63867 7.84082L0.138672 9.34082Z' fill='%23388DFF'/%3E%3C/svg%3E");
    }
    &.alreadyRated {
        border: 0;
        box-shadow: none;
        &::after {
            content: "";
        }
    }
    :global {
        .ant-rate {
            margin-top: -3px;
            .ant-rate-star {
                > div {
                    @include flex-center-vert;
                    height: 13px;
                }
            }
        }
    }
    > p {
        margin: -3px 5px 0 2px;
        @include fontRem(10, 500, 20);
        color: var(--darkGrey);
        @include flex-center-vert;
    }
}
.rateDropdownWrapper {
    position: relative;
    margin-top: -3px;
    .rateShower {
        @include fontRem(12, 500, 20);
        color: var(--blue);
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }
}
.rateZero {
    cursor: default;
    * {
        cursor: default;
    }
}
