@import "/src/styles/mixins";
@import "/src/styles/pallete";

.uploadedList {
  padding-top: 12px;
  width: 100%;
  @include flex-center-vert;
  .uploadedButtons {
    @include flex-center-vert;
    justify-content: flex-end;
    gap: 20px;
    button {
      width: 30px;
      height: 30px;
      background-color: var(--white);
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      @include flex-center;
      border: 0;
      outline: none;
      transition: 0.1s;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed !important;
        svg {
          cursor: not-allowed;
        }
        &:hover {
          transform: scale(1);
        }
      }
      &:hover {
        transform: scale(1.1);
      }
      &:nth-child(2) {
        svg {
          path {
            fill: var(--blue);
          }
        }
      }
    }
  }
}

.infoBlock {
  padding: 10px;
  .rightColumn,
  .leftColumn {
    @include flex-column;
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
  }
  .rightColumn {
    color: var(--black);
  }
}

.crossing {
  position: relative;
  height: 47px;
  &::before {
    content: "";
    @include absoluteCenter(vertical);
    width: calc(79.1666666667% - 20px);
    left: 10px;
    height: 1px;
    background-color: var(--black);
    z-index: 2;
  }

  p,
  h2 {
    position: relative;
    z-index: 2;
    opacity: 0.4;
    color: var(--black) !important;
  }
  :global {
    .ant-col {
      background-color: var(--rateCrossed);
      display: grid;
      align-items: center;
      &:last-child {
        background-color: transparent;
        align-self: center;
      }
    }
  }
}

.blurred {
  position: relative;
  height: 47px;
  align-items: stretch;
  &::before {
    content: "";
    position: absolute;
    top: 32px;
    transform: translateY(-50%);
    width: calc(79.1666666667% - 20px);
    left: 10px;
    height: 1px;
    background-color: var(--black);
    z-index: 2;
  }

  p,
  h2 {
    position: relative;
    z-index: 2;
    opacity: 0.4;
    color: var(--black) !important;
  }
  :global {
    .ant-col {
      background-color: var(--rateCrossed);
      display: grid;
      align-items: center;
      .ant-select-selector {
        border-color: var(--rateCrossed) !important;
        background-color: var(--rateCrossed) !important;
        .ant-select-selection-item {
          opacity: 0.4;
        }
      }
      > {
        [class*="selectMainWrapper"] {
          pointer-events: none;
          background-color: var(--rateCrossed) !important;
          [class*="suffix"] {
            display: none !important;
          }
          .ant-select-arrow {
            display: none !important;
          }
        }
      }
      &:last-child {
        background-color: transparent;
        align-self: center;
      }
      
    }
  }
}
