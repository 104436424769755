@import "/src/styles/mixins";
@import "/src/styles/pallete";

.payablesInfo {
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
    }
  }
  .headingBlock {
    display: flex;
    align-items: baseline;

    p:first-child {
      @include fontRem(14, 600, 20);
      color: var(--black);
      margin-right: 3px;
    }
    a {
      text-decoration: underline;
    }
  }
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
    margin-bottom: 0;
  }

  [class*="tableRateItem"] {
    box-shadow: none;
    border: none;
  }
  .rows {
    padding-top: 10px;
  }
  .divider {
    margin-bottom: 6px;
  }
  .dividerLast {
    margin-bottom: 12px;
  }
}
.filterTableContainer {
  background: var(--white);
  border: 1px solid var(--drawerBorder);
  border-radius: 12px;
  padding-block: 30px;
  padding-bottom: 80px;
  :global {
    .ant-divider {
      width: 100% !important;
    }
  }
  .payableTableViewItems {
    padding: 0 42px;
  }
  .filtersItems {
    padding-left: 8px;
    padding-right: 8px;
  }
  :global {
    .ant-table {
      thead {
        tr {
          th {
            &::after {
              content: none !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:last-child {
              cursor: default;
            }
            &::after {
              content: none !important;
            }
          }
        }
      }
    }
  }
}
.totalInfo {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin-right: 5px;
}
.totalCount {
  @include fontRem(14, 600, 20);
  position: relative;
  color: var(--blue);
  &::after {
    content: none;
    position: absolute;
    top: 0;
    left: calc(100% + 9px);
    width: 1px;
    height: 100%;
    background-color: var(--lightGray);
    opacity: 0.2;
  }
}

.fullWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .filterTableContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .payableTableViewItems {
      &:last-child {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.payableModalItem {
  font-weight: 500;
}
