@import "src/styles/mixins";
@import "src/styles/pallete";

.carriers {
  &.editMode {
    margin-top: 0;
  }
  .truckTypes {
    margin-top: 32px;
    display: flex;
    column-gap: 108px;
    row-gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    .truckType {
      width: 150px;
      align-items: center;
      justify-content: space-between;
      display: flex;

    }
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .generalInfo {
    @include fontRem(18, 700, 20);
    color: var(--black);
    margin-bottom: 25px;
  }
  .switchSection {
    margin-top: 35px;
  }
  .cargoSwitchSection {
    margin-top: 10px;
  }
  .switchHeading {
    @include fontRem(16, 600, 20);
    color: var(--black);
    margin: 0;
  }
  .addTruckBtn {
    margin-top: 37px;
    button {
      width: 160px !important;
    }
  }
  .fuelCard {
    margin-bottom: 25px;
  }
  .headingGap {
    padding-bottom: 33px;
    h3::after {
      @include fontRem(12, 500, 20);
      margin-left: 3px;
      display: inline-block;
      color: var(--persimmonRed);
    }
  }
  .resetTruck {
    @include flex-center-vert;
    justify-content: space-between;
  }

  .documentationSubTitle {
    @include fontRem(14, 600, 20);
    color: var(--black);
    // &::after {
    //   content: "*";
    //   @include fontRem(12, 500, 20);
    //   margin-left: 3px;
    //   display: inline-block;
    //   color: var(--persimmonRed);
    // }
  }
  .bankData {
    padding-top: 25px;
    &.bankDataRadios {
      p {
        &[class*="error"] {
          display: none;
        }
      }
    }
  }
  .active {
    border-radius: 12px;
    border: 3px solid var(--blue);
  }
  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
    :global {
      .ant-upload-drag {
        border-radius: 8px;
      }
      .ant-upload-btn {
        padding: 9px 0;
      }
    }
  }
  .downloadFormats {
    @include fontRem(12, 400, 20);
    color: var(--blueGray);
  }
  .insuranceInputs {
    margin-top: 60px;
  }
}
.collapseWrapper {
  margin-top: 30px;
  width: calc(100% + 50px);
  :global {
    .ant-collapse {
      border-radius: 0 !important;
      transform: scale(1) !important;
      .ant-collapse-item {
        border-bottom: 1px solid var(--lightBlue);
        &:last-child {
          border-radius: 0;
        }
        .ant-collapse-header {
          padding: 19px 36px 19px 0;
          border-radius: 0 !important;
          .ant-collapse-header-text {
            @include flex-center-vert;
            justify-content: space-between;
            span {
              padding-right: 16px;
              @include fontRem(14, 600, 20);
              color: var(--black);
            }
          }
        }
        .ant-collapse-content-box {
          padding: 24px 36px 34px 0 !important;
        }
      }
    }
  }
  .arrow {
    @include flex-center-vert;
    transform: rotate(-90deg);
    transition: 0.3s;
    &.activeArrow {
      transform: rotate(0);
    }
  }
  .fileItemMainWrapper {
    [class*="fileItemWrapper"] {
      border-bottom: 0 !important;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
    }
  }
}
