@import "src/styles/mixins";
@import "src/styles/pallete";

.carriersEdit {
  .columnsGap {
    padding-top: 14px;
  }

  [class*="tableRateItem"] {
    box-shadow: none;
    border: none;
  }
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
      border-color: var(--gray);
    }
  }
  .active {
    border: 3px solid var(--blue);
    border-radius: 12px;
  }
  .lastLoadsSection {
    &.active {
      border: 3px solid var(--blue);
      border-radius: 12px;
    }
  }
  .paymentTerms {
    @include fontRem(18, 700, 20);
    color: var(--black);
  }
  .downloadAllFiles {
    background-color: var(--white);
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    padding: 7px;
    cursor: pointer;
    transition: 0.1s;
    &:hover {
      transform: scale(1.1);
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: var(--blue);
      }
    }
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.05);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
      :global {
        .top-banner-value {
          color: var(--activeLink);
        }
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.topBannerPairs {
  @include flex-center-vert;
  gap: 4px;
}
.divider {
  margin: 15px 0 4px;
}
