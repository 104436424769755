@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  @include flex-column;

  .headingBlock {
    display: flex;
    align-items: center;
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }

  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    margin: 0;
  }
  .infoParagraphReset,
  .infoParagraphBracket,
  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
  }
  a.infoParagraph {
    color: var(--blue);
  }
  .infoParagraphReset {
    cursor: pointer;
    text-decoration: underline;
    transition: 0.3s;
    display: inline-block;
    &:hover {
      transform: scale(1.1);
    }
  }
  .infoParagraphBracket::after {
    content: "|";
  }
  .infoParagraphBracket:last-child::after {
    display: none;
  }
  .agentInfo,
  .payroll {
    margin-top: 43px;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      &:hover {
        transform: scale(1.1);
      }
      visibility: hidden;
      opacity: 1;
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .viewHeaderItem {
    margin-bottom: 11px;
  }
  [class*="ghost"] {
    transition: 0.3s;
    button {
      padding: 0 !important;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
