@import "/src/styles/mixins";
@import "/src/styles/pallete";

.employee {
  .secondHeading {
    margin-bottom: 25px;
  }
  .addedBlock {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .editEmployee {
    background: var(--white);
    border: 1px solid var(--longBorder);
    border-radius: 12px;
    padding: 31px 61px 61px 48px;
    margin-top: 20px;
  }
  .infoColumns {
    & > span {
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    & > a {
      text-decoration: underline;
    }
  }
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
    .ant-typography {
      color: var(--black);
    }
  }
  .rowsGap {
    padding-top: 40px;
  }
  .rows {
    padding-top: 10px;
  }
  .employeeAddAuthorities,
  .payoutBlock {
    margin-top: 50px;
    p {
      margin-bottom: 30px;
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    p::after {
      content: "*";
      @include fontRem(12, 500, 20);
      margin-left: 3px;
      display: inline-block;
      color: var(--persimmonRed);
    }
  }
  .employeeAddAuthorities {
    margin-top: 30px;
    margin-bottom: 30px;
    p {
      @include fontRem(18, 700, 20);
    }
  }
  .allowedFormat {
    & > h2 {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
      padding-top: 18px;
    }
  }
  .secondHeadingBottom {
    margin-top: 70px;
  }
}

.headingBlock {
  @include flex-center-vert;
  margin: 10px;
  a {
    word-break: break-all;
  }
}

.textWithLinks {
  display: flex;
  gap: 4px;
  a {
    text-decoration: underline;
  }
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}