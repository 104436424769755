@import "/src/styles/mixins";
@import "/src/styles/pallete";

.topBanner {
  .columnItemTitle {
    @include fontRem(16, 700, 20);
    color: var(--black);
    margin-bottom: 0;
  }
  .blockTitle {
    margin-top: 30px;
  }
  .textItem {
    @include flex-center-vert;
    gap: 4px;
  }
  .topBannerActions {
    .markasClaim {
      @include flex-center-vert;
      gap: 8px;
    }
    [class*="gray"] {
      button {
        &:hover {
          background-color: var(--persimmonRed);
          color: #fff;
        }
      }
    }
  }
  .count {
    text-decoration: underline;
    cursor: pointer;
  }
  .infoParagraph {
    display: inline-block;
    color: var(--black);
  
    @include fontRem(14, 600, 20);
    margin: 0 !important;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
    &.red {
      color: var(--persimmonRed);
    }
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.05);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.active {
  border-radius: 12px;
  border: 3px solid var(--blue) !important;
}

