@import "/src/styles/mixins";
@import "/src/styles/pallete";

.filterWrapper {
  padding: 0 22px 33px;
  .selectResetIcon {
    margin-top: 25px;
  }
}
.truckBoardSearchWrapper {
  padding-bottom: 90px;
  &.withoutBorder {
    .truckBoardCardsContainer {
      border-top: 0;
    }

  }
  &.openedLoader {
    height: 0;
    overflow: hidden;
  }
  .truckBoardCardsContainer {
    padding: 33px 33px 0 22px;
    border-top: 1px solid var(--longBorder);
    margin-top: 29px;
  
    .truckBoardHeader {
      margin-bottom: 16px;
      @include flex-center-vert;
      justify-content: space-between;
      gap: 10px;
      .headerLeft {
        @include flex-center-vert;
        gap: 46px;
        .title {
          @include fontRem(20, 700, 20);
        }
        .fromToInfo {
          @include flex-center-vert;
          gap: 8px;
          .item {
            p {
              @include flex-center-vert;
              @include fontRem(12, 600, 20);
              gap: 6px;
              .cube {
                width: 14px;
                height: 14px;
                border-radius: 1px;
                display: block;
                &.blueCube {
                  background-color: #388dff;
                }
                &.greenCube {
                  background-color: #34a853;
                }
                &.grayCube {
                  background-color: #98A2B2;
                }
              }
              span {
                font-weight: 400;
              }
            }
          }
        }
      }
      .headerRight {
        @include flex-center-vert;
        gap: 10px;
        p {
          @include fontRem(16, 600, 20);
          span {
            font-weight: 400;
          }
        }
      }
    }
  }
  .searchButton {
    @include flex-center-vert;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 33px;
  }
}

