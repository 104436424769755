@import "/src/styles/mixins";
@import "/src/styles/pallete";

.notificationOuterButton {
    position: relative;
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: none;
    span {
        position: absolute;
        top: -7px;
        right: -11px;
        width: 25px;
        height: 25px;
        border: 1px solid var(--white);
        background-color: var(--danger);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 2px;
        @include fontRem(10, 700, 12);
        @include flex-center;
        color: var(--white);
        border-radius: 50%;
    }
}

.notificationDrawerWrapper {
    :global {
        .ant-drawer-header {
            padding: 0;
            padding: 14px 30px;
            border: 0;
            background-color: var(--gray);
            .ant-drawer-close {
                display: none;
            }
        }
        .ant-drawer-body {
            padding: 0;
            background-color: var(--secondWhite);
            .local-loader {
                width: 100% !important;
                left: 0 !important;
                [class*="loaderWrapper"] {
                    width: 100%;
                    padding-inline: 40px;
                }
            }
        }
    }
    .headerContent {
        @include flex-center-vert;
        justify-content: space-between;
        gap: 6px;
        .headerTitle {
            @include fontRem(16, 700, 20);
            color: var(--black);
        }
    }
    .drawerControlPanel {
        position: absolute;
        top: 0;
        left: 0;
        width: 65px;
        height: 100%;
        background-color: var(--drawerBg);
        @include flex-column;
        align-items: center;
        padding-top: 15px;
        border-right: 1px solid var(--longBorder);
        transform: translateX(-100%);
        button {
            @include buttonNulled;
            height: 32px !important;
            box-shadow: none !important;
            transition: 0.3s;
            &:hover,
            &:focus {
                background-color: transparent !important;
            }
            &:hover {
                transform: scale(1.1);
            }
        }

        .drawerOpener {
            transition: 0.3s;
            @include absoluteCenter(both);
            &.rotatedArrow {
                transform: translate(-50%, -50%) rotate(180deg);
            }
            svg {
                width: 31px;
                transition: 0.3s;
                path {
                    fill: #fff;
                }
            }
            &:hover {
                svg {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.notificationDrawerContent {
    max-height: 100%;
    overflow: auto;

    .skeletonWrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 60px;
        width: 100%;
        @include flex-center;
        background-color: var(--white);
    }
}
.notification {
    border-bottom: 1px solid rgba(152, 162, 178, 0.5);
    cursor: pointer;
}
.ldsRoller {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    zoom: 0.5;
    div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
        &::after {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: var(--blue);
            margin: -4px 0 0 -4px;
        }
        &:nth-child(1) {
            animation-delay: -0.036s;
            &::after {
                top: 63px;
                left: 63px;
            }
        }
        &:nth-child(2) {
            animation-delay: -0.072s;
            &::after {
                top: 68px;
                left: 56px;
            }
        }
        &:nth-child(3) {
            animation-delay: -0.108s;
            &::after {
                top: 71px;
                left: 48px;
            }
        }
        &:nth-child(4) {
            animation-delay: -0.144s;
            &::after {
                top: 72px;
                left: 40px;
            }
        }
        &:nth-child(5) {
            animation-delay: -0.18s;
            &::after {
                top: 71px;
                left: 32px;
            }
        }
        &:nth-child(6) {
            animation-delay: -0.036s;
            &::after {
                top: 63px;
                left: 63px;
            }
        }
        &:nth-child(7) {
            animation-delay: -0.216s;
            &::after {
                top: 68px;
                left: 24px;
            }
        }
        &:nth-child(8) {
            animation-delay: -0.288s;
            &::after {
                top: 56px;
                left: 12px;
            }
        }
    }
}
.noData {
    text-align: center;
    margin-top: 50px;
    @include fontRem(22, 600);
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
