@import "/src/styles/mixins";
@import "/src/styles/pallete";

.homePageWrapper {
    padding: 49px 43px;
    .pageTitle {
        margin-bottom: 40px;
        @include fontRem(36, 700, 44);
        color: var(--black);
    }
    .singleItem {
        display: block;
        border: 1px solid var(--gray);
        border-radius: 6px;
        padding: 24px 0 24px 23px;
        color: var(--black);
        height: 100%;
        &.disabledItem {
            cursor: not-allowed;
            opacity: 0.6;
        }
        .itemHeader {
            padding: 0 23px 10px 0;
            @include flex-center-vert;
            justify-content: space-between;
            margin-bottom: 20px;
            .itemTitle {
                @include fontRem(20, 700, 20);
                color: var(--black);
            }
            .headerLeft {
                @include flex-center-vert;
                gap: 23px;
                .iconWrapper {
                    @include flex-center-vert;
                    justify-content: center;
                    width: 44px;
                    height: 44px;
                    border-radius: 6px;
                    background-color: var(--blue);
                }
            }
            .headerRight {
                @include flex-center-vert;
            }
        }
        .itemBody {
            padding-right: 32px;
            p {
                @include fontRem(14, 400, 22);
            }
        }
        &:hover {
            background-color: var(--blue);
            .itemHeader {
                border-bottom-color: var(--white);
                .itemTitle {
                    color: var(--white);
                }
                .headerLeft {
                    .iconWrapper {
                        background-color: var(--white);
                        svg {
                            path {
                                fill: var(--blue);
                            }
                        }
                    }
                }
                .headerRight {
                    svg {
                        path {
                            fill: var(--white);
                        }
                    }
                }
            }
            .itemBody {
                p {
                    color: var(--white);
                }
            }
        }
    }
}
