@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  @include flex-column;

  .headingBlock {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .phoneItem {
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    gap: 4px;
  }
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    margin: 0;
  }
  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
  }
  a.infoParagraph {
    color: var(--blue);
  }
  .textWithLinks {
    display: flex;
    width: 100%;
    gap: 4px;
    a {
      text-decoration: underline;
    }
  }
  .agentInfo,
  .payroll {
    margin-top: 43px;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .phoneLinkInArray {
    .viewHeaderItem {
      margin-bottom: 11px;
    }
  }
}
.phoneLinkInArray {
  @include fontRem(14, 600, 20);
  color: var(--blue);
  margin-right: 3px;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
}
.phoneNameInArray {
  @include fontRem(14, 600, 20);
  color: var(--black);
}
.secondRow{
  padding-top: 30px;
}
