@import "/src/styles/mixins";
@import "/src/styles/pallete";

.EmptyText {
    padding-top: 25px;
    color: var(--darkGrey);
    @include fontRem(16, 700, 20);
}
.reset {
    margin-top: 20px;
}
.divider {
    border: 2px solid var(--longBorder);
}
