@import "/src/styles/mixins";
@import "/src/styles/pallete";

.permissionListContainer {
  padding: 11px 25px 0 25px;
  .permissionListTopBlock {
    .permissionListMainSelect {
      margin-bottom: 30px;
      @include  flex-center-vert;
      .selectWrapper {
        flex: 1;
        min-width: 314px;
      }
      button {
        margin-top: 20px;
      }
      label {
        color: var(--black);
        @include fontRem(14, 400, 20);
      }
    }
  }
  .permissionTopRight {
    @include  flex-center-vert;
    justify-content: flex-end;
    gap: 22px;
    .permissionSwitch {
      @include  flex-center-vert;
      gap: 8px;
    }
    .dotsItem {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background-color: var(--white);
      @include  flex-center;
      box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
    }
  }
}
.permissionListEmpty {
  padding-left: 30px;
  padding-top: 25px;
  color: var(--darkGrey);
  @include fontRem(16, 700, 20);
}

.subHeading {
  @include fontRem(18, 700, 20);
}
