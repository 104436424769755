@import "/src/styles/mixins";
@import "/src/styles/pallete";

.uploadTitle {
    margin-top: 20px;
    margin-bottom: 10px;
    @include fontRem(14, 600, 20);
    color: var(--black);
}
.upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
    @include fontRem(8, 400, 20);
}
.radioWrapper {
    margin-block: 32px;
}
.blockTitle {
    @include fontRem(16, 700, 20);
    margin-bottom: 24px;
}
.paymentTypeWrapper {
    margin-top: 50px;
    .paymentTypeRadios {
        margin-bottom: 32px;
    }
}
.brokerPaymentTerm {
    margin-top: 38px;
}
.downloadFormats {
    @include fontRem(12, 400, 20);
    color: var(--blueGray);
}
.bold {
    @include fontRem(12, 500, 20);
    color: var(--darkBlack);
}
.checkboxes {
    @include fontRem(12, 400, 20);
    color: var(--blueGray);
}
.icon{
    position: absolute;
    display: block;
    right: 0;
}

.fileTypeHeading {
    font-size: 12px;
    font-weight: 500;
    color: #252733;
}
.typeSection {
    margin-bottom: 15px;
    align-items: center;
}

.viewItemMainWrapper {
    padding-bottom: 20px;

    .whiteBlock {
        background-color: var(--white);
        padding: 30px 50px;
        border-radius: 12px;
        border: 1px solid var(--drawerBorder);

        :global {
            .top-banner-title {
                @include fontRem(14, 500, 20);
                color: var(--darkGrey);
                margin-right: 3px;
                white-space: nowrap;
                margin-bottom: 0 !important;
            }

            .top-banner-value {
                @include fontRem(14, 600, 20);
                color: var(--black);
                margin-bottom: 0 !important;
            }

            a.top-banner-value {
                color: var(--blue);
                text-decoration: none !important;

                &:hover {
                    color: var(--blueRadio);
                    text-decoration: underline !important;
                }
            }

            .ant-divider {
                width: calc(100% + 50px) !important;
            }
        }

        .headerBlock {
            .blockTitle {
                @include flex-center-vert;
                justify-content: space-between;

                @include fontRem(20, 700, 20);
                margin: 0;
                color: var(--black);
                gap: 4px;
            }

            :global {
                .ant-divider {
                    width: calc(100% + 50px) !important;
                    margin: 20px 0 30px;
                    border-color: var(--scrollColor) !important;
                }
            }

            .headerWithButtons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .buttonsGroup {
                    display: flex;
                    gap: 10px;

                    button {
                        height: 36px !important;
                    }
                }

                .copyIconText {
                    @include flex-center-vert;
                    display: inline-flex;
                    gap: 12px;

                    .copyIcon {
                        padding: 7px;
                        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
                        border-radius: 3px;
                        cursor: pointer;
                        background: var(--white);
                        transition: 0.3s;
                        visibility: hidden;
                        opacity: 0;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }

                    &.copyActive {
                        > h2 {
                            span {
                                color: var(--activeLink) !important;
                            }
                        }
                    }

                    &:hover {
                        .copyIcon {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .whiteBlockBody {
            [class*="tableRateItem"] {
                box-shadow: none;
                border: none;
                padding-left: 0;
            }

            :global {
                .elementTitle {
                    @include fontRem(14, 500, 20);
                    color: var(--darkGrey) !important;
                    margin: 0;
                }

                .elementParagraph {
                    @include fontRem(14, 600, 20);
                    color: var(--black);
                    margin: 0;

                    & > a {
                        text-decoration: underline;
                        color: var(--blueRadio);
                    }

                    [class*="tableRateItem"] {
                        box-shadow: none;
                        border: none;
                        margin-left: -12px;
                    }
                }
            }

            [class*="ghost"] {
                transition: 0.3s;

                button {
                    padding: 5px !important;
                    height: 20px;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }

            [class*="copyIconText"] {
                height: 20px;
                max-width: 100%;
            }
        }

        .divider {
            width: calc(100% + 35px) !important;
        }

        &.active {
            border-radius: 12px;
            border: 3px solid var(--blue);
        }

        &.canceled {
            position: relative;
            pointer-events: none !important;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(152, 162, 178, 0.3) !important;
                z-index: 999;
                border-radius: 12px;
            }
        }
    }
}
