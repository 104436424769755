@import "/src/styles/mixins";
@import "/src/styles/pallete";

.title {
    @include fontRem(24, 700, 29);
    text-align: center;
}
.signinRemember {
    margin: -7px 0 17px;
    .checkboxWrapper {
        span {
            color: #7c8db0;
            @media only screen and (max-width: 768px) {
               font-size: 12px;
            }
        }
        :global {
            @media only screen and (max-width: 768px) {
                .ant-checkbox-inner {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
            
        }
    }
    button {
        font-weight: 400 !important;
        height: unset !important;
        padding: 0 !important;
        span {
            font-size: 14px !important;
            text-decoration: underline;
            @media only screen and (max-width: 768px) {
                font-size: 12px !important;
                font-weight: 400 !important;
            }
        }
    }
}
.arrowIcon {
    position: absolute;
    left: 0;
    background: #fff;
    border: 1px solid #d0d8e6;
    transform: translateX(-25px);
    width: 52px;
    height: 52px;
    border-radius: 50%;
    @include flex-center;
    top: 50px;
    button {
        @include flex-center;
        padding: 0 !important;
    }
}

.EmailConfirmation {
    text-align: center;
    margin: -20px -20px -40px;
    p {
        padding: 13px 0 32px;
        @include flex-center;
        @include fontRem(16, 400, 21);
    }
    button {
        padding: 0 4px !important;
    }
}
.resendWrapper {
    padding-top: 13px;
    border-top: 1px solid #98a2b2;
    p {
        padding-bottom: 8px;
    }
}
