@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tonuLoadForm {
  .transactionSection,
  .topBanner {
    border: 1px solid var(--longBorder);
    padding: 35px 48px 45px 48px;
    background-color: var(--white);
    border-radius: 12px;
    :global {
      .ant-divider {
        width: calc(100% + 48px) !important;
      }
    }
    .columnItemTitle {
      @include fontRem(16, 700, 20);
      color: var(--black);
    }
    .textItem {
      @include fontRem(14, 600, 20);
      span,
      a {
        font-weight: 400;
      }
      a {
        color: var(--blue);
        text-decoration: underline;
      }
    }
    .topBannerActions {
      padding-right: 46px;
      .markasClaim {
        @include flex-center-vert;
        gap: 8px;
      }
      [class*="gray"] {
        button {
          &:hover {
            background-color: var(--persimmonRed);
            color: #fff;
          }
        }
      }
    }
    .carrierInfo {
      margin-top: 22px;
    }
  }
  .topBannerHeader {
    .topBannerTitle {
      @include fontRem(24, 700, 20);
      color: var(--black);
      margin: 0;
    }
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .generalInfoHeading {
    @include fontRem(18, 700, 20);
    color: var(--black);
    margin-top: 12px;
    margin-bottom: 42px;
  }
  .switchHeading {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
  .customerContactISize {
    width: 100%;
  }
  .uploadTitle {
    @include fontRem(18, 700, 20);
    color: var(--black);
    margin-bottom: 16px;
  }
  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .uploadSection {
    :global {
      .ant-upload-btn {
        padding: 9px 0 !important;
      }
    }
    [class*="fileItemWrapper"] {
      padding-right: 48px;
    }
    .documentationHeading {
      @include fontRem(16, 700, 20);
      color: var(--black);
    }
    .uploadHeading {
      @include fontRem(14, 600, 20);
      color: var(--black);
      padding-bottom: 7px;
    }
    .downloadAll {
      @include fontRem(14, 400, 20);
      color: var(--black);
      letter-spacing: 0.4px;
    }
    .allowedFormat {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
      padding-top: 9px;
    }
    .downloadAllBlock {
      margin-bottom: 30px;
    }
    .uploadedList {
      @include flex-column;
      gap: 15px;
      p {
        @include fontRem(14, 500, 20);
      }
      .uploadedButtons {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 21px;
      }
    }
    .uploadControl {
      background-color: var(--white);
      box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      padding: 7px;
      svg {
        width: 16px;
        height: 16px;
      }
      &.eyeIcon {
        svg {
          width: 19px;
          height: 13px;
        }
      }
      &.downloadBtn {
        &:hover {
          transform: scale(1.05);
        }
        svg {
          transition: 0.3s;
          path {
            fill: var(--blue);
          }
        }
      }
    }
  }
  .loadsFinanceSection,
  .transactionSection {
    background: var(--white);
    border: 1px solid var(--lightBlue);
    border-radius: 12px;
    padding: 30px 90px 51px 36px;
    .heading {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
    .topBannerBlock {
      margin-bottom: 35px;
      .takenAsBlock {
        padding: 10px 15px;
        background: rgba(#34a853, 0.2);
        border-radius: 3px;
        @include fontRem(14, 600, 20);
      }
      .generalInfoHeading {
        @include fontRem(18, 700, 20);
        color: var(--black);
      }
    }
    .switchHeading {
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    .switchBtns {
      justify-content: flex-end;
      gap: 10px;
    }
    .customerContactISize {
      max-width: 255px;
      width: 100%;
    }
    .subHeaderBlock {
      margin-top: 24px;
      & > div {
        padding-right: 30px;
        position: relative;
        &:not(:last-child) {
          &::after {
            content: "";
            display: block;
            height: 100%;
            width: 1px;
            background-color: var(--lightBlue);
            position: absolute;
            right: 22px;
            top: 0;
          }
        }
      }

      .headerBlock {
        margin-bottom: 10px;
      }
      .subHeaderValue,
      .subHeader {
        @include fontRem(14, 700, 20);
        color: var(--black);
        margin-bottom: 10px;
      }
      .subHeaderValue {
        color: var(--blueRadio);
      }
      .titleValue,
      .title {
        @include fontRem(14, 600, 20);
        color: var(--black);
        margin-bottom: 10px;
      }
      .titleValue {
        @include fontRem(14, 400, 20);
      }
    }
  }
  .transactionSection {
    .heading {
      margin-bottom: 30px;
    }
  }
  .active {
    border-radius: 12px;
    border: 3px solid var(--blue);
  }
}
