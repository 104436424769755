@import "/src/styles/mixins";
@import "/src/styles/pallete";

.cardController {
  position: relative;
  width: 100%;
  height: 200px;
  border: 1px solid #c1cada;
  border-radius: 6px;
  padding: 20px 0 20px 20px;
  .cardHeader {
    padding-right: 20px;
    padding-bottom: 22px;
    border-bottom: 1px solid var(--gray);
    @include flex-center-vert;
    justify-content: space-between;
    gap: 10px;
    .headerTitle {
      @include fontRem(16, 700, 20);
      color: #388dff;
    }
    .headerNumbers {
      @include flex-center-vert;
      gap: 10px;
      p {
        @include fontRem(16, 600, 20);
        span {
          font-weight: 400;
        }
      }
    }
  }
}

.checkBoxesContainer {
  margin-top: 21px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 24px);
  grid-template-columns: repeat(4, 60px);
  column-gap: 18px;
  row-gap: 17px;
}

.checkboxWithLabelContainer {
  position: relative;
  @include flex-center-vert;
  width: fit-content;
  span {
    @include fontRem(16, 600, 20);
  }
  input {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      background-color: var(--gray);
      border-color: var(--gray);

    }
  }
  .tooltipCheck {
    left: calc(100% - 25px);
    z-index: 1;
    @include absoluteCenter(vertical);
    background: var(--white);
    top: calc(100% - 10px);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    border-radius: 3px;
    min-width: 70px;
    .item {
      @include flex-center-vert;
      gap: 6px;
      @include fontRem(12, 600, 20);
      padding: 8px;
      cursor: pointer;
      &:hover {
        background: var(--gray);
      }
      .cube {
        width: 14px;
        height: 14px;
        border-radius: 1px;
        display: block;
        &.blueCube {
          background-color: #388dff;
        }
        &.greenCube {
          background-color: #34a853;
        }
        &.grayCube {
          background-color: #98a2b2;
        }
      }
    }
  }
}

.checkbox {
  appearance: none;
  font: inherit;
  color: currentColor;
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #a1b0cc;
  border-radius: 3px;
  margin-right: 13px;
}

.checkbox {
  &::before {
    content: "";
    width: 14px;
    height: 14px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #388dff;
  }
}

.checkbox {
  &.rightClick {
    &::before {
      box-shadow: inset 1em 1em #34a853;
    }
  }
  &.bothRightClick {
    &::before {
      box-shadow: inset 1em 1em #98a2b2;
    }
  }
}

.checkbox {
  &.rightClick {
    &:checked {
      border-color: #34a853;
    }
  }
  &.bothRightClick {
    &:checked {
      border-color: #98a2b2;
    }
  }
  &:checked {
    border-color: #388dff;
  }
  &:checked {
    &::before {
      transform: scale(1);
    }
  }
}
.resetCardBtn {
  position: absolute;
  right: 20px;
  bottom: 10px;
  button {
    span {
      &:last-child {
        margin-top: 2px;
      }
    }
  }
}
