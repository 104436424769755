@import "/src/styles/mixins";
@import "/src/styles/pallete";

.infoColumns {
    @include flex-center-vert;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}
.copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.05);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
}