@import "/src/styles/mixins";
@import "/src/styles/pallete";

.lastLoadsTable {
  width: 100%;
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
  .lastLoadsContent {
    :global {
      .ant-table-wrapper {
        .ant-table-container {
          &::after,
          &::before {
            content: none;
          }
        }
      }
      .ant-table {
        background-color: var(--white);
      }
      .ant-table-content {
        overflow: auto;
        .ant-table-thead {
          .ant-table-cell {
            background-color: transparent;
            @include fontRem(14, 500, 20);
            color: var(--darkGrey);
            border-bottom: 0;
            padding-bottom: 0;
            &::before {
              content: none !important;
            }
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            background-color: transparent;
            @include fontRem(14, 500, 20);
            color: var(--black);
            border-bottom: 0;
            padding-top: 13px;
            padding-bottom: 23px;
            .table-dropdown {
              position: relative;
              @include flex-center-vert;
              gap: 6px;
              svg {
                width: 11px;
                height: 11px;
              }
            }
          }
        }
      }
    }
  }
  :global {
    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        @include flex-center-vert;
        height: 30px;
        width: 30px !important;
        justify-content: center;
        button {
          color: var(--blueGrayWhite);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGrayWhite);
          padding: 0;
        }

        &.ant-pagination-total-text {
          order: 3;
          width: auto !important;
          color: var(--blueGrayWhite);
        }
        &.ant-pagination-item {
          width: auto;
          min-width: unset;
          margin-inline-end: 6px;
        }
        &.ant-pagination-item-active {
          background-color: transparent;
          border-color: var(--paginationActive);
          border-width: 2px;
          &:hover {
            color: var(--black);
          }
        }
        &.ant-pagination-prev,
        &.ant-pagination-next {
          background-color: var(--gray);

          a {
            @include flex-center-vert;
            justify-content: center;
            width: 100%;
            height: 100%;
            svg {
              width: 14px;
              height: 14px;
            }
          }
          &.ant-pagination-next {
            a {
              transform: rotate(180deg);
            }
          }
        }

        &.ant-pagination-options {
          width: auto;
          margin-inline: 20px;
          .ant-select-selector {
            border: none;
            color: var(--black);
            @include fontRem(14, 500);
            background-color: transparent;
            .ant-select-selection-item {
              color: var(--black);
              padding-inline-end: 14px;
            }
          }
          .ant-select-suffix {
            width: 9px;
            height: 5px;
            background-size: contain;
            background: no-repeat;
            mask: url("data:image/svg+xml;charset=utf8,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.43416 0L4.49414 3.0904L7.55412 0L8.49414 0.951402L4.49414 5L0.494141 0.951402L1.43416 0Z' fill='currentColor'/%3E%3C/svg%3E");
            background-color: var(--black);
            svg {
              display: none;
            }
          }
        }
        &.ant-pagination-jump-next,
        &.ant-pagination-jump-prev {
          align-items: flex-end;
          span {
            color: var(--black) !important;
          }
          .ant-pagination-item-ellipsis {
            color: var(--black);
          }
        }
      }
      .ant-select-dropdown {
        background-color: var(--white);
        .ant-select-item-option {
          @include flex-center;
          justify-content: center;
          * {
            color: var(--black);
            text-align: center;
          }
          &.ant-select-item-option-active {
            background-color: var(--gray);
          }
          &.ant-select-item-option-selected {
            background-color: var(--gray);
          }
        }
      }
    }
  }
}
:global {
  .drop-down-loads_items {
    .ant-dropdown-menu {
      background-color: var(--white);
      padding: 18px 16px 4px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      .ant-dropdown-menu-item {
        padding: 0 0 14px;
        background-color: transparent !important;
        .ant-dropdown-menu-title-content {
          @include fontRem(14, 500, 20);
          color: var(--black);
        }
      }
    }
  }
}
