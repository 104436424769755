@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tonuLoadForm {
  .topBanner,
  .transactionSection {
    border: 1px solid var(--longBorder);
    padding: 31px 48px 61px 48px;
    background-color: var(--white);
    border-radius: 12px;
    .topBannerHeader {
      padding-block: 21px;
      padding-right: 64px;
      border-bottom: 1px solid var(--lightBlue);
      @include flex-center-vert;
      justify-content: space-between;
      margin-bottom: 35px;
      .topBannerTitle {
        @include fontRem(24, 700, 20);
        color: var(--black);
        margin: 0;
      }
    }
    .columnItemTitle {
      @include fontRem(16, 700, 20);
      color: var(--black);
    }
    .textItem {
      @include fontRem(14, 600, 20);
      span,
      a {
        font-weight: 400;
      }
      a {
        color: var(--blue);
        text-decoration: underline;
      }
    }
    .topBannerActions {
      padding-right: 46px;
      .markasClaim {
        @include flex-center-vert;
        gap: 8px;
      }
      [class*="gray"] {
        button {
          &:hover {
            background-color: var(--persimmonRed);
            color: #fff;
          }
        }
      }
    }
    .carrierInfo {
      margin-top: 22px;
    }
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .topBannerBlock {
    margin-bottom: 35px;
    .takenAsBlock {
      padding: 10px 15px;
      background: rgba(#34a853, 0.2);
      border-radius: 3px;
      @include fontRem(14, 600, 20);
      @include flex-center-vert;
      gap: 4px;
      span {
        font-weight: 400;
      }
    }
    .generalInfoHeading {
      @include fontRem(18, 700, 20);
      color: var(--black);
    }
  }
  .switchHeading {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
  .switchBtns {
    display: flex;
    justify-content: flex-end;
  }
  .customerContactISize {
    max-width: 255px;
    width: 100%;
  }
  .subHeaderBlock {
    margin-top: 24px;
    .headerBlock {
      margin-bottom: 10px;
    }
    .subHeaderValue,
    .subHeader {
      @include fontRem(14, 700, 20);
      color: var(--black);
    }
    .subHeaderValue {
      color: var(--blueRadio);
    }
    .titleValue,
    .title {
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    .titleValue {
      @include fontRem(14, 400, 20);
    }
  }

  .active {
    border-radius: 12px;
    border: 3px solid var(--blue);
  }
}

.addAddressBtn {
  margin-top: 40px;
}

.transactionSection {
  .heading {
    margin-bottom: 37px;
  }
}
.loadFinanceSection {
  padding: 31px 48px 61px 48px;
}
.upload {
  background: rgba(56, 141, 255, 0.1);
  border: 1px dashed var(--blue);
  border-radius: 8px;
}
.uploadSection {
  :global {
    .ant-upload-btn {
      padding: 9px 0 !important;
    }
  }
  [class*="fileItemWrapper"] {
    padding-right: 48px;
  }
  .documentationHeading {
    @include fontRem(16, 700, 20);
    color: var(--black);
  }
  .uploadHeading {
    @include fontRem(14, 600, 20);
    color: var(--black);
    padding-bottom: 7px;
  }
  .downloadAll {
    @include fontRem(14, 400, 20);
    color: var(--black);
    letter-spacing: 0.4px;
  }
  .allowedFormat {
    @include fontRem(12, 400, 20);
    color: var(--darkGrey);
    padding-top: 9px;
  }
  .downloadAllBlock {
    margin-bottom: 30px;
  }
  .uploadedList {
    @include flex-column;
    gap: 15px;
    p {
      @include fontRem(14, 500, 20);
    }
    .uploadedButtons {
      @include flex-center-vert;
      justify-content: flex-end;
      gap: 21px;
    }
  }
  .uploadControl {
    background-color: var(--white);
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    padding: 7px;
    svg {
      width: 16px;
      height: 16px;
    }
    &.eyeIcon {
      svg {
        width: 19px;
        height: 13px;
      }
    }
    &.downloadBtn {
      &:hover {
        transform: scale(1.05);
      }
      svg {
        transition: 0.3s;
        path {
          fill: var(--blue);
        }
      }
    }
  }
}
.uploadRowWrapper {
  margin-top: 30px;
}
.active {
  border-radius: 12px;
  border: 3px solid var(--blue) !important;
}
.toBillingText {
  margin-top: 10px;
  text-align: right;
  @include fontRem(14, 700);
}
.withBorder {
  border-right: 1px solid var(--gray);
}
.financeItem {
  @include flex-center-vert;
  gap: 4px;
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--grayWhite) !important;
  margin: 0;
}
.infoParagraph {
  display: inline-block;
  @include fontRem(14, 600, 20);
  margin: 0;
  color: var(--black) !important;

  & > a {
    text-decoration: underline;
    color: var(--blueRadio) !important;
  }
  &.red {
    color: var(--persimmonRed) !important;
  }
}
.financeTitle {
  @include fontRem(14, 600, 20);
  color: var(--black) !important;
  margin-bottom: 0 !important;
}
.borderedColumn {
  &:not(:first-child) {
    border-left: 1px solid var(--gray);
  }
}
.newCarrierRateBtn {
  cursor: pointer;
  text-decoration: underline;
  color: var(--blue);
  @include fontRem(14, 500, 20);
}
.spinWrapper {
  min-height: 80px;
  @include flex-center;
}
