@import "/src/styles/mixins";
@import "/src/styles/pallete";

.customerHeaderTabs {
    :global {
        .ant-tabs-nav {
            margin: 0 -20px 23px -20px;
            padding: 0 32px 0 35px;
            .ant-tabs-tab {
                padding: 13px 3px;
                &:not(:first-child) {
                    margin: 0 0 0 41px;
                }
                .ant-tabs-tab-btn {
                    @include fontRem(14, 500, 20);
                    color: var(--darkGrey);
                    transition: 0.1s;
                    &:hover {
                        color: var(--blue);
                    }
                }
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: var(--blue);
                    }
                }
            }
            .ant-tabs-ink-bar {
                background-color: var(--blue);
            }
        }
    }
}
.customersContainer {
    padding-top: 11px;
    .customersTopBlock {
        margin-bottom: 20px;
        .customerSearchClear {
            margin-top: 25px;
        }
        label {
            color: var(--black);
        }
    }
}

.buttons {
    margin-top: 15px;
}

.addBlacklist {
    margin-left: auto;
    padding-bottom: 20px;
}

.customersEmpty {
    padding-top: 25px;
    color: var(--darkGrey);
    @include fontRem(16, 700, 20);
}
