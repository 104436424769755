@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  [class*="tableRateItem"] {
    box-shadow: none;
    border: none;
  }
  .topbannerPairs {
    @include flex-center-vert;
    gap: 4px;
  }
  .carrierInfoSection {
    :global {
      .ant-divider {
        width: calc(100% + 50px) !important;
      }
    }

    .columnsGap {
      padding-top: 12px;
    }

    [class*="tableRateItem"] {
      box-shadow: none;
      border: none;
      height: 20px !important;
    }
  }
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .infoContainer {
    .heading {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }

    .infoParagraph {
      @include fontRem(14, 600, 20);
      color: var(--black);
      margin: 0;
      & > a {
        text-decoration: underline;
        color: var(--blueRadio);
      }
    }

    .viewHeaderItem {
      margin-bottom: 11px;
    }
    [class*="ghost"] {
      transition: 0.3s;
      button {
        padding: 5px !important;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey) !important;
    margin: 0 !important;
  }

  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
    [class*="tableRateItem"] {
      box-shadow: none;
      border: none;
      margin-left: -12px;
    }
  }

  
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}

.infoParagraph {
  display: inline-block;

  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
  [class*="tableRateItem"] {
    box-shadow: none;
    border: none;
    margin-left: -12px;
  }
}

.copyIconText {
  @include flex-center-vert;
  display: inline-flex;
  gap: 12px;
  .copyIcon {
    padding: 7px;
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    cursor: pointer;
    background: var(--white);
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
  &.copyActive {
    > .infoParagraph {
      color: var(--activeLink);
    }
  }
  &:hover {
    .copyIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}
.viewMoreBtn {
  text-decoration: underline;
  cursor: pointer;
  @include fontRem(14, 600, 20);
  color: var(--black);
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.paymentHeading {
  @include fontRem(20, 700, 20);
  color: var(--black);
  padding-top: 55px;
}
.infoCommentParagraph {
  cursor: pointer;
  p {
    @include fontRem(14, 600, 20);
    color: var(--black);
    text-decoration: underline;
  }
}

.downloadAllFiles {
  background-color: var(--white);
  box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
  border-radius: 3px;
  padding: 7px;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: var(--blue);
    }
  }
}
.popoverOverlay {
  .infoParagraph {
    text-align: right;
    display: block;
  }
  :global {
    .ant-divider {
      margin: 2px 0;
    }
    .ant-popover-inner {
      background-color: var(--white);
      border-radius: 6px;
      padding: 25px;
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
}

.row {
  display: flex;
  align-items: center;
  .col {
    min-width: 250px;
  }
}

.truckTypes {
  margin-top: 32px;
  display: flex;
  column-gap: 108px;
  row-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  .truckType {
    width: 150px;
    align-items: center;
    justify-content: space-between;
    display: flex;

  }
}