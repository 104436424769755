@import "/src/styles/mixins";
@import "/src/styles/pallete";

.selectMainWrapper {
    > label {
        display: block;
        @include fontRem(14, 400, 20);
        margin-bottom: 4px;
        color: var(--black);
    }
    .selectWrapper {
        height: 40px;
        position: relative;
        &.withSubChild {
            :global {
                .ant-select-tree-node-content-wrapper-normal {
                    font-weight: 400 !important;
                }
            }
        }
        &.required {
            .placeholder {
                &::after {
                    content: "*";
                }
            }
        }
        &.error {
            :global {
                .ant-select-selector,
                .ant-select-dropdown {
                    border-color: #ff5454 !important;
                }

                .ant-select-open {
                    .ant-select-selector {
                        border-radius: 6px 6px 0 0 !important;
                    }
                    .ant-select-dropdown {
                        border-radius: 0 0 6px 6px !important;
                    }
                }
            }
        }
        .placeholder {
            pointer-events: none;
            transition: 0.3s;
            @include absoluteCenter(vertical);
            left: 17px;
            background-color: var(--secondWhite);
            padding: 2px 7px;
            @include fontRem(14, 500, 22);
            display: flex;
            color: #98a2b2;
            z-index: 1;
            &::after {
                content: none;
                color: #ff5454;
                @include fontRem(12, 500, 20);
                margin-top: -5px;
                margin-left: 3px;
                display: block;
            }
        }
        .select {
            width: 100%;
            height: 100%;
            &:global {
                &.ant-select-disabled {
                    cursor: not-allowed !important;
                    * {
                        cursor: not-allowed !important;
                    }
                    .ant-select-selector {
                        background-color: var(--gray);
                        border-color: var(--gray);
                    }
                    + .placeholder-select {
                        background-color: var(--gray);
                    }
                }
            }
            &:hover {
                :global {
                    .ant-select-selector {
                        cursor: pointer;
                        filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
                    }
                }
            }
        }
        :global {
            .ant-select-selector {
                height: 100% !important;
                background-color: var(--secondWhite);
                border-color: var(--darkGrey);
                .ant-select-selection-search {
                    @include flex-center-vert;
                    color: var(--lightGray);
                    margin-inline-start: 16px;
                    inset-inline-start: 4px;
                    input {
                        padding-left: 0;
                        color: var(--black);
                        &:-webkit-autofill {
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: var(--black);
                        }
                    }
                }
                .ant-select-selection-placeholder {
                    @include flex-center-vert;
                    color: #98a2b2;
                }
            }
            .ant-select-open {
                &.ant-select-show-search {
                    .ant-select-selection-item {
                        opacity: 0.5;
                    }
                }
                .ant-select-selector {
                    height: 100%;
                    border-radius: 6px 6px 0 0;
                    border: 1.5px solid #379fff;
                    border-bottom: 0;
                }
                + .placeholder-select {
                    top: 0;
                    transform: translateY(calc(-50% - 3px));
                }
            }
            .ant-select-dropdown {
                &::after {
                    content: "";
                    top: 0;
                    right: 0;
                    width: calc(100% - 17px);
                    position: absolute;
                    height: 1px;
                    background-color: #c1cada;
                    pointer-events: none;
                }
                top: 38px !important;
                border-radius: 0 0 6px 6px !important;
                box-shadow: none;
                border: 1.5px solid #379fff;
                border-top: 0;
                padding: 0;
                background-color: var(--secondWhite);
                min-width: unset !important;
                width: 100% !important;
                .ant-select-tree {
                    background-color: var(--secondWhite);
                }
                .ant-select-tree-list-scrollbar {
                    width: 10px !important;

                    .ant-select-tree-list-scrollbar-thumb {
                        border-radius: 0 !important;
                        background: var(--scrollColor) !important;
                    }
                }
                .ant-select-tree-treenode {
                    width: 100%;
                    padding: 0;
                    color: var(--black);
                    > span {
                        padding: 8px 5px 8px 20px;
                    }
                    .ant-select-tree-indent {
                        display: none;
                    }
                    &:hover {
                        background-color: rgba(102, 118, 149, 0.2);
                    }
                    &.ant-select-tree-treenode-switcher-open {
                        .ant-select-tree-indent {
                            display: block;
                            padding-left: 0;
                        }
                    }
                    &.ant-select-tree-treenode-switcher-close {
                        .ant-select-tree-indent {
                            display: block;
                            padding-left: 0;
                        }
                    }
                }
                .ant-select-tree-treenode-active {
                    background-color: rgba(102, 118, 149, 0.2);
                }
                .ant-select-tree-node-content-wrapper {
                    outline: none !important;
                    @include fontRem(14, 500, 20);
                    @include flex-center-vert;

                    &.ant-select-tree-node-selected {
                        background-color: transparent !important;
                    }
                    &:hover {
                        background-color: transparent !important;
                    }
                    .ant-select-tree-title {
                        pointer-events: none;
                    }
                    &.ant-select-tree-node-content-wrapper-open {
                        pointer-events: none;
                        .ant-select-tree-title {
                            font-weight: 700;
                        }
                    }
                }
                .ant-select-tree-switcher {
                    display: none;
                }
            }
            .ant-select-arrow {
                svg {
                    fill: var(--lightGray);
                }
            }
        }
        &::after {
            content: "";
            @include absoluteCenter(vertical);
            height: 22px;
            width: 1px;
            background-color: #c1cada;
            right: 37px;
            user-select: none;
            pointer-events: none;
        }
        :global {
            .ant-select-selector {
                height: 100%;
                box-shadow: none !important;
                .ant-select-selection-overflow {
                    cursor: pointer;
                }
                .ant-select-selection-item {
                    background-color: transparent;
                    border: 0;
                    padding-left: 8px;
                    @include flex-center-vert;
                    color: var(--black);
                }
            }
        }

        &.selected {
            :global {
                .ant-select-selector {
                    border-color: var(--black);
                }
                .ant-select-open {
                    .ant-select-selector {
                        border-color: #379fff !important;
                    }
                }
            }
            .placeholder {
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }
        .selectedTags {
            display: flex;
            gap: 3px;
            p {
                padding: 3px 9px;
                background-color: var(--gray);
                @include flex-center-vert;
                justify-content: space-between;
                gap: 7px;
            }
        }
    }
    .selectedItems {
        position: relative;
        z-index: 2;
        @include flex-center-vert;
        background-color: var(--gray);
        padding: 8px;
        gap: 8px;
        p {
            @include fontRem(12, 500, 20);
            color: var(--black);
        }
    }
    :global {
        .ant-select-arrow,
        .ant-select-clear {
            width: unset;
            height: calc(100% - 2px);
            margin: 0 !important;
            vertical-align: initial !important;
            @include absoluteCenter(vertical);
            @include flex-center-vert;
        }
        .ant-select-clear {
            background-color: transparent !important;
            svg {
                path {
                    fill: var(--black);
                }
            }
        }
    }
}
.noResultFound {
    padding: 8px 11px;
    p {
        text-align: center;
        color: var(--lightGray);
        @include fontRem(14, 400, 20);
    }
}
.searchResultKeyword {
    color: var(--black) !important;
    background: rgba(56, 141, 255, 0.3);
}
.clearIcon {
    @include flex-center-vert;
}
