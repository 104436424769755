@import "src/styles/mixins";
@import "src/styles/pallete";

.billingWrapper {
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
    }
  }
  .billingHeading {
    @include fontRem(16, 700, 20);
    color: var(--black);
  }

  .factored {
    color: var(--black) !important;
    @include fontRem(16, 600, 20);
  }
  .uploadedList {
    @include flex-column;
    gap: 15px;
    .fileTyleHeading {
      @include fontRem(14, 600, 20);
    }
    p {
      @include fontRem(14, 500, 20);
    }
    .uploadedButtons {
      @include flex-center-vert;
      justify-content: flex-start;
      gap: 21px;
      .uploadControl {
        background-color: var(--white);
        box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
        border-radius: 3px;
        padding: 7px;
        cursor: pointer;
        transition: 0.1s;
        &:disabled {
          opacity: 0.6;
          cursor: not-allowed !important;
          svg {
            cursor: not-allowed;
          }
          &:hover {
            transform: scale(1);
          }
        }
        &:hover {
          transform: scale(1.1);
        }
        svg {
          width: 16px;
          height: 16px;
        }
        &.eyeIcon {
          cursor: pointer;
          svg {
            width: 19px;
            height: 13px;
          }
        }
        &.downloadBtn {
          svg {
            path {
              fill: var(--blue);
            }
          }
        }
        &.trashIcon {
          svg {
            path {
              fill: var(--persimmonRed);
            }
          }
        }
      }
    }
    button {
      padding-left: 0 !important;
      text-decoration: underline;
      transition: 0.3s !important;
      padding-bottom: 0 !important;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .acceptedToBilling {
    @include fontRem(14, 600);
    margin-bottom: 0.5em;
    text-align: right;
    color: var(--black);
  }
}
