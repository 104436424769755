@import "/src/styles/mixins";
@import "/src/styles/pallete";

.selectMarkingWrapper {
    width: 100%;
    height: 100%;
    .optionsContainer {
        width: 100%;
        color: var(--black) !important;
        @include flex-column;
        .searchResultKeyword {
            background-color: var(--blue);
        }
    }
}
.searchableSelectMainWrapper {
    > label {
        display: block;
        @include fontRem(14, 400, 20);
        margin-bottom: 4px;
        color: var(--black);
    }
}
.searchResultKeyword {
    color: var(--black) !important;
    background: rgba(56, 141, 255, 0.3);
}

.authoritiesContainer {
    width: 100%;
    span {
        color: var(--black);
        @include fontRem(14, 500, 17);
        word-break: break-all;
    }
    p {
        color: var(--blue);
        text-decoration: underline;
        @include fontRem(14, 500, 20);
        text-align: right;
        padding-right: 35px;
    }
}

.selectWrapper {
    height: 40px;
    position: relative;
    :global {
        .rc-virtual-list-scrollbar {
            width: 10px !important;
            background-color: var(--gray) !important;
        }
        .rc-virtual-list-scrollbar-thumb {
            background: var(--lightBlue) !important;
            border-radius: 0 !important;
        }
    }
    &.required {
        .placeholder {
            &::after {
                content: "*";
            }
        }
    }

    &.errors {
        :global {
            .ant-select-selector {
                border-color: #ff5454 !important;
            }
        }
    }
    .placeholder {
        width: 90%;
        pointer-events: none;
        transition: 0.3s;
        @include absoluteCenter(vertical);
        left: 11px;
        background-color: var(--white);
        padding: 2px 7px;
        @include fontRem(14, 500, 22);
        display: flex;
        color: #98a2b2;
        z-index: 1;
        &::after {
            content: none;
            color: #ff5454;
            @include fontRem(12, 500, 20);
            margin-top: -5px;
            margin-left: 3px;
            display: block;
        }
    }

    :global {
        .ant-select-arrow,
        .ant-select-clear {
            width: unset;
            height: calc(100% - 2px);
            margin: 0 !important;
            vertical-align: initial !important;
            @include absoluteCenter(vertical);
            @include flex-center-vert;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        .ant-select-clear {
            background-color: transparent !important;
            svg {
                path {
                    fill: var(--black);
                }
            }
        }
        .ant-select-selector {
            height: 100% !important;
            padding-left: 0 !important;
            border: 0.5px solid var(--headerInputColor) !important;
            border-radius: 0px 6px 6px 0px !important;
            background-color: var(--white) !important;
            .ant-select-selection-search {
                inset-inline-start: 0 !important;
            }
            .ant-select-selection-item {
                display: none;
            }
            input {
                height: 100% !important;
                padding-left: 17px !important;
                padding-right: 45px !important;
                background-color: var(--white) !important;
                color: var(--black) !important;
                &:hover {
                    border-color: var(--headerInputColor) !important;
                    box-shadow: none !important;
                }
                &::placeholder {
                    color: var(--darkGray);
                }
                &:-webkit-autofill {
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: var(--black);
                }
            }
        }

        .ant-select-open {
            .ant-select-selector {
                border-radius: 0px 6px 0 0px !important;
                border: 1.5px solid var(--blue) !important;
                border-bottom: 0 !important;
            }
        }
        .ant-select-focused {
            .ant-select-selector {
                border: 1.5px solid var(--blue) !important;
            }
        }
        .ant-select-dropdown {
            &::after {
                content: "";
                top: 0;
                right: 0;
                width: 100%;
                position: absolute;
                height: 1px;
                background-color: #c1cada;
                z-index: 3;
            }
            background-color: var(--secondWhite);
            top: 38px !important;
            border-radius: 0 0 6px 6px !important;
            box-shadow: none;
            border: 1.5px solid var(--blue) !important;
            padding: 0;
            border-top: 0 !important;
            min-width: unset !important;
            width: 100% !important; 
        }
    }

    :global {
        .ant-select-selector {
            [class*="searchResultKeyword"] {
                background-color: transparent;
            }
        }
    }

    &.showValue {
        :global {
            .ant-select-selection-item {
                display: flex !important;
                align-items: center;
                color: var(--black);
                * {
                    font-weight: 400;
                    color: var(--black);
                }
            }
            .ant-select-item-option-selected {
                background-color: transparent;
            }
        }
    }
    .headerItems {
        pointer-events: none;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 2px 18px;
        background-color: var(--searchHeaderColor) !important;
        color: var(--black);
    }
    .mainSearchItesWrapper {
        .authoritiesContainer {
            padding: 11px 18px;
            color: var(--black);
            &:hover {
                background-color: var(--tableScrollThumb);
            }
        }
    }
}
.clearIcon {
    @include flex-center-vert;
}
.noDataText {
    padding: 10px 17px;
    color: var(--black);
    pointer-events: none;
}
