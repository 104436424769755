@import "/src/styles/mixins";
@import "/src/styles/pallete";
.branchEmpty {
  padding-top: 25px;
  color: var(--darkGrey);
  @include fontRem(16, 700, 20);
}
.branchTopBlock {
  margin-bottom: 20px;
  .branchSearchClear {
    margin-top: 25px;
  }
}

