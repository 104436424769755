@import "/src/styles/mixins";
@import "/src/styles/pallete";

.reactResizable {
  position: relative;
  background-clip: padding-box;
}

.reactResizableHandle {
  position: absolute;
  right: -5px;
  bottom: 0;

  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.tableWrapper {
  :global {
    .highlight-row {
      background-color: #3289da4d!important;
    }
    .ant-checkbox-input {
      display: none;
    }
    .ant-checkbox {
      top: 0;
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            background-color: #a1b0cc;
          }
          &::before {
            content: "";
          }
        }
      }
    }
    .ant-checkbox-wrapper {
      @include flex-center-vert;
    }
    .ant-checkbox-inner {
      background-color: var(--white);
      border-radius: 3px;
      width: 24px;
      height: 24px;
      &::before {
        @include absoluteCenter(both);
        background-color: var(--white);
        width: 8px;
        height: 1px;
        z-index: 1;
      }
      &::after {
        background-color: #379fff;
        @include absoluteCenter(both);
        border: 0;
        width: 14px;
        height: 14px;
      }
    }
    .ant-checkbox-wrapper-checked {
      &:hover {
        .ant-checkbox-inner {
          background-color: var(--white) !important;
          border-color: #379fff !important;
        }
      }
    }
    .ant-checkbox-checked {
      &::after {
        content: none;
      }
      .ant-checkbox-inner {
        border-color: #379fff;
        background-color: var(--white);
      }
    }
    .ant-checkbox-wrapper-disabled {
      .ant-checkbox-inner {
        background-color: var(--gray);
      }
    }
  }
  &.autoWrapper {
    :global {
      .ant-table {
        overflow: initial;

        .ant-table-tbody {
          tr {
            td {
              padding-top: 20px;
              padding-bottom: 20px;
              p {
                @include fontRem(16, 600, 20);
              }
            }
          }
        }
      }
    }
  }
  .customTableCell {
    position: relative;
    padding: 8px;
    min-height: 54px;
    display: grid;
    align-items: center;
    .renderedItems {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        width: fit-content;
        max-width: 100%;
      }
    }
    .withoutTooltip {
      display: grid;
      align-items: center;
      height: 100%;
      overflow: initial;
    }
    .tableSwitcher {
      @include flex-center-vert;
      height: 100%;
    }
    .tdContent {
      @include flex-center-vert;
      gap: 8px;
      cursor: pointer;
      p {
        display: flex;
      }
      .infoIconText {
        @include flex-center-vert;
        gap: 8px;
      }
    }
  }
  :global {
    .ant-table-sticky-holder {
      background-color: var(--gray);
      box-shadow: 0 4px 10px rgba(#667695, 0.2);
      border-radius: 0 !important;
    }
    .ant-table-body {
      &::-webkit-scrollbar {
        height: 14px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        display: none;
        background-color: var(--tableScrollLine) !important;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
        background-color: var(--tableScrollThumb) !important;
      }
      scrollbar-color: var(--tableScrollThumb) var(--tableScrollLine);
    }
    .ant-table-sticky-scroll {
      border: 0;
      background-color: var(--tableScrollLine) !important;
      .ant-table-sticky-scroll-bar {
        background-color: var(--tableScrollThumb) !important;
      }
    }
    .ant-table-content {
      height: 100%;
      padding-bottom: 10px;
    }
    .ant-table {
      .highlighted-column {
        background: var(--tableColumnColor) !important;
        position: relative;
      }
      background-color: transparent;
      padding-bottom: 10px;

      &:hover {
        .ant-table-body {
          &::-webkit-scrollbar {
            height: 14px;
            background-color: var(--tableScrollLine) !important;
          }
          &::-webkit-scrollbar-track {
            display: initial;
          }

          &::-webkit-scrollbar-thumb {
            display: initial;
          }
        }
      }
      table {
        background-color: var(--white);
        border-radius: 0;
        .ant-dropdown-trigger {
          display: block;
          width: 100%;
          text-align: center;
          svg {
            path {
              fill: var(--lightGray);
            }
          }
        }
      }
    }
    .ant-table-cell {
      border-start-start-radius: 0 !important;
      border-start-end-radius: 0 !important;
    }
    .ant-table-thead {
      tr {
        :global {
          .ant-table-cell {
            border: 0;
          }
        }
        th {
          background: var(--gray);
          padding: 0 8px;
          height: 33px;
          &::before {
            background-color: var(--grayBlack) !important;
          }
          &.highlighted-column {
            background-color: var(--gray) !important;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        :global {
          .ant-table-cell {
            border: 0;
            color: var(--black);
            padding: 0 !important;
            a {
              background-color: transparent;
              color: var(--blueRadio);
              box-shadow: none;
            }
            .ant-checkbox-wrapper-disabled {
              visibility: hidden;
              opacity: 0;
            }
          }
        }
        .ant-divider {
          width: 1px;
          background: var(--black);
        }
        &:nth-child(even) {
          &.isMovedToCollection {
            background: #D7DFED;
          }
          background: rgba(102, 118, 149, 0.1);
          .ant-table-cell-row-hover {
            cursor: pointer;
          }
        }
        &:nth-child(odd) {
          &.isMovedToCollection {
            background: #EBF0FA;
          }
          background: var(--white);
          .ant-table-cell-row-hover {
            cursor: pointer;
          }
        }
        &:hover {
          td {
            background-color: var(--tableHoverColor) !important;
            &[class*="highlighted-colum"] {
              background-color: var(--tableColumnColor) !important;
            }
          }
          &.highlight-row {
            td {
              background-color: #3289da4d !important;
              &[class*="highlighted-colum"] {
                background-color: #3289da4d !important;
              }
            }
          }
        }
        td {
          padding: 8px;
          .ant-checkbox-wrapper {
            padding: 0 8px;
          }
          div {
            margin: 0;
            &[class*="default"] {
              display: inline-block;
            }
            :global {
              .ant-btn-link {
                padding-left: 0;
                color: #388dff;
                &:hover {
                  color: #1677ff;
                }
              }
            }
          }
        }
        &:hover {
          &:nth-child(even) {
            &:not(.highlight-row) {
              .ant-table-cell {
                background: var(--gray);
              }
            }

          }
          &:not(.highlight-row) {
            &:nth-child(odd) {
              .ant-table-cell {
                background: var(--white);
              }
            }
          }


        }
      }
    }
    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        @include flex-center-vert;
        height: 30px;
        width: 30px;
        justify-content: center;
        button {
          color: var(--blueGrayWhite);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGrayWhite);
          padding: 0;
        }

        &.ant-pagination-total-text {
          order: 3;
          width: auto;
          color: var(--blueGrayWhite);
        }
        &.ant-pagination-item {
          width: 30px;
          height: 30px;
          min-width: unset;
          margin-inline-end: 6px;
          &:hover {
            a {
              color: var(--black);
            }
          }
        }
        &.ant-pagination-item-active {
          background-color: transparent;
          border-color: var(--paginationActive);
          border-width: 2px;
          &:hover {
            color: var(--black);
          }
        }
        &.ant-pagination-prev,
        &.ant-pagination-next {
          background-color: var(--gray);

          a {
            @include flex-center-vert;
            justify-content: center;
            width: 100%;
            height: 100%;
            svg {
              width: 14px;
              height: 14px;
            }
          }
          &.ant-pagination-next {
            a {
              transform: rotate(180deg);
            }
          }
        }

        &.ant-pagination-options {
          width: auto;
          margin-inline: 20px;
          .ant-select-selector {
            border: none;
            color: var(--black);
            @include fontRem(14, 500);
            background-color: transparent;
            box-shadow: none !important;
            .ant-select-selection-item {
              color: var(--black);
              padding-inline-end: 14px;
            }
          }
          .ant-select-suffix {
            width: 9px;
            height: 5px;
            background-size: contain;
            background: no-repeat;
            mask: url("data:image/svg+xml;charset=utf8,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.43416 0L4.49414 3.0904L7.55412 0L8.49414 0.951402L4.49414 5L0.494141 0.951402L1.43416 0Z' fill='currentColor'/%3E%3C/svg%3E");
            background-color: var(--black);
            svg {
              display: none;
            }
          }
        }
        &.ant-pagination-jump-next,
        &.ant-pagination-jump-prev {
          align-items: flex-end;
          span {
            color: var(--black) !important;
          }
          .ant-pagination-item-ellipsis {
            color: var(--black);
          }
        }
      }
      .ant-select-dropdown {
        background-color: var(--white);
        .ant-select-item-option {
          @include flex-center;
          justify-content: center;
          * {
            color: var(--black);
            text-align: center;
          }
          &.ant-select-item-option-active {
            background-color: var(--gray);
          }
          &.ant-select-item-option-selected {
            background-color: var(--gray);
          }
        }
      }
    }
  }
  .retrieved {
    gap: 10px;
  }
  .timerText {
    @include fontRem(14, 500, 20);
    color: var(--black);
    &.warning {
      color: var(--brightYellow);
    }
    &.danger {
      color: var(--persimmonRed);
    }
  }
  .RowTextType {
    @include fontRem(14, 500, 20);
    color: var(--black);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.negativeText {
      color: red;
    }
  }
  .HeaderTitleWithSort {
    @include flex-center-vert;
    gap: 10px;
  }
  .headingCellText {
    @include fontRem(14, 500);
    color: var(--lightGray);
    margin: 0;
    position: relative;
    span {
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 0;
      right: -15px;
      border-radius: 50%;
      background-color: var(--blue);
    }
  }
  .poligonWrapper {
    @include flex-center-column;
    gap: 1px;
    cursor: pointer;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      visibility: hidden;
      opacity: 0;
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }

    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.phoneLinkInArray {
  @include fontRem(14, 500, 20);
  color: var(--black);
  text-decoration: underline;
  margin-right: 3px;
  white-space: nowrap;
  &:hover {
    color: var(--black);
  }
}

.phoneNameInArray {
  @include fontRem(14, 500, 20);
  color: var(--black);
  word-break: break-all;
}
.truncString {
  @include fontRem(14, 500, 20);
  color: var(--black);
  display: inline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.favoriteIconWrapper {
  width: 32px;
  height: 32px;
  @include flex-center-column;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
  margin-right: 23px !important;
}

.commentsLink {
  @include fontRem(14, 500, 20);
  color: var(--black);
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.addLoadBtn {
  button {
    width: auto !important;
    height: 30px !important;
    &:global {
      &.ant-btn {
        padding-top: 6px;
        font-size: 12px !important;
      }
    }
  }
}

.deny {
  @include fontRem(14, 500, 20);
  color: var(--persimmonRed);
  text-align: left;
}

.unlock {
  @include fontRem(14, 500, 20);
  color: var(--blue);
  text-decoration: underline;
  transition: 0.3s;
  display: inline-block;
  &:hover {
    transform: scale(1.1);
  }
}

.commentBlock {
  cursor: pointer;
  &.commentsZero {
    cursor: default;
  }
}
.paymentPlanButtons {
  > div {
    display: block !important;
  }
}
.statusText {
  @include fontRem(14, 500, 20);
  color: var(--black);
}

.chargeBack {
  @include flex-center-vert;
  gap: 7px;
}

.documentBlock {
  @include flex-center-vert;
  justify-content: space-between;
}

.commentText {
  display: block;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
}

.agentInvite {
  button {
    text-decoration: underline;
    color: var(--black) !important;
    padding-left: 0 !important;
    transition: 0.3s;
  }
  &:hover {
    button {
      transform: scale(1.05);
    }
  }
}

.allActionsButton {
  > div {
    &[class*="button"] {
      button {
        width: 138px !important;
        height: 30px;
        @include fontRem(12, 500, 20);
      }
    }
  }
}

.statusAction {
  @include flex-center-vert;
  gap: 6px;
  @include fontRem(14, 500, 20);
  color: var(--black);
  .cod,
  .denied,
  .consignment,
  .approved,
  .pending {
    width: 4px;
    min-height: 33px;
    background: var(--lightGray);
    border-radius: 6px;
  }
  .cod {
    background: var(--yellow);
  }
  .denied {
    background: var(--persimmonRed);
  }
  .consignment {
    background: var(--activeLink);
  }
  .approved {
    background: var(--primaryBtn);
  }
}
.claimed {
  opacity: 0.4;
  pointer-events: none;
}
.tooltipContent {
  padding: 0 8px;
  margin-left: -8px;
  margin-right: -8px;

  .tooltipValue {
    display: block;
  }
  &:not(:last-child) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--gray);
  }
}
.croppedToolTip {
  pointer-events: none;
}
.singleTooltip {
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  cursor: default;
  .customArrow {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    display: block;
    pointer-events: none;
    width: 16px;
    height: 16px;
    overflow: hidden;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%) rotate(180deg);
    &::before {
      position: absolute;
      bottom: 0;
      inset-inline-start: 0;
      width: 16px;
      height: 8px;
      background: var(--white);
      clip-path: polygon(
        1.6568542494923806px 100%,
        50% 1.6568542494923806px,
        14.34314575050762px 100%,
        1.6568542494923806px 100%
      );
      content: "";
    }
    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      bottom: 0;
      inset-inline: 0;
      margin: auto;
      border-radius: 0 0 2px 0;
      transform: translateY(50%) rotate(-135deg);
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      z-index: 0;
      background: transparent;
    }
  }
  .customContent {
    position: relative;
    .customInner {
      min-width: 32px;
      min-height: 32px;
      max-width: 260px;
      padding: 6px 8px;
      color: var(--black);
      text-align: start;
      text-decoration: none;
      word-wrap: break-word;
      background-color: var(--white);
      border-radius: 6px;
      box-shadow:
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
      P {
        @include fontRem(14, 400);
      }
    }
  }
}
.tooltipItemWrapper {
  width: 100%;
  overflow: hidden;
}
.tooltipMainItem {
  &:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray);
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}
.stateWrapperList {
  max-width: 400px;
  :global {
    .ant-col {
      &:not(:last-child) {
        span {
          display: inline-block;
          &::after {
            content: ", ";
          }
        }
      }
    }
  }
}
.copyActive {
  span {
    color: var(--activeLink) !important;
  }
}
.tableButtonsWrapper {
  > div {
    display: block !important;
  }
}
.payButtonComponent {
  width: 100%;
}


.popoverOverlay {
  :global {
    .ant-popover-inner {
      padding: 27px 15px 30px 29px;
      background-color: var(--white);
      border-radius: 6px;
      max-height: 400px;
      height: 350px;
      overflow: auto;
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
  .dividerinPopover {
    margin-left: -29px;
    width: calc(100% + 44px);
  }
  .popoverTitle {
    text-decoration: underline;
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: var(--blue) !important;
      cursor: pointer;
    }
  }
  .popoverInnerTitle {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    text-align: left;
  }
  .popoverValue {
    @include fontRem(14, 500, 20);
    color: var(--black);
    &.debt {
      color: var(--persimmonRed);
    }
  }
  .spinWrapper {
    height: 350px;
    @include flex-center;
  }
}