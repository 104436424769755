@import "/src/styles/mixins";
@import "/src/styles/pallete";


.radio {
  display: flex;
  :global {
      .ant-radio-wrapper {
        @include flex-center-vert;
        .ant-radio {
          width: 26px;
          height: 26px;
          .ant-radio-inner {
            position: relative;
            width: 100%;
            height: 100%;
            background: var(--white);
            border: 1px solid var(--blueGray);
            &::after {
              transform: none;
              background: var(--blueRadio);
              width: 14px;
              height: 14px;
              margin: 0;
              @include absoluteCenter(both);
            }
          }
        }
        .ant-radio-checked {
          .ant-radio-inner  {
            border: 1px solid var(--blueRadio);
          }
        }
        span {
          @include fontRem(14,600,20);
          color: var(--black);
        }
      }
    }
}