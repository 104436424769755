@import "/src/styles/mixins";
@import "/src/styles/pallete";

.settingsItems {
    .settingsTitle {
        margin-bottom: 22px;
        color: var(--black);
        @include fontRem(24, 700, 20);
    }
    button {
        width: auto !important;
        border-radius: 3px !important;
    }
}
