@import "/src/styles/mixins";
@import "/src/styles/pallete";

.carriersHeaderTabs {
  :global {
    .ant-tabs-nav {
      margin: 0 -20px 23px -20px;
      padding: 0 32px 0 35px;
      .ant-tabs-tab {
        padding: 13px 3px;
        &:not(:first-child) {
          margin: 0 0 0 41px;
        }
        .ant-tabs-tab-btn {
          @include fontRem(14, 500, 20);
          color: var(--darkGrey);
          transition: 0.1s;
          &:hover {
            color: var(--blue);
          }
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--blue);
          }
        }
      }
      .ant-tabs-ink-bar {
        background-color: var(--blue);
      }
    }
  }
}

.carriersEmpty {
  padding-top: 25px;
  color: var(--darkGrey);
  @include fontRem(16, 700, 20);
}
.filters {
  padding-bottom: 20px;
  justify-content: space-between;
  .carrierTopFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .carrierDayFilter {
      background: var(--white);
      border-radius: 6px;
      border: 1px solid var(--lightBlue);
      padding: 11px 17px;
      box-shadow: 0px 4px 4px 0px rgba(102, 118, 149, 0.15);
      position: relative;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 4px 10px 0px rgba(102, 118, 149, 0.15);
        transform: scale(1.05);
      }
      .carrierDayText {
        color: var(--black);
        @include fontRem(14, 500, 20);
      }
      .leftColorBlockYellow,
      .leftColorBlockGrey,
      .leftColorBlockRed,
      .leftColorBlockBlue {
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 100%;
        background: var(--blueRadio);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      .leftColorBlockYellow {
        background: var(--brightYellow);
      }
      .leftColorBlockGrey {
        background: var(--darkGrey);
      }
      .leftColorBlockRed {
        background: var(--persimmonRed);
      }
    }
    & .active {
      border: 1px solid #252733;
    }
  }
}
.reset {
  padding-bottom: 5px;
}

.addBlacklist {
  margin-left: auto;
}

.signUpLink {
    color: var(--lightGray);
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.copyActive {

  color: var(--activeLink) !important;
}