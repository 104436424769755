@import "/src/styles/pallete";
@import "/src/styles/mixins";

.header {
    position: relative;
    z-index: 9999;
    padding: 20px 33px 20px 44px;
    background-color: var(--gray);
    transition: 0.3s;
    margin-left: 240px;
    width: calc(100% - 240px);
    &.growHeader {
        margin-left: 100px;
        width: calc(100% - 100px);
    }
    &.overlayOpen {
        z-index: 9999;
    }
    button {
        @include buttonNulled;
    }
    ul {
        list-style-type: none;
    }
    .headerRow {
        @include flex-center-vert;
        justify-content: space-between;
        gap: 10px;
        .headerleft {
            flex: 1 1 auto;
            .headerLeftContent {
                position: relative;
                z-index: 104;
                @include flex-center-vert;
                pointer-events: none;
            }
        }
        .headerRight {
            .headerRightContent {
                @include flex-center-vert;
                gap: 27px;
                .userLogin {
                    .loginList {
                        > li {
                            position: relative;

                            > button {
                                gap: 10px;
                                color: var(--black);
                                @include flex-center-vert;
                                @include fontRem(16, 600, 19);
                                transition: 0.3s;
                                &:hover {
                                    transform: scale(1.1);
                                }
                                span {
                                    @include flex-center-vert;
                                    svg {
                                        path {
                                            fill: var(--black);
                                        }
                                    }
                                }
                            }
                            .userdropDown {
                                position: absolute;
                                top: calc(100% + 33px);
                                right: 0;
                                border-radius: 6px;
                                overflow: hidden;
                                width: 100%;
                                box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
                                min-width: 180px;
                                max-width: 180px;
                                visibility: hidden;
                                opacity: 0;
                                z-index: 1;
                                &.openedMenu {
                                    visibility: visible;
                                    opacity: 1;
                                }
                                li {
                                    button,
                                    a {
                                        @include buttonNulled;
                                        padding: 13px 16px;
                                        @include flex-center-vert;
                                        text-decoration: none;
                                        color: var(--black);
                                        @include fontRem(14, 500, 20);
                                        background-color: var(--white);
                                        width: 100%;
                                        justify-content: space-between;
                                        &:hover {
                                            background: var(--gray);
                                        }
                                    }
                                }
                            }
                            :global {
                                .ant-skeleton-input {
                                    background-image: linear-gradient(
                                        90deg,
                                        rgba($black, 0.06) 25%,
                                        rgba($black, 0.15) 37%,
                                        rgba($black, 0.06) 63%
                                    ) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(73, 80, 97, 0.6);
    backdrop-filter: blur(2px);
    z-index: 103;
}
