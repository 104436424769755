@import "/src/styles/mixins";
@import "/src/styles/pallete";

.permissionContainer {
    .header {
        :global {
            .ant-typography-secondary {
                @include fontRem(20, 700, 20);
                color: var(--black);
            }
        }
    }
    .infoHeading {
        @include fontRem(15, 500, 20);
        color: var(--darkGrey);
        margin-bottom: 0;
    }
    .infoParagraph {
        @include fontRem(15, 500, 20);
        color: var(--black);
    }
    .permissionItems {
        span {
            &:not(:last-child) {
                &::after {
                    content: ", ";
                }
            }
        }
    }
}
