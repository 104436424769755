@import "/src/styles/mixins";
@import "/src/styles/pallete";

.timePickerWrapper {
  position: relative;
  width: 100%;
  &.disabled {
    user-select: none;
    cursor: not-allowed;
    opacity: 0.5;
    .timeWrapper {
      background-color: var(--gray);
      border-color: transparent !important;
      .placeholder {
        background-color: var(--gray);
      }
    }
    * {
      cursor: not-allowed;
    }
  }
  &.errors {
    .timeWrapper {
      border-color: var(--persimmonRed) !important;
    }
    .dropdonwContainer {
      border-color: var(--persimmonRed) !important;
    }
  }
  ul {
    list-style-type: none;
    li {
      &:hover {
        .dropdonwContainer,
        .timeWrapper {
          border-color: #379fff !important;
        }
      }
    }
  }
  .timeWrapper {
    padding: 7px 17px;
    background-color: var(--white);
    width: 100%;
    height: 40px;
    @include flex-center-vert;
    gap: 1px;
    border: 1px solid var(--darkGrey);
    border-radius: 6px;
    position: relative;
    transition: 0.3s;
    &:hover {
      border: 1px solid #379fff !important;
      box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
    }
    > span {
      width: 4px;
      margin-right: 2px;
      @include flex-center-vert;
    }
    > p {
      @include fontRem(14, 500, 17);
    }
    &::after {
      content: "";
      @include absoluteCenter(vertical);
      height: 22px;
      width: 1px;
      background-color: var(--lavenderGray);
      right: 37px;
      user-select: none;
      pointer-events: none;
    }
    .timeIcon {
      @include absoluteCenter(vertical);
      right: 0;
      height: 100%;
      padding-inline: 8px;
      @include flex-center-vert;
      justify-content: center;
    }
    .resetButton {
      @include absoluteCenter(vertical);
      right: 40px;
      height: 100%;
      padding-inline: 8px;
      @include flex-center-vert;
      justify-content: center;
    }
    .placeholder {
      pointer-events: none;
      transition: 0.3s;
      @include absoluteCenter(vertical);
      left: 17px;
      background-color: var(--secondWhite);
      padding: 2px 7px;
      @include fontRem(14, 500, 22);
      display: flex;
      color: var(--darkGrey);
      z-index: 1;

      &::after {
        content: none;
        color: var(--persimmonRed);
        @include fontRem(12, 500, 20);
        margin-top: -5px;
        margin-left: 3px;
        display: block;
      }
    }
    &.required {
      .placeholder {
        &::after {
          content: "*";
        }
      }
    }
    &.openedInput {
      border-bottom: 0;
      border-radius: 6px 6px 0 0;
      border-color: var(--blue);
      &:hover {
        border-bottom: 0 !important;
      }
    }
    &.filled {
      .placeholder {
        top: 0;
        transform: translateY(calc(-50% - 5px));
      }
    }
    &.hasValue {
      border-color: var(--black);
      .placeholder {
        top: 0;
        transform: translateY(calc(-50% - 5px));
      }
    }
  }
  .amPmWrapper {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    .amPmChangers {
      svg {
        transform: rotate(90deg) translateX(-1px);
      }
      &:last-child {
        svg {
          transform: rotate(-90deg) translateX(1px);
        }
      }
    }
  }

  .amPmShower {
    width: auto !important;
  }
}
.input {
  width: 22px;
  display: inline-block;
  height: 100%;
  border: none;
  color: var(--black);
  background-color: transparent;
  @include fontRem(14, 500, 17);
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
.inputTime {
  cursor: pointer;
  width: 30px;
  display: inline-block;
  height: 100%;
  border: none;
  color: var(--black);
  background-color: transparent;
  @include fontRem(14, 500, 17);
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.dropdonwContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 3;
  padding: 14px 10px;
  border: 1px solid var(--lavenderGray);
  border-top: 0;
  border-radius: 0 0 6px 6px;
  &.openedDropdown {
    border-color: var(--blue);
  }
}
.dropdownWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 65px;
  gap: 8px;
  li {
    &:first-child {
      position: relative;
      &::after {
        content: ":";
        @include absoluteCenter(vertical);
        right: -5px;
        color: var(--darkGrey);
      }
    }
  }

  .inputWrapper {
    display: grid;
    justify-items: center;
    input {
      text-align: center;
      border: 1px solid #98a2b2 !important;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
      border-radius: 3px;
      width: 100%;
      height: 40px;
      &:disabled {
        cursor: default !important;
      }
    }
    > div {
      &:first-child {
        button {
          padding-top: 0 !important;
          padding-bottom: 6px !important;
        }
      }
      &:last-child {
        button {
          padding-top: 6px !important;
          padding-bottom: 0 !important;
        }
      }
    }
    .input {
      border: 1px solid #98a2b2 !important;
      &:focus {
        border-color: #379fff !important;
      }
    }
  }
}
.timePikerActions {
  margin-top: 30px;
  .resetWrapper {
    margin-top: 5px;
    button {
      padding-left: 0 !important;
    }
  }
  .butonsItems {
    @include flex-center-vert;
  }
}
