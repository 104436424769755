@import "/src/styles/mixins";
@import "/src/styles/pallete";

.uploadTitle {
  margin-bottom: 10px;
  @include fontRem(14, 600, 20);
  color: var(--black);
}
.upload {
  margin-bottom: 28px;
  background: rgba(56, 141, 255, 0.1);
  border: 1px dashed var(--blue);
  border-radius: 8px;
}
.radioWrapper {
  margin-block: 32px;
}
.blockTitle {
  @include fontRem(16, 700, 20);
  margin-bottom: 24px;
}
.paymentTypeWrapper {
  margin-top: 50px;
  .paymentTypeRadios {
    margin-bottom: 32px;
  }
}
.brokerPaymentTerm {
  margin-top: 38px;
}
.downloadFormats {
  @include fontRem(12, 400, 20);
  color: var(--blueGray);
}
.icon {
  position: absolute;
  display: block;
  right: 0;
  top: 6px;
}

.factoringTooltipHeader {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #282828 !important;
}

.fileType {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #98a2b2 !important;
}

.fileInfo {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17px !important;
  color: #252733 !important;
}
.actionsWrapper {
  @include flex-center-vert;
  justify-content: flex-end;
  gap: 20px;
  button {
    width: 30px !important;
    height: 30px !important;
    background-color: var(--white);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    @include flex-center;
    border: 0;
    outline: none;
    transition: 0.1s;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed !important;
      svg {
        cursor: not-allowed;
      }
      &:hover {
        transform: scale(1);
      }
    }
    &:hover {
      transform: scale(1.1);
    }
    &:nth-child(2) {
      svg {
        path {
          fill: var(--blue);
        }
      }
    }
  }
}
.interested {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #252733 !important;
}
