@import "/src/styles/mixins";
@import "/src/styles/pallete";

.selectMarkingWrapper {
    width: 100%;
    height: 100%;
    .optionsContainer {
        width: 100%;
        color: var(--black) !important;
        .searchResultKeyword {
            background-color: var(--blue);
        }
    }
}
.searchableSelectMainWrapper {
    > label {
        display: block;
        @include fontRem(14, 400, 20);
        margin-bottom: 4px;
        color: var(--black);
    }
}
.searchResultKeyword {
    color: var(--black) !important;
    background: rgba(56, 141, 255, 0.3);
}

.authoritiesContainer {
    width: 100%;
    padding: 2px 18px;
    div {
        color: var(--black);
        @include fontRem(14, 500, 17);
        cursor: pointer;
        word-break: break-all;
    }
    p {
        color: var(--blue);
        text-decoration: underline;
        @include fontRem(14, 500, 20);
        text-align: right;
        padding-right: 35px;
    }
}

.selectWrapper {
    height: 40px;
    position: relative;
    :global {
        .rc-virtual-list-scrollbar {
            width: 10px !important;
            background-color: var(--gray) !important;
        }
        .rc-virtual-list-scrollbar-thumb {
            background: var(--lightBlue) !important;
            border-radius: 0 !important;
        }
    }
    &.required {
        .placeholder {
            &::after {
                content: "*";
            }
        }
    }
    &.disabled {
        opacity: 0.7;
        .placeholder {
            background-color: var(--gray) !important;
        }

        :global {
            .ant-select {
                cursor: not-allowed !important;
            }
            .ant-select-selector {
                border-color: var(--gray) !important;
                background-color: var(--gray) !important;

                cursor: not-allowed !important;
                pointer-events: none;
                .ant-select-selection-overflow {
                    cursor: not-allowed !important;
                }
                input {
                    cursor: not-allowed !important;
                }
            }
        }
        &:hover {
            :global {
                .ant-select-selector {
                    border-color: var(--gray) !important;
                }
            }
        }
    }
    &.errors {
        :global {
            .ant-select-selector {
                border-color: #ff5454 !important;
            }
            .ant-select-open {
                .ant-select-selector {
                    border-color: #ff5454 !important;
                }
            }
            .ant-select {
                .ant-select-selector {
                    border-color: #ff5454 !important;
                }
            }
            .ant-select-dropdown {
                border-color: #ff5454 !important;
            }
        }
    }
    .placeholder {
        pointer-events: none;
        transition: 0.3s;
        @include absoluteCenter(vertical);
        left: 11px;
        background-color: var(--secondWhite);
        padding: 2px 7px;
        @include fontRem(14, 500, 22);
        display: flex;
        color: #98a2b2;
        z-index: 1;
        &::after {
            content: none;
            color: #ff5454;
            @include fontRem(12, 500, 20);
            margin-top: -5px;
            margin-left: 3px;
            display: block;
        }
    }

    :global {
        .ant-select-arrow,
        .ant-select-clear {
            width: unset;
            height: calc(100% - 2px);
            margin: 0 !important;
            vertical-align: initial !important;
            @include absoluteCenter(vertical);
            @include flex-center-vert;
            svg {
                width: 13px;
                height: 13px;
            }
        }
        .ant-select-clear {
            background-color: var(--white) !important;
            svg {
                path {
                    fill: var(--lightGray);
                }
            }
        }
        .ant-select-selector {
            border-color: var(--darkGrey) !important;
            background-color: var(--secondWhite) !important;
            height: 100% !important;

            .ant-select-selection-item {
                display: none !important;
            }

            .ant-select-selection-search {
                @include flex-center-vert;
                width: 100% !important;
                margin-inline-start: 0 !important;
                input {
                    color: var(--black) !important;
                    padding-left: 8px !important;
                    &:-webkit-autofill {
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: var(--black);
                    }
                }
            }
            .ant-select-selection-placeholder {
                @include flex-center-vert;
            }
        }
        .select {
            width: 100%;
            height: 100%;
            &:hover {
                :global {
                    .ant-select-selector {
                        border-color: var(--lightBlue) !important;
                        border-radius: 6px !important;
                    }
                }
            }
        }

        .ant-select-tree-checkbox-checked {
            .ant-select-tree-checkbox-inner {
                border-color: var(--lightBlue);
            }
            &:hover {
                .ant-select-tree-checkbox-inner {
                    background-color: #fff !important;
                    border-color: var(--lightBlue) !important;
                }
            }
            &::after {
                content: none;
            }
            .ant-select-tree-checkbox-inner {
                background-color: #fff;
            }
        }
        .ant-select-tree-checkbox-inner {
            width: 20px !important;
            height: 20px !important;
            border-radius: 3px !important;
            &::after {
                background-color: #379fff;
                position: absolute;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%) rotate(0) !important;
                border: 0 !important;
                width: 11px !important;
                height: 11px !important;
            }
        }
        .ant-select-open {
            .ant-select-selector {
                height: 100%;
                border-radius: 6px 6px 0 0 !important;
                border: 1.5px solid var(--blue) !important;
                border-bottom: 0 !important;
            }
            + .placeholder-select {
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
            + p {
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }

        .ant-select-dropdown {
            &::after {
                content: "";
                top: 0;
                right: 0;
                width: calc(100% - 17px);
                position: absolute;
                height: 1px;
                background-color: #c1cada;
                z-index: 3;
            }
            background-color: var(--secondWhite);
            top: 38px !important;
            border-radius: 0 0 6px 6px !important;
            box-shadow: none;
            border: 1.5px solid var(--blue) !important;
            padding: 0;
            border-top: 0 !important;
            .ant-select-item {
                background-color: transparent !important;
                padding: 0;
                color: var(--black);
            }
            .ant-select-tree-treenode {
                width: 100%;
                padding: 8px 5px 8px 20px;
            }
            .ant-select-tree-node-content-wrapper {
                outline: none !important;
                @include fontRem(14, 500, 20);
                @include flex-center-vert;
                &.ant-select-tree-node-selected {
                    background-color: transparent !important;
                }
                &:hover {
                    background-color: transparent !important;
                }
            }
            .ant-select-tree-switcher {
                display: none;
            }
            .ant-select-item-option-content {
                color: var(--black);
                text-overflow: initial;
                overflow: initial;
                white-space: initial;
                > * {
                    text-overflow: initial;
                    overflow: initial;
                    white-space: initial;
                }
            }
            .ant-select-item-option {
                display: block !important;
            }
        }
    }
    &::after {
        content: "";
        @include absoluteCenter(vertical);
        height: 22px;
        width: 1px;
        background-color: #c1cada;
        right: 37px;
        user-select: none;
        pointer-events: none;
    }
    :global {
        .ant-select-selector {
            height: 100%;
            box-shadow: none !important;
            border-radius: 6px !important;
            .ant-select-selection-overflow {
                cursor: pointer;
            }
            .ant-select-selection-item {
                background-color: transparent;
                border: 0;
                padding-left: 8px;
                @include flex-center-vert;
            }
            [class*="searchResultKeyword"] {
                background-color: transparent;
            }
        }
    }

    &.selected {
        :global {
            .ant-select-selector {
                border-color: var(--black);
            }
        }
        .placeholder {
            top: 0;
            transform: translateY(calc(-50% - 3px));
        }
    }
    &.hasValue {
        :global {
            .ant-select-selector {
                border-color: var(--black);
            }
            .ant-select-focused {
                .ant-select-selector {
                    border-color: var(--blue) !important;
                }
            }
        }
        .placeholder {
            top: 0;
            transform: translateY(calc(-50% - 3px));
        }
    }
    &.onFocus {
        :global {
            .ant-select-selector {
                border-color: var(--blue) !important;
            }
        }
    }
    .selectedTags {
        display: flex;
        gap: 3px;
        p {
            padding: 3px 9px;
            background-color: var(--gray);
            @include flex-center-vert;
            justify-content: space-between;
            gap: 7px;
        }
    }
    &.showValue {
        :global {
            .ant-select-selection-item {
                display: flex !important;
                align-items: center;
                color: var(--black);
                * {
                    font-weight: 400;
                    color: var(--black);
                }
            }
            .ant-select-item-option-selected {
                background-color: transparent;
            }
        }
        &.hasValue {
            :global {
                .ant-select-selector {
                    border-color: var(--black) !important;
                }
                .ant-select-focused {
                    .ant-select-selector {
                        border-color: var(--blue) !important;
                    }
                }
            }
            .placeholder {
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }
    }
}

:global {
    .ant-select-dropdown .ant-select-item-option-state {
        display: none;
    }
}
.headerItems {
    pointer-events: none;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 2px 18px;
    background-color: var(--searchHeaderColor) !important;
    color: var(--black);
}
.filterSelectWrapper {
    .placeholder {
        left: 11px !important;
    }
    :global {
        .ant-select-selector {
            padding-right: 50px !important;
            .ant-select-selection-search {
                input {
                    &.ant-select-selection-search-input {
                        padding-left: 6px !important;
                    }
                }
            }
        }
    }
    &.hasValue {
        :global {
            .ant-select-open {
                .ant-select-selector {
                    height: 100%;
                    border: 1.5px solid #379fff;
                    border-color: var(--blue) !important;
                    border-bottom: 0;
                }
                + .placeholder-select {
                    top: 0;
                    transform: translateY(calc(-50% - 3px));
                }
            }
        }
    }

    &:hover {
        :global {
            .ant-select-selector {
                border-color: var(--blue) !important;
                border-radius: 6px 6px !important;
                filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
            }
            .ant-select-dropdown {
                border: 1.5px solid var(--blue) !important;
                border-top: 0 !important;
                border-radius: 0 0 6px 6px !important;
            }
        }
    }
    :global {
        .ant-select-dropdown {
            &::after {
                content: "";
                top: 0;
                right: 0;
                width: calc(100% - 17px);
                position: absolute;
                height: 1px;
                background-color: #c1cada;
                pointer-events: none;
            }
            top: 38px !important;
            border-radius: 0 0 6px 6px !important;
            box-shadow: none;
            border: 1.5px solid #379fff;
            border-top: 0;
            padding: 0;
            background-color: var(--secondWhite);
            min-width: unset !important;
            width: 100% !important;
            .ant-select-tree {
                background-color: var(--secondWhite);
            }
            .ant-select-tree-list-scrollbar {
                width: 10px !important;

                .ant-select-tree-list-scrollbar-thumb {
                    border-radius: 0 !important;
                    background: var(--scrollColor) !important;
                }
            }
        }
        .ant-select-open {
            .ant-select-selector {
                height: 100%;
                border-radius: 6px 6px 0 0 !important;
                border: 1.5px solid #379fff;
                border-color: var(--blue) !important;
                border-bottom: 0;
            }
            + .placeholder-select {
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
            &:hover {
                .ant-select-selector {
                    border-radius: 6px 6px 0 0 !important;
                }
            }
            + p {
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }
    }
    .optionsContainer {
        @include fontRem(14, 500, 20);

        span {
            color: var(--black) !important;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .optionsContainer {
            padding: 10px 10px 10px 17px;
            &:hover {
                background-color: rgba(102, 118, 149, 0.2) !important;
            }
        }
    }
}
.noDataText {
    pointer-events: none;
    padding: 4px 17px !important;
    color: var(--black);
    span {
        display: none;
    }
}
.optionsCustomWrapper {
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }

    .authoritiesContainer {
        padding-block: 8px;
        &:hover {
            background-color: var(--tableHoverColor);
        }
    }
}
