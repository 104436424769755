@import "/src/styles/mixins";
@import "/src/styles/pallete";

.checkbox {
    input {
        display: none;
    }
    :global {
        .ant-checkbox {
            top: 0;
        }
        .ant-checkbox-wrapper {
            @include flex-center-vert;
        }
        .ant-checkbox-inner {
            background-color: var(--white);
            border-radius: 3px;
            &::after {
                background-color: #379fff;
                @include absoluteCenter(both);
                border: 0;
            }
        }
    }
    &.default {
        :global {
            .ant-checkbox-inner {
                width: 24px;
                height: 24px;

                &::after {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
    &.small {
        :global {
            .ant-checkbox-inner {
                width: 20px;
                height: 20px;

                &::after {
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }
    :global {
        .ant-checkbox-wrapper-checked {
            &:hover {
                .ant-checkbox-inner {
                    background-color: var(--white) !important;
                    border-color: #379fff !important;
                }
            }
        }
        .ant-checkbox-checked {
            &::after {
                content: none;
            }
            .ant-checkbox-inner {
                border-color: #379fff;
                background-color: var(--white);
            }
        }
    }
}
