@import "/src/styles/mixins";
@import "/src/styles/pallete";

.heading {
  @include fontRem(20, 700, 20);
  color: var(--black);
}

.documentationHeading {
  @include fontRem(20, 700, 20);
  color: var(--black);
  padding-top: 55px;
}
.documentationWrapper {
  .infoParagraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
.viewHeaderItem {
  margin-bottom: 11px;
}

.downloadIcon {
  @include flex-center-vert;
  gap: 30px;
}
.infoCommentParagraph {
  cursor: pointer;
  display: inline-block;
  @include fontRem(14, 600, 20);
  margin: 0;
  text-decoration: underline;
  color: var(--black);
}
.buttonsWrapper {
  button {
    width: 100%;
  }
}

.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--grayWhite) !important;
  margin: 0 !important;
}
.infoParagraph {
  display: inline-block;
  @include fontRem(14, 600, 20);
  margin: 0;
  color: var(--black) !important;

  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
  &.red {
    color: var(--persimmonRed);
  }
}
.popoverTitle {
  text-align: right;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: var(--blue) !important;
    cursor: pointer;
  }
}
.popoverOverlay {
  :global {
    .ant-popover-inner {
      padding: 27px 15px 30px 29px;
      background-color: var(--white);
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
  .infoParagraph {
    word-break: break-all;
  }
}
.documentatitonWrapper {
  .infoParagraph {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-right: 30px;
  }
}
.viewDivider {
  width: calc(100% + 50px) !important;
}
.infoIcon {
  display: inline-flex;
  cursor: pointer;
}

.financeTitle {
  @include fontRem(14, 600, 20);
  color: var(--black) !important;
  margin-bottom: 0 !important;
}
.borderedColumn {
  &:not(:first-child) {
    border-left: 1px solid var(--gray);
  }
}

.newCarrierRateBtn {
  cursor: pointer;
  text-decoration: underline;
  color: var(--blue);
  @include fontRem(14, 500, 20);
}
.copyIconText {
  @include flex-center-vert;
  display: inline-flex;
  gap: 12px;
  .copyIcon {
    padding: 7px;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    cursor: pointer;
    background: var(--white);
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    &:hover {
      transform: scale(1.05);
    }
  }
  &.copyActive {
    > .infoParagraph {
      color: var(--activeLink) !important;
    }
  }
  &:hover {
    .copyIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}
.pickupDropOffMainContainer {
  overflow: auto;
  margin-bottom: 20px;
  :global {
    .ant-table-container {
      &::after {
        content: none;
      }
    }
    .ant-table {
      overflow: initial !important;
      table {
        padding-top: 0 !important;
      }
      .ant-table-cell {
        border-bottom: 1px solid var(--longBorder) !important;
        &.ant-table-cell-scrollbar {
          border-bottom: 1px solid transparent !important;
        }
      }
    }
    .ant-table-header {
      overflow: initial !important;
    }
    .ant-table-body {
      overflow: initial !important;
      min-height: 100px;
    }
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 15px;
  }
}
.fileItemView {
  align-items: stretch;
  :global {
    .ant-col {
      &:nth-last-child(2) {
        margin-left: auto;
        background-color: transparent;
        align-self: center;
      }
      p,
      h2 {
        margin-bottom: 0 !important;
      }
    }
  }
}
.blurred {
  position: relative;
  height: 47px;
  align-items: stretch;
  &::before {
    content: "";
    @include absoluteCenter(vertical);
    width: calc(75% - 20px);
    left: 10px;
    height: 1px;
    background-color: var(--black);
    z-index: 2;
  }

  p,
  h2 {
    position: relative;
    z-index: 2;
    opacity: 0.4;
    color: var(--black) !important;
    margin-bottom: 0 !important;
  }
  :global {
    .ant-col {
      background-color: var(--rateCrossed);
      display: grid;
      align-items: center;
      &:last-child {
        background-color: transparent;
        align-self: center;
      }
      &:nth-last-child(2) {
        margin-left: auto;
        background-color: transparent;
        align-self: center;
      }
    }
  }
}
