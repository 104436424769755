@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  @include flex-column;

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    margin: 0;
  }
  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
  }
  a.infoParagraph {
    color: var(--blue);
  }
  .infoParagraphBracket::after {
    content: " |";
  }
  .infoParagraphBracket:last-child::after {
    display: none;
  }
  .infoColumns {
    @include flex-center-vert;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .viewHeaderItem {
    margin-bottom: 11px;
  }
  [class*="ghost"] {
    transition: 0.3s;
    button {
      padding: 0 !important;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  pre {
    @include fontRem(14, 400, 20);
    color: var(--black);
    white-space: pre-wrap;
  }
}
.showMore {
  color: var(--blue);
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &.showMoreBlock {
    margin-top: 8px;
    margin-left: 0;
    display: block;
  }
}
