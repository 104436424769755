@import "/src/styles/mixins";
@import "/src/styles/pallete";

.layoutWrapper {
    height: 100vh;
    overflow: hidden;
    position: relative;
    .layoutLeft {
        background-color: var(--signinBlue);
    }
    @media all and (max-width: 1024px) {
        .layoutLeft {
            display: none;
        }
    }
    :global {
        .ant-row {
            height: 100%;
            background-color: var(--seondWhite);
        }
    }
    .componentsWrapper {
        position: relative;
        max-width: 600px;
        width: 100%;
        border: 1px solid var(--lightBlue);
        box-shadow: 0px 4px 60px rgba(37, 39, 51, 0.2);
        border-radius: 12px;
        background-color: var(--white);
        @include flex-center;
        margin: auto;
        padding: 53px 56px 50px;
        @media all and (max-width: 768px) {
            background-color: transparent;
            box-shadow: none;
            border: 0;
            padding: 0 25px 0;
        }
        form {
            @include flex-column;
            gap: 20px;
        }
    }
    .designedInfo {
        position: absolute;
        bottom: 80px;
        right: 100px;
        @media only screen and (max-width: 1600px) {
            bottom: 30px;
        }
        @media only screen and (max-width: 768px) {
            bottom: 25px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
        a {
            @include fontRem(12, 400, 18);
            color: var(--secondLightBlue);
            text-decoration: none;
        }
    }
}
