@import "/src/styles/mixins";
@import "/src/styles/pallete";

.searchableDropdownMain {
    width: 100%;
    position: relative;
    > label {
        display: block;
        @include fontRem(14, 400, 20);
        margin-bottom: 4px;
        color: var(--black);
    }
    .searchableDropdownWrapper {
        position: relative;
        z-index: 102;
        width: 100%;
        background-color: var(--secondWhite);
        border: 1px solid var(--darkGrey);
        border-radius: 6px;
        align-items: center;
        cursor: pointer;
        height: 40px;
        overflow: hidden;
        &.disabled {
            background-color: var(--gray);
            border-color: transparent !important;
            opacity: 0.7;
            * {
                cursor: not-allowed;
            }
            input {
                background-color: var(--gray);
                border-color: transparent !important;
                &:-webkit-autofill {
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: var(--black);
                }
                &::placeholder {
                    background-color: var(--gray) !important;
                }
            }
            .placeholder {
                background-color: transparent !important;
            }
            &:hover {
                border-color: transparent !important;
                box-shadow: none !important;
            }
        }
        &.wrapperLong {
            width: 130%;
            transition: 0.3s;
        }
        &.error {
            border-color: var(--persimmonRed) !important;
            .dropdownWrapper {
                border-color: var(--persimmonRed) !important;
            }
            .mistakeBorder {
                border-color: var(--persimmonRed) !important;
                &::after {
                    border-color: var(--persimmonRed) !important;
                }
            }
            &:hover {
                .dropdownWrapper {
                    border-color: var(--blue) !important;
                }
                .mistakeBorder {
                    border-color: var(--blue) !important;
                    &::after {
                        border-color: var(--blue) !important;
                    }
                }
            }
        }

        &.filled {
            overflow: visible;
            border-color: var(--black);
            &.disabled {
                .placeholder {
                    background-color: var(--gray) !important;
                }
            }
            .inputContainer {
                .placeholder {
                    top: 0;
                    transform: translateY(calc(-50% - 4px));
                }
            }
        }
        &.onFocus {
            border: 1px solid var(--activeLink);
            z-index: 999;
            border-radius: 6px 6px 0 0;
            overflow: visible;
            .inputBlock {
                &::placeholder {
                    color: transparent !important;
                }
            }
            .dropdownWrapper {
                border: 1px solid var(--activeLink);
                border-top: 0;
            }
        }
        &.required {
            .inputContainer {
                .placeholder {
                    &::after {
                        content: "*";
                    }
                }
            }
        }
        &:hover {
            box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2) !important;
            border-color: var(--blue) !important;
        }
        .inputContainer {
            position: relative;
            padding: 0 8px 0 17px;
            @include flex-center-vert;
            gap: 8px;
            height: 40px;
            .selectedItemsSingle,
            .selectedItems {
                cursor: pointer;
                position: relative;
                z-index: 1;
                @include flex-center-vert;
                background-color: var(--gray);
                padding: 0 8px 0 0;
                gap: 8px;
                p {
                    margin-top: 2px;
                    @include fontRem(12, 500, 20);
                    color: var(--black);
                }
            }
            .selectedItemsSingle {
                position: absolute;
                pointer-events: none;
                background-color: transparent;
                width: calc(100% - 60px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                p {
                    white-space: nowrap;
                    @include fontRem(14, 400, 20);
                }
            }
            .inputBlock {
                width: 100%;
                height: 100%;
                border: none;
                background-color: transparent;
                color: var(--lightGray);
                padding-right: 40px;
                &:focus {
                    + .placeholder {
                        top: 0;
                        transform: translateY(calc(-50% - 4px));
                    }
                }
            }
            .placeholder {
                pointer-events: none;
                transition: 0.3s;
                @include absoluteCenter(vertical);
                left: 17px;
                background-color: var(--secondWhite);
                padding: 2px 7px;
                @include fontRem(14, 500, 22);
                display: flex;
                color: #98a2b2;
                z-index: 1;
                &::after {
                    content: none;
                    color: var(--persimmonRed);
                    @include fontRem(12, 500, 20);
                    margin-top: -3px;
                    margin-left: 3px;
                    display: block;
                }
            }
            .inputBlock::placeholder {
                pointer-events: none;
                transition: 0.3s;
                background-color: var(--secondWhite);
                @include fontRem(14, 500, 22);
                display: flex;
                color: var(--darkGrey);
                z-index: 1;
                &::after {
                    content: none;
                    color: var(--persimmonRed);
                    @include fontRem(12, 500, 20);
                    margin-top: -5px;
                    margin-left: 3px;
                    display: block;
                }
            }
            &::after {
                content: "";
                @include absoluteCenter(vertical);
                height: 22px;
                width: 1px;
                background-color: #c1cada;
                right: 37px;
                user-select: none;
                pointer-events: none;
            }
            .suffixIconBlock {
                position: absolute;
                right: 10px;
                @include flex-center-vert;
                justify-content: center;
                width: 15px;
                height: 15px;
            }
        }
        .dropdownContainerWrapper {
            position: absolute;
            width: 100%;
            top: 100%;
            visibility: hidden;
            opacity: 0;
            &::after {
                content: "";
                top: 0;
                right: 0;
                width: 100%;
                position: absolute;
                height: 1px;
                background-color: #c1cada;
                pointer-events: none;
            }
            .dropdownWrapper {
                width: calc(100% + 2px);
                position: absolute;
                left: -1px;
                top: 100%;
                border-radius: 0 0 6px 6px;
                overflow-y: hidden;
                max-height: 250px;
                background-color: var(--searchHeaderColor);
                .innerScroller {
                    max-height: 250px;
                    overflow: hidden auto;
                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                }
                .dropdownColumnsSection {
                    width: 100%;
                    align-items: center;
                    padding: 0 18px;
                    @include fontRem(12, 500, 20);
                    color: var(--blueGray);
                    .checkbox {
                        display: none;
                        &.selected:checked + .checkboxMark::after {
                            content: "";
                            width: 11px;
                            height: 11px;
                            @include absoluteCenter(both);
                            border: 0;
                            background: #a1b0cc;
                        }
                        &.selectedAll + .checkboxMark {
                            border: 1px solid var(--activeLink);
                        }
                        &.selectedAll:checked + .checkboxMark::after {
                            content: "";
                            width: 11px;
                            height: 11px;
                            background: var(--activeLink);
                            @include absoluteCenter(both);
                        }
                    }
                    .checkboxMark {
                        cursor: pointer;
                        @include flex-center-vert;
                        background-color: var(--white);
                        border-radius: 3px;
                        margin: 0;
                        border: 1px solid #a1b0cc;
                        width: 20px;
                        height: 20px;
                    }
                }
                .dropdownOptionsSection {
                    cursor: pointer;
                    width: 100%;
                    background: var(--secondWhite);
                    display: flex;
                    flex-direction: column;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    .optionItem {
                        padding: 10px 18px;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid var(--lightBlue);
                        .checkbox {
                            display: none;
                            &:checked + .checkboxMark {
                                border: 1px solid var(--activeLink);
                            }
                            &:checked + .checkboxMark::after {
                                content: "";
                                width: 11px;
                                height: 11px;
                                background: var(--activeLink);
                                @include absoluteCenter(both);
                            }
                        }
                        .checkboxMark {
                            position: relative;
                            cursor: pointer;
                            @include flex-center-vert;
                            background-color: var(--white);
                            border-radius: 3px;
                            margin: 0;
                            border: 1px solid #a1b0cc;
                            width: 20px;
                            height: 20px;
                        }
                        .searchResultKeyword {
                            background-color: rgba(56, 141, 255, 0.4);
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        .resultText {
                            @include fontRem(14, 500, 20);
                            color: var(--black);
                            &:first-child {
                                word-break: break-all;
                            }
                            &.firstTextItem {
                                word-break: break-all;
                            }
                        }
                        &:hover {
                            background-color: rgba(102, 118, 149, 0.2);
                        }
                    }
                    .withoutBorder {
                        border-bottom: none;
                    }
                }
            }
            &.dropdownOpen {
                visibility: visible;
                opacity: 1;
            }
            &.dropdownRight {
                right: 0;
                .mistakeBorder {
                    left: 0;
                    &::after {
                        left: initial;
                        right: 0;
                        border-bottom-right-radius: 6px;
                        border-bottom-left-radius: 0;
                        border-right: 1px solid var(--blue);
                        border-left: 0;
                    }
                    &::before {
                        left: initial;
                        right: 0;
                    }
                }
            }
        }
    }
    .errorMessage {
        color: var(--persimmonRed);
        @include fontRem(14, 500, 20);
    }
}
.notDataItem {
    text-align: center;
    pointer-events: none;
    padding: 10px 11px;
    @include fontRem(14, 400, 20);
    color: var(--blueGray);
    opacity: 0.8;
}
.mistakeBorder {
    position: absolute;
    top: -1px;
    right: 0;
    border-top: 1px solid var(--blue);
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -0;
        width: 10px;
        height: 10px;
        transform: translateY(-100%);
        border-bottom-left-radius: 6px;
        border-left: 1px solid var(--blue);
        border-bottom: 1px solid var(--blue);
        z-index: 1;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 4px;
        height: 4px;
        background-color: var(--white);
    }
}
