@import "/src/styles/mixins";
@import "/src/styles/pallete";

html,
body {
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-family: "Inter", sans-serif !important;
    background-color: var(--white);
    color: var(--black);
    background-color: var(--white);
}
body {
    @media all and (max-width: 1280px) {
        zoom: 0.8;
    }
}
[class*="ant-col"],
[class*="ant-typography"],
[class*="ant-tree-select"] {
    font-family: "Inter", sans-serif !important;
}

* {
    box-sizing: border-box;
    font-family: "Inter", sans-serif !important;
}
::-webkit-scrollbar {
    width: 18px;
    @media only screen and (max-width: 1600px) {
        width: 10px;
    }
}
::-webkit-scrollbar-track {
    background: var(--white);
}

::-webkit-scrollbar-thumb {
    background: var(--scrollColor);
}

::-webkit-scrollbar-thumb:hover {
    background: darken(#c1cada, 5);
}
*::after,
*::before {
    box-sizing: border-box;
}

::-webkit-file-upload-button {
    appearance: button;
    -webkit-appearance: button;
    font: inherit;
}

img,
picture,
video,
canvas {
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    overflow-wrap: break-word;
    hyphens: auto;
    margin: 0;
}

button,
input,
select,
label {
    font-family: inherit;
    outline: none;
}

label {
    display: block;
}

button {
    cursor: pointer;
}

[type="button"],
[type="reset"],
[type="submit"] {
    appearance: buttom;
    -webkit-appearance: button;
}

hr {
    overflow: visible;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    white-space: normal;
}
ul,
ol {
    margin: 0;
    padding: 0;
}
pre {
    white-space: pre-wrap;
}
#root {
    height: 100%;
}
.App {
    height: 100%;
    > div {
        height: 100%;
        > div {
            &:first-child {
                height: 100%;
            }
        }
    }
}
.tabPanelButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.page-content {
    padding: 11px 20px 33px;
    &.sticky-requirements {
        padding-bottom: 0;
    }
}
.truck-board-tables-wrapper {
    position: relative;
    margin-top: 15px;
}

div {
    &[role="button"] {
        cursor: pointer;
    }
}
.noaOutlined {
    @include flex-center;
    width: 30px;
    height: 30px;
    background: var(--white);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    padding: 7px;
    transition: 0.3s;
    &:hover {
        transform: scale(1.05);
    }
}

.scrollBarWrapper {
    display: flex;
    height: 100%;
    .ant-affix {
        top: 0 !important;
    }
    .scrollBarContent {
        width: calc(100% - 210px);
        margin-left: 210px;
        &.openedDrawer {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.canceled-block {
    position: relative;
    pointer-events: none !important;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(152, 162, 178, 0.3) !important;
        z-index: 999;
        border-radius: 12px;
    }
}
.ant-popover {
    z-index: 9999;
    .ant-popover-inner {
        background-color: var(--white);
        border: 1px solid #fff;
        color: var(--black);
        span, p {
            color: var(--black);
        }
    }
}
.ant-tooltip {
    z-index: 9999;
    .ant-tooltip-inner {
        background-color: var(--white) !important;
        border: 1px solid #fff !important;
        color: var(--black);
        span, p {
            color: var(--black);
        }
    }
}
.ant-select-dropdown {
    z-index: 99999;
}
.ant-notification {
    z-index: 99999;
}
.ant-dropdown {
    z-index: 99999;
}
.ant-notification-notice {
    padding: 0 !important;
    border: 1px solid #fff;
    border-radius: 0 !important;
    background-color: var(--white) !important;
}
.ant-notification-notice-content {
    padding: 12px 10px 8px 12px;
    background-color: var(--white);
    .ant-notification-notice-message {
        color: var(--black) !important;
        @include fontRem(14, 400);
    }
    .ant-notification-notice-btn {
        button {
            margin-top: 0;
            position: absolute;
            top: 6px;
            right: 4px;
            background-color: transparent;
            border: 0;
            svg {
                width: 16px;
                height: 16px;
            }
            &:hover {
                svg {
                    path {
                        fill: var(--black);
                    }
                }
            }
        }
    }
}

.ant-notification-notice-close {
    right: 8px !important;
    top: 8px !important;
    display: none !important;
}
.light {
    input {
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px var(--white) inset !important;
        }
    }
    .ant-table-placeholder {
        background-color: var(--white) !important;
        pointer-events: none;
        > * {
            pointer-events: none;
        }
        .ant-empty-description {
            color: var(--black);
        }
    }
}
.dark {
    .react-datepicker__day-name {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    input {
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px var(--white) inset !important;
        }
    }
    .ant-table-placeholder {
        background-color: var(--white) !important;
        pointer-events: none;
        > * {
            pointer-events: none;
        }
        .ant-empty-description {
            color: var(--black);
        }
    }
}

.paddingBlock {
    padding-block: 35px 45px !important;
}
.local-loader {
    width: calc(100% - 65px);
    min-height: 100vh;
    background-color: var(--secondWhite);
    z-index: 99999;
    left: 65px;
    top: 0;
    bottom: 0;
    position: absolute;
}

.large-loader {
    width: calc(100% - 240px);
    height: calc(100%);
    top: 80px;
    left: 240px;
    right: 0;
}
.element-loader {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
}
.filtersWrapper {
    min-height: 84px;
}
.filtersWrapperBig {
    min-height: 144px;
}

.notficationItemPopup {
    border-radius: 0 !important;
    background-color: var(--white) !important;
    .ant-notification-notice-content {
        padding: 0;
        .ant-notification-notice-message {
            padding-inline-end: 0 !important;
            margin-bottom: 0;
        }
    }
    .notificationBox {
        &::after {
            content: none !important;
        }
    }
}
.tooltipCroped {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
