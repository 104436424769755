@import '/src/styles/mixins';
@import '/src/styles/pallete';

.requiredField::after {
    content: '*';
    vertical-align: super;
    @include fontRem(12, 500, 20);
    margin-left: 3px;
    display: inline-block;
    color: var(--persimmonRed);
}