@import "/src/styles/mixins";
@import "/src/styles/pallete";

.infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--grayWhite) !important;
    margin: 0 !important;
}

.infoParagraph {
    display: inline-block;
    @include fontRem(14, 600, 20);
    margin: 0;
    color: var(--black) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-right: 30px;
    & > a {
        text-decoration: underline;
        color: var(--blueRadio);
    }
    &.red {
        color: var(--persimmonRed);
    }
}
.popoverTitle {
    padding-right: 0;
    text-align: right;
    text-decoration: underline;
    &:hover {
        text-decoration: underline;
        color: var(--blue) !important;
        cursor: pointer;
    }
}
.popoverOverlay {
    :global {
        .ant-popover-inner {
            padding: 27px 15px 30px 29px;
            background-color: var(--white);
        }
        .ant-popover-arrow {
            &::before {
                background-color: var(--white);
            }
        }
    }
}
.fileItemWrapper {
    &:not(:last-child) {
        margin-bottom: 13px;
    }
    p {
        color: var(--black);
        @include fontRem(14, 500, 20);

        &.fileName {
            @include textShorten(1);
        }
    }
    .actionsWrapper {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 10px;
        button {
            padding: 0;
            border: 0;
            outline: none;
            transition: 0.1s;
            background-color: transparent;
            &:hover {
                transform: scale(1.1);
            }
            &.sendEmailBtn {
                text-decoration: underline;

                @include fontRem(14, 600);
                color: var(--black);
                &:hover {
                    transform: scale(1);
                    color: var(--blue);
                }
            }
        }
    }
}

.crossing {
    position: relative;
    height: 30px;
    &::before {
        content: "";
        @include absoluteCenter(vertical);
        width: calc(79.1666666667% - 20px);
        left: 10px;
        height: 1px;
        background-color: var(--black);
        z-index: 2;
    }

    p, h2 {
        position: relative;
        z-index: 2;
        opacity: 0.4;
        color: var(--black) !important;
    }
    :global {
        .ant-col {
            background-color: var(--rateCrossed);
            display: grid;
            align-items: center;
            &:last-child {
                background-color: transparent;
                align-self: center;
            }
        }
    }
}
