@import "/src/styles/mixins";
@import "/src/styles/pallete";


.infoHeading {
  @include fontRem(15, 500, 21);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}

.heading {
  @include fontRem(16, 700, 20);
  color: var(--black);
}
.popoverOverlay {
  .infoParagraph {
    display: block;
  }
  :global {
    .ant-divider {
      margin: 2px 0;
    }
    .ant-popover-inner {
      background-color: var(--white);
      border-radius: 6px;
      padding: 25px;
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
}
.popoverOverlayError {
  .infoParagraph {
    display: block;
  }
  :global {
    .ant-divider {
      margin: 2px 0;
    }
    .ant-popover-inner {
      background-color: var(--white);
      border-radius: 6px;
      padding: 8px;
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
}
.popoverIcon {
  width: fit-content;
  @include flex-center-vert;
}
