@import "/src/styles/mixins";
@import "/src/styles/pallete";

.requestError {
    padding: 6px 16px;
    background: rgba(255, 84, 84, 0.2);
    border-radius: 3px;
    width: 100%;
    p {
        @include flex-center-vert;
        gap: 16px;
        @include fontRem(14, 500, 20);
    }
    &.warningMode {
        background: rgba(#FF8718, 0.2);
        svg {
            path {
                fill: var(--orange);
            }
        }
    }
}
