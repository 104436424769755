@import "/src/styles/mixins";
@import "/src/styles/pallete";

.markasClaim {
  @include flex-center-vert;
  gap: 8px;
  margin-top: 20px;
  button {
    margin-top: 0 !important;
  }
}
.textItem {
  @include flex-center-vert;
  gap: 4px;
}
.informationFilter {
  padding: 0 0 40px;
  button {
    margin-top: 20px;
  }
}
.expensesWrapper {
  margin: 30px 0;
}
.tableTitle {
  @include fontRem(18, 700, 20);
  margin: 45px 0;
  color: var(--color) !important;
}
.tableWrapper {
  margin-top: 25px;
}
.finalPayBlock {
  display: block;
}
.finalPay {
  display: flex;
  justify-content: flex-end;
  p {

    @include fontRem(14, 400, 20);
    &:last-child {
      color: var(--blue);
      &.danger {
        color: var(--persimmonRed);
      }
      &.zeroPrice {
        color: var(--darkGrey);
      }
    }
  }
}

.titleNameAndCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .movedToCollection {
    width: 148px;
    height: 60px;
    border-radius: 6px;
    background: #F0F2F5;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #252733;
    }
    .amount {
      font-size: 16px;
      font-weight: 600;
      color: #379fff;
    }
  }
}