@import "/src/styles/mixins";
@import "/src/styles/pallete";

.locationWrapper {
    padding: 34px 36px;
    background-color: var(--white);
    border: 1px solid #d0d8e6;
    border-radius: 12px;
    .locationTitle {
        @include fontRem(18, 700, 20);
        margin-bottom: 32px;
        color: var(--black);
    }
}
.textarea {
    textarea {
        min-height: 40px;
        border-radius: 6px;
    }
}
.switchHeading {
    @include fontRem(14, 600, 20);
    color: var(--black);
}
