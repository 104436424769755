@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
    @include flex-column;
    .infoBlock {
        margin-top: 35px;
        row-gap: 30px;
        @include flex-column;
        .sumNoteError,
        .sumNote {
            @include fontRem(14, 600, 20);
            color: var(--black);
            .remainingSum {
                color: var(--blue);
            }
            .negative {
                color: var(--persimmonRed);
            }
        }
        .sumNoteError {
            color: var(--persimmonRed);
        }
    }
    .buttonsWrapper {
        margin-top: 30px;
    }
}
