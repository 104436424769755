@import "/src/styles/mixins";
@import "/src/styles/pallete";

.drawerWrapper {
  z-index: 9999;
  :global {
    @media only screen and (max-width: 1600px) {
      .ant-drawer-content-wrapper {
        min-width: 85% !important;
      }
    }
    .ant-drawer-content {
      position: relative;
      background-color: var(--gray);
      .ant-drawer-content {
        background-color: var(--white);
      }
      .ant-drawer-wrapper-body {
        max-width: 1177px;
        margin: auto;
        .ant-drawer-body {
          width: calc(100% - 65px);
          margin-left: 73px;
          padding-top: 73px;
          height: 100%;
          margin-bottom: 10px;
          transition: scroll-behavior 1.5s;
          scroll-behavior: smooth;
          &.ant-drawer-body_loader {
            overflow: hidden;
          }
          > div {
            &:first-child {
              height: 100%;
            }
          }
        }
      }

      .ant-drawer-footer {
        border: 0;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 83px);
        margin-left: 73px;
        padding-bottom: 0;
      }
    }
  }
  .drawerControlPanel {
    position: absolute;
    top: 0;
    left: 0;
    width: 65px;
    height: 100%;
    background-color: var(--drawerBg);
    @include flex-column;
    align-items: center;
    padding-top: 15px;
    border-right: 1px solid var(--longBorder);
    .controlButtons {
      @include flex-column;
      align-items: center;
      row-gap: 15px;
    }
    button {
      @include buttonNulled;
      height: 32px !important;
      box-shadow: none !important;
      transition: 0.3s;
      &:hover,
      &:focus {
        background-color: transparent !important;
      }
      &:hover {
        transform: scale(1.1);
      }
    }

    .drawerOpener {
      transition: 0.3s;
      @include absoluteCenter(both);
      &.rotatedArrow {
        transform: translate(-50%, -50%) rotate(180deg);
      }
      svg {
        width: 31px;
        transition: 0.3s;
        path {
          fill: #fff;
        }
      }
      &:hover {
        svg {
          transform: scale(1.1);
        }
      }
    }
  }
  .drawerContent {
    height: 100%;
    max-width: 1046px;
    :global {
      .ant-divider {
        border-color: var(--scrollColor);
        width: calc(100% + 61px);
      }
    }
  }
  .drawerFooter {
    background-color: var(--white);
    height: 100px;
    padding: 25px 40px;
    border-radius: 6px 6px 0 0;
    @include flex-center-vert;
    justify-content: space-between;
    gap: 10px;
    &.withScrollBar {
      width: calc(100% - 210px);
      margin-left: 210px;
    }
    &.withScrollBarOpened {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.fullWidthAllDrawers {
    :global {
      .ant-drawer-content {
        .ant-drawer-body {
          padding-top: 20px;
          width: calc(100% - 73px);
        }
        .ant-drawer-footer {
          width: calc(100% - 90px);
          margin-left: 73px;
        }
        .ant-drawer-wrapper-body {
          max-width: 100%;
        }
      }
    }
    .drawerContent {
      max-width: 100%;
    }
  }
  &.carrierDrawerCustomize {
    :global {
      .ant-drawer-content {
        .ant-drawer-body {
          padding-top: 20px;
          width: initial;
          margin-left: initial;
        }
        .ant-drawer-wrapper-body {
          max-width: calc(100% - 283px);
          margin-left: 275px;
          margin-right: 0;
        }
        .ant-drawer-footer {
          margin-left: 0;
          width: 100%;
        }
      }
      .scrollBarContent {
        width: 100%;
      }
      .ant-anchor-wrapper {
        padding-top: 24px !important;
      }
    }
    .drawerContent {
      max-width: 100%;
    }
    .drawerFooter {
      &.withScrollBar {
        width: calc(100% - 17px);
        margin-left: 0;
      }
    }
  }
}
