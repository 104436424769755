@import "/src/styles/mixins";

.contact {
  background: var(--white);
  border: 1px solid var(--drawerBorder);
  border-radius: 12px;
  padding: 30px 50px;
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
    }
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
  .bracked {
    &:last-child {
      :global {
        .ant-divider {
          display: none;
        }
      }
    }
  }
}

.subHeading {
  @include fontRem(18, 700, 20);
  margin-bottom: 20px;
}

.radio {
  gap: 50px;
  @include fontRem(14, 600, 20);
  color: var(--black);
}
