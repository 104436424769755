@import "/src/styles/mixins";
@import "/src/styles/pallete";

.containerPage {
    padding: 11px 0 0 0;
    background-color: var(--white);
}
.backToButtonWrapper {
    margin: 13px 0 13px 22px;
}

.signUpLink {
    color: var(--lightGray);
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.copyActive {
    color: var(--activeLink) !important;
  }