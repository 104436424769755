@import "/src/styles/mixins";
@import "/src/styles/pallete";

.expandedRowContent {
  padding: 36px 38px 36px 50px;
  background-color: var(--gray);
  .actions {
    margin-top: 26px !important;
  }
  :global {
    .ant-divider {
      width: calc(100% + 41px) !important;
      margin-top: 26px !important;
      margin-bottom: 15px !important;
    }
    .ant-select-dropdown {
      [class*="customDropDown"] {
        max-height: 110px;
      }
    }
  }
  .heading {
    @include fontRem(14, 500, 20);
    color: var(--lightGray);
  }
  .rows {
    margin-top: 20px !important;
    .paragraph {
      @include fontRem(14, 500, 20);
      color: var(--black);
    }
  }
}
