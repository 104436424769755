@import "/src/styles/mixins";
@import "/src/styles/pallete";

.requestInfoButtons {
  @include flex-column;
  align-items: flex-end;
  gap: 12px;
  button {
    min-width: 130px;
    border-radius: 6px !important;
  }
}
.heading {
  @include fontRem(20, 700, 20);
  color: var(--black);
}

.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey);
  margin: 0;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}
.infoParagraphDenied {
  color: var(--persimmonRed);
}
