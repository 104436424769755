@import "/src/styles/mixins";
@import "/src/styles/pallete";

.addCoordinatorWrapper {
    .coordinatorAdded {
        margin-top: 38px;
        span {
            @include fontRem(14, 500, 20);
        }
        button {
            width: 30px;
            height: 30px;
            padding: 7px;
            background-color: var(--white);
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
            border-radius: 3px;
            @include flex-center-vert;
        }
    }
    [class*="selectWrapper"] {
        height: 44px;
    }
}
.spinWrapper {
    min-height: 80px;
    @include flex-center;
}

