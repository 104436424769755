@import "/src/styles/mixins";
@import "/src/styles/pallete";

.chargebackContainer {
    background: var(--white);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    border-radius: 3px;
    padding: 11px 13px 26px;
    .normalDivider {
        &:global {
            &.ant-divider {
                margin: 10px 0;
                width: calc(100% + 13px) !important;
            }
        }
    }
    .collectionBlock {
        margin-top: 10px;
    }
    .subHeaderSummary,
    .subHeader {
        :global {
            .ant-typography-secondary {
                @include fontRem(14, 700, 20);
                color: var(--black);
            }
        }
    }
    .subHeaderSummary {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 5px;
        .percentage {
            margin-bottom: 0;
        }
        .positive {
            color: var(--primaryBtn);
        }
        .negative {
            color: var(--persimmonRed);
        }
        :global {
            .ant-divider {
                width: 2px !important;
                border-color: var(--black);
            }
        }
    }
    .chargesWrapper {
        margin-top: 15px;
        .title {
            @include fontRem(14, 600, 20);
            color: var(--black);
        }
        .titleValue {
            @include fontRem(14, 400, 20);
            color: var(--black);
        }

        .percentBlock {
            max-width: fit-content;
            @include flex-center-vert;
            gap: 5px;
            padding: 5px 10px;
            margin-top: 5px;
            border-radius: 3px;
            :global .ant-typography-secondary {
                @include fontRem(14, 600, 20);
                color: var(--black);
                margin-bottom: 0;
            }
            :global {
                .ant-divider {
                    width: 2px !important;
                    border-color: var(--black);
                }
            }
        }
        .positive {
            background: rgba(55, 159, 255, 0.2);
        }
        .negative {
            background: rgba(255, 84, 84, 0.2);
        }
    }
}
