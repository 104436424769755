@import "/src/styles/mixins";
@import "/src/styles/pallete";

.headerSearch {
    max-width: 526px;
    width: 100%;
    position: relative;
    transition: 0.3s;
    pointer-events: all;
    &.headerSearchLong {
        max-width: 838px;
    }
    input,
    button {
        height: 40px !important;
    }
}
