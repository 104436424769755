@import "/src/styles/mixins";
@import "/src/styles/pallete";

.employeeWrapper {
  form {
    @include flex-column;
    gap: 0;
  }
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
  :global {
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div > label {
        @include flex-center-vert;
        .ant-radio {
          width: 26px;
          height: 26px;
          .ant-radio-inner {
            position: relative;
            width: 100%;
            height: 100%;
            background: var(--white);
            border: 1px solid var(--blueGray);
            &::after {
              transform: none;
              background: var(--blueRadio);
              width: 14px;
              height: 14px;
              margin: 0;
              @include absoluteCenter(both);
            }
          }
        }
        .ant-radio-checked {
          .ant-radio-inner {
            border: 1px solid var(--blueRadio);
          }
        }
        span {
          @include fontRem(14, 600, 20);
          color: var(--black);
        }
      }
    }
  }
  .addedBlock {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .rowsGap {
    padding-top: 40px;
  }
  .rows {
    padding-top: 10px;
  }
  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .uploadSection {
    .allowedFormat {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
    }
    .uploadText * {
      @include fontRem(12, 400, 20);
      color: var(--black);
    }
  }
  .uploadHeading {
    @include fontRem(20, 600, 20);
    color: var(--black);
    margin-bottom: 30px;
    &::after {
      content: "*";
      @include fontRem(12, 500, 20);
      padding-left: 3px;
      vertical-align: super;
      color: var(--persimmonRed);
    }
  }

  .employeesSubheaderBottom,
  .employeesSubheader {
    margin-top: 22px;
    margin-bottom: 25px;
    p {
      margin-bottom: 30px;
      @include fontRem(18, 700, 20);
      color: var(--black);
    }
  }
  .employeesSubheaderBottom {
    margin-top: 70px;
  }
  .heading {
    margin-bottom: 30px;
  }
}
