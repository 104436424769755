@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tooltipCropped {
    cursor: initial;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
a.tooltipCropped {
    cursor: pointer;
}

.phoneNameItem {
    display: flex;
    align-items: center;
    max-width: 100%;
    gap: 4px;
    a {
        white-space: nowrap;
        color: var(--blue);
        text-decoration: none !important;
        @include fontRem(14, 600, 20);
        margin-bottom: 0 !important;
        &:hover {
            color: var(--blueRadio);
            text-decoration: underline !important;
        }
    }
}
