@import "/src/styles/mixins";
@import "/src/styles/pallete";

.rate {
    font-size: unset;
    @include flex-center-vert;
  
    :global {
        .ant-rate-star {
            &:not(:last-child) {
                margin-inline-end: 2px;
            }
            &.ant-rate-star-full {
                svg {
                    path {
                        fill: #fbbc05;
                    }
                }
            }
        }
    }
    &.canRate {
        * {
            cursor: pointer !important;
        }
    }
  }
  .tableRateItem {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 6px;
    height: 32px !important;
    &::after {
        content: none;
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 1;
        width: 7px;
        height: 9px;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.138672 9.34082V1.02832C0.138672 0.82832 0.213672 0.65332 0.363672 0.50332C0.513672 0.35332 0.688672 0.27832 0.888672 0.27832H6.38867C6.58867 0.27832 6.76367 0.35332 6.91367 0.50332C7.06367 0.65332 7.13867 0.82832 7.13867 1.02832V9.34082L3.63867 7.84082L0.138672 9.34082Z' fill='%23388DFF'/%3E%3C/svg%3E");
    }
    &.alreadyRated {
        border: 0;
        box-shadow: none;
        &::after {
            content: "";
        }
    }
    :global {
        .ant-rate {
            margin-top: -3px;
            .ant-rate-star {
                > div {
                    @include flex-center-vert;
                    height: 13px;
                }
            }
        }
    }
    > p {
        margin: -3px 5px 0 2px;
        @include fontRem(10, 500, 20);
        color: var(--darkGrey);
        @include flex-center-vert;
    }
  }
  .rateZero {
    cursor: default;
    * {
        cursor: default;
    }
  }
  .rateDropdownWrapper {
    position: relative;
    margin-top: -3px !important;
    .rateShower {
        @include fontRem(12, 500, 20);
        color: var(--blue);
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }
    .rateShowerNone {
        color: var(--black);
        cursor: auto;
        user-select: none;
        text-decoration: none;
    }
    
  }