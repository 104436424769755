@import "/src/styles/mixins";
@import "/src/styles/pallete";

.customersMainSelect {
  margin-bottom: 20px;
}

.fountdCount {
  margin: 11px 0 15px;
  @include fontRem(14, 700, 20);
}
.tableInfo {
  margin-bottom: 36px;
}
.reset {
  margin-top: 20px;
}

.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0;
}

.popoverOverlay {
  .infoParagraph {
    text-align: right;
  }
  :global {
    .ant-popover-inner {
      background-color: var(--white);
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
}

.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
  [class*="tableRateItem"] {
    box-shadow: none;
    border: none;
    margin-left: -12px;
  }
}
.searchInfo {
  margin-top: 20px;
  margin-left: 20px;
}
.tableWrapper {
  :global {
    .ant-table-body {
      min-height: 540px;
    }
  }
}
