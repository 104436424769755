@import "/src/styles/mixins";
@import "/src/styles/pallete";

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    span {
        &.badgeMain {
            border-radius: 10px;
            color: #fff;
            @include fontRem(12, 500, 20);
            padding: 0 20px;
        }
        &.badgeText {
            color: var(--black);
            @include fontRem(14, 500, 20);
        }
    }
    &.standart {
        span {
            &.badgeMain {
                background-color: #a1b0cc;
            }
        }
    }
    &.widthLoad {
        span {
            &.badgeMain {
                background-color: #fbbc05;
            }
        }
    }
    &.new {
        span {
            &.badgeMain {
                padding: 0 9px;
                background-color: #34a853;
            }
        }
        &.rightLabel {
            gap: 14px;
            span {
                &.badgeText {
                    @include fontRem(14, 600, 20);
                }
            }
        }
    }
    &.hoverState {
        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }
    &.rightLabel {
        flex-direction: row-reverse;
    }
}
