@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
      margin: 20px 0 30px;
    }
  }
  .employeeInfo {
    margin-bottom: 20px;
    background: var(--white);
    border: 1px solid var(--drawerBorder);
    border-radius: 12px;
    padding: 30px 50px;
    .secondHeading {
      margin-bottom: 25px;
    }
  }

  .headingBlock {
    @include flex-center-vert;

    a {
      word-break: break-all;
    }
  }

  .textWithLinks {
    display: flex;
    gap: 4px;
    a {
      text-decoration: underline;
    }
  }

  .agentInfo,
  .payroll {
    margin-top: 43px;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .bottomBlock {
    margin-top: 65px;
    .heading {
      margin-bottom: 26px;
    }
  }
  .viewHeaderItem {
    margin-bottom: 11px;
  }
  [class*="ghost"] {
    transition: 0.3s;
    button {
      padding: 0 !important;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.heading {
  @include fontRem(20, 700, 20);
  color: var(--black) !important;
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black) !important;
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
  a.infoParagraph {
    color: var(--blue);
  }
  &.tooltipCroped {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.infoParagraphReset {
  @include fontRem(14, 600, 20);
  color: var(--black);
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  display: inline-block;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.textWithLinks {
  display: flex;
  width: 100%;
  gap: 4px;
  a {
    text-decoration: underline;
  }
}
