@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tableWrapper {
    :global {
        .ant-table {
            background-color: transparent;
            table {
                thead {
                    tr {
                        th {
                            &.ant-table-cell {
                                padding: 8px 10px;
                                background-color: transparent;
                                vertical-align: bottom;
                                &::before {
                                    content: none !important;
                                }
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            &.ant-table-cell {
                                padding: 8px 10px;
                                background-color: transparent;
                                &.ant-table-cell-row-hover {
                                    background-color: var(--tableHoverColor) !important;
                                }
                                &::before {
                                    content: none !important;
                                }
                                p {
                                    @include fontRem(12, 500, 20);
                                    color: var(--black);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .HeaderTitleWithSort {
        @include flex-center-vert;
        gap: 10px;
        p {
            @include fontRem(12, 500, 20);
            color: var(--lightGray);
        }
        .poligonWrapper {
            @include flex-center-column;
            gap: 1px;
            cursor: pointer;
        }
        .downloadIconWrapper {
            width: 30px;
            height: 30px;
            padding: 7px;
            background-color: var(--white);
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
            border-radius: 3px;
            @include flex-center-vert;
            transition: 0.3s;
            &:hover {
                transform: scale(1.1);
            }
            svg {
                path {
                    fill: var(--blue);
                }
            }
        }
    }
    .viewDownloadItems {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 10px;
        .iconItem {
            width: 30px;
            height: 30px;
            padding: 7px;
            background-color: var(--white);
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
            border-radius: 3px;
            @include flex-center-vert;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
                transform: scale(1.1);
            }

            &.downladItem {
                svg {
                    path {
                        fill: var(--blue);
                    }
                }
            }
        }
    }
    :global {
        .ant-pagination {
            color: var(--blueGray);
            @include fontRem(14, 500);
            li {
                @include flex-center-vert;
                height: 30px;
                width: 30px;
                justify-content: center;
                button {
                    color: var(--blueGrayWhite);
                    span {
                        @include fontRem(14, 900);
                    }
                }
                a {
                    color: var(--blueGrayWhite);
                    padding: 0;
                }

                &.ant-pagination-total-text {
                    order: 3;
                    width: auto;
                    color: var(--blueGrayWhite);
                }
                &.ant-pagination-item {
                    width: 30px;
                    height: 30px;
                    min-width: unset;
                    margin-inline-end: 6px;
                    &:hover {
                        // background-color: transparent;
                        a {
                            color: var(--black);
                        }
                    }
                }
                &.ant-pagination-item-active {
                    background-color: transparent;
                    border-color: var(--paginationActive);
                    border-width: 2px;
                    &:hover {
                        color: var(--black);
                    }
                }
                &.ant-pagination-prev,
                &.ant-pagination-next {
                    background-color: var(--gray);

                    a {
                        @include flex-center-vert;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        svg {
                            width: 14px;
                            height: 14px;
                        }
                    }
                    &.ant-pagination-next {
                        a {
                            transform: rotate(180deg);
                        }
                    }
                }

                &.ant-pagination-options {
                    width: auto;
                    margin-inline: 20px;
                    .ant-select-selector {
                        border: none;
                        color: var(--black);
                        @include fontRem(14, 500);
                        background-color: transparent;
                        .ant-select-selection-item {
                            color: var(--black);
                            padding-inline-end: 14px;
                        }
                    }
                    .ant-select-suffix {
                        width: 9px;
                        height: 5px;
                        background-size: contain;
                        background: no-repeat;
                        mask: url("data:image/svg+xml;charset=utf8,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.43416 0L4.49414 3.0904L7.55412 0L8.49414 0.951402L4.49414 5L0.494141 0.951402L1.43416 0Z' fill='currentColor'/%3E%3C/svg%3E");
                        background-color: var(--black);
                        svg {
                            display: none;
                        }
                    }
                }
                &.ant-pagination-jump-next,
                &.ant-pagination-jump-prev {
                    align-items: flex-end;
                    span {
                        color: var(--black) !important;
                    }
                    .ant-pagination-item-ellipsis {
                        color: var(--black);
                    }
                }
            }
            .ant-select-dropdown {
                background-color: var(--white);
                .ant-select-item-option {
                    @include flex-center;
                    justify-content: center;
                    * {
                        color: var(--black);
                        text-align: center;
                    }
                    &.ant-select-item-option-active {
                        background-color: var(--gray);
                    }
                    &.ant-select-item-option-selected {
                        background-color: var(--gray);
                    }
                }
            }
        }
    }
}
