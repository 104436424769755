@import "/src/styles/mixins";
.agentCreateEditWrapper {
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
  form {
    gap: 0;
  }
  .bracked {
    &:last-child {
      :global {
        .ant-divider {
          display: none;
        }
      }
    }
  }
  .brackedButtonWrapper {
    button {
      height: 40px;
    }
  }
  .disabledTrashIcon {
    opacity: 0.5;
  }
  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .uploadSection {
    .allowedFormat {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
    }
    .uploadText * {
      @include fontRem(12, 400, 20);
      color: var(--black);
    }
  }
  .uploadHeading {
    @include fontRem(20, 600, 20);
    color: var(--black);
    margin-bottom: 30px;
    &::after {
      content: "*";
      @include fontRem(12, 500, 20);
      padding-left: 3px;
      vertical-align: super;
      color: var(--persimmonRed);
    }
  }

  .percent {
    @include fontRem(14, 500, 20);
    margin: 0;
  }

  .subHeading {
    @include fontRem(18, 700, 20);
    margin-bottom: 35px;
  }

  .trashIcon {
    width: 30px;
    height: 30px;
    @include flex-center-vert;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    background: var(--white);
    cursor: pointer;
  }

  .agentAddAuthorities {
    margin-top: 30px;
    margin-bottom: 30px;
    p {
      margin-bottom: 0;
      @include fontRem(18, 700, 20);
    }
  }

  .tableWrapper {
    :global {
      .ant-table {
        overflow: auto;
        table {
          padding-top: 12px;
          background-color: var(--white);
        }
      }
      .ant-table-thead {
        tr {
          th {
            background: transparent;
            padding: 0 6px;
            height: 33px;
            @include fontRem(14, 600, 20);
            border-bottom: 1px solid var(--gray);
            color: var(--black);
            &::before {
              display: none;
            }
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            padding: 8px;
            @include fontRem(14, 600, 20);
            color: var(--black);
            border-bottom: 1px solid var(--gray);
            div {
              margin: 0;
            }
            &.ant-table-cell-row-hover {
              background-color: var(--white) !important;
            }
          }
        }
      }
      .ant-pagination {
        color: var(--blueGray);
        @include fontRem(14, 500);
        li {
          button {
            color: var(--blueGray);
            span {
              @include fontRem(14, 900);
            }
          }
          a {
            color: var(--blueGray);
          }
          .ant-select-selector {
            border: none;
            color: var(--blueGray);
            @include fontRem(14, 500);
          }
        }
      }
    }
    .RowTextType {
      @include fontRem(14, 500, 20);
      color: var(--black);
    }
    .HeaderTitleWithSort {
      @include flex-center-vert;
      gap: 10px;
    }
    .headingCellText {
      @include fontRem(14, 500);
      color: var(--blueGray);
      margin: 0;
    }
    .poligonWrapper {
      @include flex-center-column;
      gap: 1px;
      cursor: pointer;
    }
  }
  .phoneLinkInArray {
    @include fontRem(14, 500, 20);
    color: var(--black);
    text-decoration: underline;
    margin-right: 3px;
    &:hover {
      color: var(--black);
    }
  }

  .phoneNameInArray {
    @include fontRem(14, 500, 20);
    color: var(--black);
  }

  .trashBlock {
    width: 30px;
    height: 30px;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .actionColumn {
    @include flex-center-column;
    width: 30px;
    height: 30px;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
  }

  .brackedsBlock {
    margin-top: 50px;
    :global {
      .ant-divider {
        margin: 17px 0;
      }
    }
  }

  .addedBlock {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .headingBlock {
    display: flex;
    align-items: center;
  }
  .resetBtnWrapper {
    [class*="button"] {
      button {
        padding-right: 0 !important;
      }
    }
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.05);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink) !important;
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}
