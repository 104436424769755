@import "/src/styles/mixins";
@import "/src/styles/pallete";

.columnItemTitle {
  @include fontRem(16, 700, 20);
  color: var(--black) !important;
  margin-bottom: 0 !important;
}
.textItem {
  @include flex-center-vert;
}
.topBannerActions {
  padding-right: 46px;
  .markasClaim {
    @include flex-center-vert;
    gap: 8px;
  }
  [class*="gray"] {
    button {
      &:hover {
        background-color: var(--persimmonRed);
        color: #fff;
      }
    }
  }
}
.carrierInfo {
  margin-top: 22px;
}
