@import "/src/styles/mixins";
@import "/src/styles/pallete";

.selectMainWrapper {
  > label {
    display: block;
    @include fontRem(14, 400, 20);
    margin-bottom: 4px;
    color: var(--black);
  }
  .selectWrapper {
    :global {
      .ant-select-clear {
        background: var(--white) !important;
      }
      .ant-space-item {
        button {
          @include fontRem(14, 500, 17);
          color: var(--blue);
          padding: 6px 13px 7px 9px;
          &:hover {
            background-color: rgba(#379fff, 0.1);
            border-radius: 3px;
            color: var(--blue);
          }
        }
      }
    }
    height: 40px;
    position: relative;
    &.required {
      .placeholder {
        &::after {
          content: "*";
        }
      }
    }
    &.error {
      :global {
        .ant-select-selector,
        .ant-select-dropdown {
          border-color: var(--persimmonRed) !important;
        }
        .ant-select-open {
          .ant-select-selector {
            border-radius: 6px 6px 0 0 !important;
          }
          .ant-select-dropdown {
            border-radius: 0 0 6px 6px !important;
          }
        }
      }
    }
    .placeholder {
      pointer-events: none;
      transition: 0.3s;
      @include absoluteCenter(vertical);
      left: 17px;
      background-color: var(--secondWhite);
      padding: 2px 7px;
      @include fontRem(14, 500, 22);
      display: flex;
      color: #98a2b2;
      z-index: 1;
      &::after {
        content: none;
        color: #ff5454;
        @include fontRem(12, 500, 20);
        margin-top: -5px;
        margin-left: 3px;
        display: block;
      }
    }
    .select {
      width: 100%;
      height: 100%;
      &:global {
        &.ant-select-disabled {
          cursor: not-allowed !important;
          * {
            cursor: not-allowed !important;
            opacity: 0.8;
          }
          .ant-select-selector {
            background-color: var(--gray);
            border-color: var(--gray);
          }
          + .placeholder-select {
            background-color: var(--gray);
          }
        }
      }
      &:hover {
        :global {
          .ant-select-selector {
            cursor: pointer;
            filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
          }
        }
      }
    }
    :global {
      .ant-select-selector {
        height: 100% !important;
        background-color: var(--secondWhite);
        border-color: #98a2b2;
        .ant-select-selection-search {
          @include flex-center-vert;
          color: var(--lightGray);
          margin-inline-start: 16px;
          inset-inline-start: 4px;
          input {
            padding-left: 0;
            color: var(--black);
            &:-webkit-autofill {
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: var(--black);
            }
          }
        }
        .ant-select-selection-placeholder {
          @include flex-center-vert;
          color: #98a2b2;
        }
      }
      .ant-select-open {
        &.ant-select-show-search {
          .ant-select-selection-item {
            opacity: 0.5;
            color: transparent;
          }
        }
        .ant-select-selector {
          height: 100%;
          border-radius: 6px 6px 0 0;
          border: 1.5px solid #379fff;
          border-bottom: 0;
        }
        + .placeholder-select {
          top: 0;
          transform: translateY(calc(-50% - 3px));
        }
      }
      .ant-select-dropdown {
        &::after {
          content: "";
          top: 0;
          right: 0;
          width: calc(100% - 17px);
          position: absolute;
          height: 1px;
          background-color: #c1cada;
          pointer-events: none;
        }
        top: 38px !important;
        border-radius: 0 0 6px 6px !important;
        box-shadow: none;
        border: 1.5px solid #379fff;
        border-top: 0;
        padding: 0;
        background-color: var(--secondWhite);
        min-width: unset !important;
        width: 100% !important;
        .ant-select-item {
          padding: 10px 10px 10px 17px;
          background-color: transparent !important;
          color: var(--black) !important;
          &:hover {
            background-color: rgba(102, 118, 149, 0.2) !important;
          }
        }
      }
      .ant-empty {
        display: none;
      }
      .ant-select-arrow {
        svg {
          fill: var(--lightGray);
        }
      }
    }
    &::after {
      content: "";
      @include absoluteCenter(vertical);
      height: 22px;
      width: 1px;
      background-color: #c1cada;
      right: 37px;
      user-select: none;
      pointer-events: none;
    }
    :global {
      .ant-select-selector {
        height: 100%;
        box-shadow: none !important;
        .ant-select-selection-overflow {
          cursor: pointer;
        }

        .ant-select-selection-item {
          background-color: transparent;
          border: 0;
          @include flex-center-vert;
          color: var(--black);
        }
      }
    }

    &.selected {
      :global {
        .ant-select-selector {
          border-color: var(--black);
        }
        .ant-select-open {
          .ant-select-selector {
            border-color: #379fff !important;
          }
        }
      }
      .placeholder {
        top: 0;
        transform: translateY(calc(-50% - 3px));
      }
    }
  }
  .selectedItems {
    position: relative;
    z-index: 2;
    @include flex-center-vert;
    background-color: var(--gray);
    padding: 8px;
    gap: 8px;
    p {
      @include fontRem(12, 500, 20);
      color: var(--black);
    }
  }
}
.enterItem {
  button {
    transition: 0.3s;
    padding-left: 17px !important;
    &:active,
    &:focus {
      background-color: var(--white) !important;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.clearIcon {
  @include flex-center-vert;
}
