@import "/src/styles/mixins";
@import "/src/styles/pallete";

.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey);
  margin: 0;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}
