@import "/src/styles/mixins";
@import "/src/styles/pallete";

.contactListContainer {
  padding: 11px 25px 0 25px;
  .contactListTopBlock {
    @include flex-center-vert;
    .contactListMainSelect {
      width: 300px;
      margin-bottom: 30px;
      label {
        color: var(--black);
        @include fontRem(14, 400, 20);
      }
    }
  }
}
.contactListEmpty {
  padding-left: 30px;
  padding-top: 25px;
  color: var(--darkGrey);
  @include fontRem(16, 700, 20);
}
