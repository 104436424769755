@import "/src/styles/mixins";
@import "/src/styles/pallete";

.regularLoadForm {
  :global {
    .ant-divider {
      width: calc(100% + 50px) !important;
    }
  }
  .pickUpsAndDropOffsSection,
  .loadInformationSection,
  .transactionSection,
  .topBanner {
    border: 1px solid var(--longBorder);
    padding: 31px 48px 61px 48px;
    background-color: var(--white);
    border-radius: 12px;
    .topBannerHeader {
      @include flex-center-vert;
      justify-content: space-between;
      .topBannerTitle {
        @include fontRem(24, 700, 20);
        color: var(--black);
        margin: 0;
      }
    }
    .columnItemTitle {
      @include fontRem(16, 700, 20);
      color: var(--black);
    }
    .textItem {
      @include fontRem(14, 600, 20);
      span,
      a {
        font-weight: 400;
      }
      a {
        color: var(--blue);
        text-decoration: underline;
      }
    }
    .topBannerActions {
      .markasClaim {
        @include flex-center-vert;
        gap: 8px;
      }
    }
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .topBannerBlock {
    margin-bottom: 35px;
    .takenAsBlock {
      padding: 10px 15px;
      background: rgba(#34a853, 0.2);
      border-radius: 3px;
      @include fontRem(14, 600, 20);
      @include flex-center-vert;
      gap: 4px;
      span {
        font-weight: 400;
      }
    }
    .generalInfoHeading {
      @include fontRem(18, 700, 20);
      color: var(--black);
    }
  }
  .switchHeading {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
  .switchBtns {
    display: flex;
    justify-content: flex-end;
  }
  .customerContactISize {
    max-width: 255px;
    width: 100%;
  }
  .subHeaderBlock {
    margin-top: 24px;
    .headerBlock {
      margin-bottom: 10px;
    }
    .subHeaderValue,
    .subHeader {
      @include fontRem(14, 700, 20);
      color: var(--black);
    }
    .subHeaderValue {
      color: var(--blueRadio);
    }
    .titleValue,
    .title {
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    .titleValue {
      @include fontRem(14, 400, 20);
    }
  }

  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .uploadSection {
    [class*="fileItemWrapper"] {
      margin-right: -61.5px;
    }
    :global {
      .ant-upload-btn {
        padding: 9px 0 !important;
      }
    }
    .documentationHeading {
      @include fontRem(16, 700, 20);
      color: var(--black);
    }
    .uploadHeading {
      @include fontRem(14, 600, 20);
      color: var(--black);
      padding-bottom: 7px;
    }
    .downloadAll {
      @include fontRem(14, 400, 20);
      color: var(--black);
      letter-spacing: 0.4px;
    }
    .allowedFormat {
      @include fontRem(12, 400, 20);
      color: var(--darkGrey);
      padding-top: 9px;
    }
    .downloadAllBlock {
      margin-bottom: 30px;
    }
    .uploadedList {
      @include flex-column;
      gap: 15px;
      p {
        @include fontRem(14, 500, 20);
      }
      .uploadedButtons {
        @include flex-center-vert;
        justify-content: flex-end;
        gap: 21px;
      }
    }
    .uploadControl {
      background-color: var(--white);
      box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      padding: 7px;
      svg {
        width: 16px;
        height: 16px;
      }
      &.eyeIcon {
        svg {
          width: 19px;
          height: 13px;
        }
      }
      &.downloadBtn {
        cursor: pointer;
        transition: 0.3s;
        height: 30px;
        &:hover {
          transform: scale(1.05);
        }
        svg {
          path {
            fill: var(--blue);
          }
        }
      }
    }
  }
}
.uploadRowWrapper {
  margin-top: 30px;
}
.active {
  border-radius: 12px;
  border: 3px solid var(--blue) !important;
}
