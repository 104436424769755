@import "src/styles/mixins";
@import "src/styles/pallete";

.mainPanel {
  position: absolute;
  top: 0;
  left: 65px;
  width: 210px;
  height: 100%;
  background-color: var(--white);
  z-index: 3;
  .scrollbarLinkWrapper {
    list-style-type: none;
    padding: 0;
    margin: 25px 0 0;
    .scrollBarLink {
      padding-inline: 29px 25px;
      padding-block: 7px;
      color: var(--blueRadio);
      @include fontRem(14, 600, 20);
      color: var(--blueRadio);
      white-space: initial;
      text-overflow: initial;
      overflow: initial;
      word-break: break-all;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
}
