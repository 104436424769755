@import "/src/styles/mixins";
@import "/src/styles/pallete";

.heading {
  @include fontRem(20, 700, 20);
  color: var(--black);
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}

.editBtn {
  text-align: right;
}
.agentInfo,
.payroll {
  margin-top: 43px;
}
.viewHeaderItem {
  margin-bottom: 11px;
}

.buttonsWrapper {
  @include flex-column;
  align-items: flex-end;
  row-gap: 10px;
  button {
    min-width: 130px;
    border-radius: 6px !important;
  }
}
