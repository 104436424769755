@import "/src/styles/mixins";
@import "/src/styles/pallete";

.payoutContainer {
    .payoutHeaderTabs {
        :global {
            .ant-tabs-nav {
                margin: 0 -20px 0 -20px;
                padding: 0 25px 0 25px;
                .ant-tabs-tab {
                    padding: 13px 3px;
                    &:not(:first-child) {
                        margin: 0 0 0 41px;
                    }
                    .ant-tabs-tab-btn {
                        @include fontRem(14, 500, 20);
                        color: var(--darkGrey);
                        transition: 0.1s;
                        &:hover {
                            color: var(--blue);
                        }
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: var(--blue);
                        }
                    }
                }
                .ant-tabs-ink-bar {
                    background-color: var(--blue);
                }
            }
        }
    }
}
.payoutMainSelect {
    padding-top: 35px;
    padding-bottom: 35px;
    .payoutResetBtn {
        margin-top: 20px;
    }
    .balanceInfo {
        margin-top: 35px;
    }
    .filtersInfo {
        @include fontRem(14, 600, 20);
        color: var(--black);
    }
    .filtersCount {
        @include fontRem(14, 600, 20);
        color: var(--blue);
    }
    .filtersCountRed{
        @include fontRem(14, 600, 20);
        color: var(--persimmonRed);
    }
}

.rowInfo {
    @include fontRem(20, 700, 20);
    color: var(--activeLink);
    margin: 5px;
}

.RowTextType {
    @include fontRem(14, 500, 20);
    color: var(--persimmonRed);
}
