@import "/src/styles/mixins";
@import "/src/styles/pallete";

.officeWrapper {
  :global {
    .ant-typography-secondary {
      @include fontRem(20, 700, 20);
      color: var(--black);
    }
  }
}
form {
  @include flex-column;
  gap: 0;
}
.upload {
  background: rgba(56, 141, 255, 0.1);
  border: 1px dashed var(--blue);
  border-radius: 8px;
}
.uploadSection {
  .allowedFormat {
    @include fontRem(12, 400, 20);
    color: var(--darkGrey);
  }
  .uploadText * {
    @include fontRem(12, 400, 20);
    color: var(--black);
  }
}
.uploadHeading {
  @include fontRem(20, 600, 20);
  color: var(--black);
  margin-bottom: 30px;
  &::after {
    content: "*";
    @include fontRem(12, 500, 20);
    padding-left: 3px;
    vertical-align: super;
    color: var(--persimmonRed);
  }
}

.addedBlock {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin-top: 40px;
  margin-bottom: 25px;
}
.rowsGap {
  padding-top: 40px;
}
.rows {
  padding-top: 10px;
}
.allowedFormat {
  & > h2 {
    @include fontRem(12, 400, 20);
    color: var(--darkGrey);
    padding-top: 18px;
  }
}
.uploadText * {
  @include fontRem(12, 400, 20);
  color: var(--black);
}
.officesAddAuthorities,
.payoutBlock {
  margin-top: 50px;
  p {
    &.title {
      margin-bottom: 64px;
      @include fontRem(14, 600, 20);
      color: var(--black);
      &::after {
        content: "*";
        @include fontRem(12, 500, 20);
        margin-left: 3px;
        display: inline-block;
        color: var(--persimmonRed);
      }
    }
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
}
.officesAddAuthorities {
  margin-top: 30px;
  margin-bottom: 30px;
  p {
    margin-bottom: 0;
    @include fontRem(18, 700, 20);
  }
}
.heading {
  margin-bottom: 30px;
}
.error {
  background: red;
}
