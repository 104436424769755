@import "/src/styles/mixins";
@import "/src/styles/pallete";

.loadForm {
  .uploadHeading,
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .uploadHeading {
    @include fontRem(14, 600, 20);
    margin-bottom: 15px;
  }
  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .downloadFormats {
    @include fontRem(12, 400, 20);
    color: var(--blueGray) !important;
  }
  .topBannerBlock {
    margin-bottom: 35px;
    .takenAsBlock {
      padding: 10px 15px;
      background: rgba(#34a853, 0.2);
      border-radius: 3px;
      @include fontRem(14, 600, 20);
      @include flex-center-vert;
      gap: 4px;
      span {
        font-weight: 400;
      }
    }
    .generalInfoHeading {
      @include fontRem(18, 700, 20);
      color: var(--black);
    }
  }

  .switchSection {
    margin-top: 35px;
    .switchHeading {
      @include fontRem(14, 600, 20);
      color: var(--black);
      margin: 0;
    }
  }
}

.editloadSection {
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
    margin-bottom: 25px;
  }
  :global {
    #pickupps-drop-offs {
      h2 {
        @include fontRem(16, 600, 20);
      }
    }
  }
}
.uploadSection {
  :global {
    .ant-upload-btn {
      padding: 9px 0 !important;
    }
  }
}
.allowedFormat {
  @include fontRem(12, 400, 20);
  color: var(--darkGrey) !important;
  padding-top: 9px;
}
.editBlock {
  margin-top: 30px;
}

.pickupDropOffMainContainer {
  overflow: auto;
  margin-bottom: 20px;
  :global {
    .ant-table-container {
      &::after {
        content: none;
      }
    }
    .ant-table {
      overflow: initial !important;
      table {
        padding-top: 0 !important;
      }
      .ant-table-cell {
        border-bottom: 1px solid var(--longBorder) !important;
        &.ant-table-cell-scrollbar {
          border-bottom: 1px solid transparent !important;
        }
      }
    }
    .ant-table-header {
      overflow: initial !important;
    }
    .ant-table-body {
      overflow: initial !important;
      min-height: 100px;
    }
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 15px;
  }
}
.active {
  border-radius: 12px;
  border: 3px solid var(--blue) !important;
}
