@import "/src/styles/mixins";
@import "/src/styles/pallete";

.fullWrapper {
  .customerInfo {
    .headingBlock {
      display: flex;
      align-items: center;
      p:first-child {
        @include fontRem(14, 600, 20);
        color: var(--black);
        margin-right: 3px;
      }
    }
  }
  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
    margin-bottom: 0;
  }
}
.rows {
  padding-top: 10px;
}

.tableWrapper {
  height: 100%;
  :global {
    .ant-table-expanded-row {
      background-color: transparent !important;
      &:hover {
        .ant-table-cell {
          background-color: transparent !important;
        }
      }
      .ant-table-cell {
        padding: 0 8px;
      }
    }
    .ant-table-expanded-row-fixed {
      background-color: var(--gray) !important;
      &:hover {
        background-color: var(--gray) !important;
      }
    }
    .ant-table-thead {
      > tr {
        th {
          border: none;
          color: var(--darkGrey);
          background: transparent;
          padding: 0 6px;
          height: 33px;
          @include fontRem(14, 500, 20);
          &::before {
            display: none;
          }
          &::after {
            content: none;
          }
          &.ant-table-cell-fix-right {
            text-align: right;
          }
        }
      }
    }
    .ant-table-tbody {
      > tr {
        @include fontRem(14, 500, 20);
        color: var(--black);
        &.ant-table-expanded-row {
          td {
            display: table-cell !important;
          }
        }
        td {
          border: none;
          padding: 8px;
          &:last-child {
            cursor: default;
          }
          div {
            margin: 0;
          }
          &::after {
            content: none;
          }
          &.ant-table-cell-fix-right {
            text-align: right;
          }
        }
        &.ant-table-placeholder {
          td {
            display: initial !important;
            .ant-table-expanded-row-fixed {
              background-color: transparent !important;
            }
          }
        }
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: var(--tableHoverColor) !important;
    }

    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        button {
          color: var(--blueGray);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGray);
        }
        .ant-select-selector {
          border: none;
          color: var(--blueGray);
          @include fontRem(14, 500);
        }
      }
    }
  }
  .RowTextType {
    @include fontRem(14, 500, 20);
    color: var(--black);
  }
  .HeaderTitleWithSort {
    @include flex-center-vert;
    gap: 10px;
  }
  .headingCellText {
    @include fontRem(14, 500);
    color: var(--blueGray);
    margin: 0;
  }
  .poligonWrapper {
    @include flex-center-column;
    gap: 1px;
    cursor: pointer;
  }
}
.filterTableContainer {
  background: var(--white);
  border: 1px solid var(--drawerBorder);
  border-radius: 12px;
  padding: 30px 0;
  padding-bottom: 80px;
  :global {
    .ant-divider {
      width: 100% !important;
    }
  }
  .receivablesTableViewItems {
    padding: 0 42px;
  }
  .filtersItems {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.totalInfo {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin-right: 5px;
}
.totalCount {
  @include fontRem(14, 600, 20);
  position: relative;
  color: var(--blue);
  &::after {
    content: none;
    position: absolute;
    top: 0;
    left: calc(100% + 9px);
    width: 1px;
    height: 100%;
    background-color: var(--lightGray);
    opacity: 0.2;
  }
}
.fullWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .filterTableContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .receivablesTableViewItems {
      &:last-child {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.receivableModalItem {
  font-weight: 500;
}

.copyIconText {
  @include flex-center-vert;
  display: inline-flex;
  gap: 12px;

  .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;

      &:hover {
          transform: scale(1.05);
      }
  }

  &.copyActive {
      >h2 {
          span {
              color: var(--activeLink) !important;
          }
      }
  }

  &:hover {
      .copyIcon {
          visibility: visible;
          opacity: 1;
      }
  }
}
