@import "/src/styles/mixins";
@import "/src/styles/pallete";
.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 530px;
    max-width: 530px;
    background-color: var(--white);
    border: 1px solid #f0f2f5;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1;
    max-height: 350px;
    overflow: auto;
    &.dropdownRight {
        left: 100%;
        right: initial;
    }
    &.opened {
        opacity: 1;
        visibility: visible;
    }
    .rateItem {
        padding: 15px;
        color: var(--black);
        &:not(:last-child) {
            border-bottom: 1px solid rgba(152, 162, 178, 0.2);
        }
        h2,
        a {
            color: var(--black) !important;
            @include textShorten(2);
            @include fontRem(14, 600, 20);
            cursor: pointer;
        }
        h2 {
            cursor: auto;
        }
        p {
            @include fontRem(14, 500, 20);
            white-space: nowrap;
        }
        svg {
            width: 14px;
            height: 14px;
        }
    }
}
.rate {
    font-size: unset;
    @include flex-center-vert;

    :global {
        .ant-rate-star {
            &:not(:last-child) {
                margin-inline-end: 2px;
            }
            &.ant-rate-star-full {
                svg {
                    path {
                        fill: #fbbc05;
                    }
                }
            }
        }
    }
}
.spinWrapper {
    min-height: 80px;
    @include flex-center;
}