@import "/src/styles/mixins";
@import "/src/styles/pallete";

.searchInput {
  width: 100%;
  height: 40px;
  :global {
    input {
      width: 100% !important;
      border: 1px solid #c1cada;
      border-radius: 6px !important;
      height: 100%;
      padding-right: 40px;
      background-color: var(--white) !important;
      color: var(--black) !important;
      &::placeholder {
        color: #98A2B2;
      }
      &:-webkit-autofill {
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: var(--black);
      }
    }
    .ant-input-wrapper {
      position: relative;
      height: 100%;
      .ant-input-group-addon {
        position: relative;
        z-index: 1;
        button {
          @include absoluteCenter(vertical);
          @include flex-center;
          right: 0;
          border-inline-start-color: unset;
          border-radius: 0;
          border: 0;
          box-shadow: none;
          background-color: transparent;

          svg {
            width: 21px;
            height: 21px;
            path {
              fill: var(--black);
            }
          }
        }
      }
    }
  }
  &.searchInputWithLabel {
    position: relative;
    :global {
      .ant-input-wrapper {
        .ant-input-group-addon {
          button {
            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
    &::after {
      content: "";
      height: 22px;
      width: 1px;
      background-color: #c1cada;
      @include absoluteCenter(vertical);
      right: 37px;
      z-index: 1;
    }
  }
}
