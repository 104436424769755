@import "/src/styles/mixins";
@import "/src/styles/pallete";

.heading {
  @include fontRem(20, 700, 20);
  color: var(--black);
}
.infoHeading {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey) !important;
  margin: 0 !important;
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0 !important;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}
