@import "/src/styles/mixins";
@import "/src/styles/pallete";

.employeeHeaderTabs {
    :global {
        .ant-tabs-nav {
            margin: 0 -20px 23px -20px;
            padding: 0 32px 0 35px;
            .ant-tabs-tab {
                padding: 13px 3px;
                &:not(:first-child) {
                    margin: 0 0 0 41px;
                }
                .ant-tabs-tab-btn {
                    @include fontRem(14, 500, 20);
                    color: var(--darkGrey);
                    transition: 0.1s;
                    &:hover {
                        color: var(--blue);
                    }
                }
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: var(--blue);
                    }
                }
            }
            .ant-tabs-ink-bar {
                background-color: var(--blue);
            }
        }
    }
}

.employeeContainer {
    padding: 11px 0 0;
    .employeeTopBlock {
        @include flex-center-vert;
        .employeeMainSelect {
            width: 300px;
            margin-bottom: 30px;
            label {
                color: var(--black);
                @include fontRem(14, 400, 20);
            }
        }
    }
}

.employeeEmpty {
    padding-top: 25px;
    color: var(--darkGrey);
    @include fontRem(16, 700, 20);
}
