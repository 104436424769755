@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tableAgingItem {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    .colorItem {
        width: 4px;
        height: 30px;
        border-radius: 2px;
    }
    p {
        @include fontRem(14, 500, 20);
        color: var(--black);
    }
}