@import "/src/styles/mixins";
@import "/src/styles/pallete";

.formWrapper {
  h2 {
    @include fontRem(20, 700, 20);
    color: var(--black) !important;
    margin-bottom: auto;
  }

  .switchSection {
    margin-top: 35px;
    .switchHeading {
      @include fontRem(16, 600, 20);
      color: var(--black);
      margin: 0;
    }
  }
  .uploadTitle {
    margin-bottom: 10px;
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
  .upload {
    margin-bottom: 28px;
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .whiteBlock {
    background: var(--white);
    border: 1px solid var(--longBorder);
    border-radius: 12px;
    padding: 31px 61px 61px 48px;
  }
  .rowsGap {
    padding-top: 40px;
  }
  .upload {
    background: rgba(56, 141, 255, 0.1);
    border: 1px dashed var(--blue);
    border-radius: 8px;
  }
  .uploadHeading {
    @include fontRem(14, 600, 20);
    color: var(--black);
    padding-top: 40px;
    margin-bottom: 10px;
    &::after {
      content: "*";
      @include fontRem(12, 500, 20);
      padding-left: 3px;
      vertical-align: super;
      color: var(--persimmonRed);
    }
  }
  .allowedFormat {
    @include fontRem(12, 400, 20);
    color: var(--lightGray);
    padding-top: 18px;
  }
  .uploadedList {
    @include flex-column;
    gap: 15px;
    p {
      @include fontRem(14, 500, 20);
    }
    .uploadedButtons {
      @include flex-center-vert;
      justify-content: flex-end;
      gap: 21px;
      .uploadControl {
        background-color: var(--white);
        box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
        border-radius: 3px;
        padding: 7px;
        svg {
          width: 16px;
          height: 16px;
        }
        &.eyeIcon {
          svg {
            width: 19px;
            height: 13px;
          }
        }
        &.downloadBtn {
          svg {
            path {
              fill: var(--blue);
            }
          }
        }
      }
    }
  }
}

.bracketsBlock {
    margin-top: 50px;
    .title {
      margin-bottom: 24px;
      @include fontRem(14, 600, 20);
      color: var(--black);
    }
    p::after {
      content: "*";
      @include fontRem(12, 500, 20);
      margin-left: 3px;
      display: inline-block;
      color: var(--persimmonRed);
    }
    .heading {
      margin-bottom: 15px;
    }
}