@import "/src/styles/mixins";
@import "/src/styles/pallete";

.headingBlock {
  display: flex;
  align-items: baseline;
}
.actionBlock {
  display: flex;
  gap: 15px;
  .trashIcon {
    width: 30px;
    height: 30px;
    @include flex-center-vert;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    background: var(--white);
    cursor: pointer;
  }
}
.emailBlock {
  margin-right: 3px;
  @include fontRem(14, 500, 17);
  color: var(--black);
}

.tableWrapper {
  :global {
    .ant-table {
      overflow: auto;
      table {
        padding-top: 12px;
      }
    }
    .ant-table-thead {
      tr {
        th {
          border: none;
          color: var(--darkGrey);
          background: transparent;
          padding: 0 6px;
          height: 33px;
          @include fontRem(14, 500, 20);
          &::before {
            display: none;
          }
          &:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        @include fontRem(14, 500, 20);
        color: var(--black);
        td {
          border: none;
          padding: 8px;
          div {
            margin: 0;
          }
          &:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: none !important;
    }
    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        button {
          color: var(--blueGray);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGray);
        }
        .ant-select-selector {
          border: none;
          color: var(--blueGray);
          @include fontRem(14, 500);
        }
      }
    }
  }
  .RowTextType {
    @include fontRem(14, 500, 20);
    color: var(--black);
  }
  .HeaderTitleWithSort {
    @include flex-center-vert;
    gap: 10px;
  }
  .headingCellText {
    @include fontRem(14, 500);
    color: var(--blueGray);
    margin: 0;
  }
  .poligonWrapper {
    @include flex-center-column;
    gap: 1px;
    cursor: pointer;
  }
}

.copyIconText {
  @include flex-center-vert;
  display: inline-flex;
  gap: 12px;
  .copyIcon {
    padding: 7px;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    cursor: pointer;
    background: var(--white);
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    &:hover {
      transform: scale(1.05);
    }
  }
  &.copyActive {
    > .infoParagraph {
      color: var(--activeLink) !important;
    }
  }
  &:hover {
    .copyIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}
.infoParagraph {
  @include fontRem(14, 600, 20);
  color: var(--black);
  margin: 0;
  & > a {
    text-decoration: underline;
    color: var(--blueRadio);
  }
}