.formAgreementWrapper {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  width: 100%;

  .checkboxWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 21px;
    .iagree {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    }
    .agreeError {
      padding-left: 18px;
    }
    
  }
}
.row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wrapper {
  padding: 15px 25px!important;
}
