@import "/src/styles/mixins";
@import "/src/styles/pallete";

.headingBlock {
    display: flex;
    align-items: center;
}

.infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey) !important;
    margin: 0 !important;
}

.copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
        padding: 7px;
        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
        border-radius: 3px;
        cursor: pointer;
        background: var(--white);
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
        &:hover {
            transform: scale(1.05);
        }
    }
    &.copyActive {
        > .infoParagraph {
            color: var(--activeLink) !important;
        }
    }
    &:hover {
        .copyIcon {
            visibility: visible;
            opacity: 1;
        }
    }
}
.infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black) !important;
    margin: 0 !important;
    & > a {
        text-decoration: underline !important;
        color: var(--blueRadio) !important;
    }
}
a.infoParagraph {
    color: var(--blue) !important;
}

.stateWrapperList {
    max-width: 400px;
    :global {
        .ant-col {
            &:not(:last-child) {
                span {
                    display: inline-block;
                    &::after {
                        content: ", ";
                    }
                }
            }
        }
    }
}

.infoParagraphReset {
    cursor: pointer;
    text-decoration: underline;
    transition: 0.3s;
    display: inline-block;
    &:hover {
        transform: scale(1.1);
    }
}
