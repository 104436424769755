@import "/src/styles/mixins";
@import "/src/styles/pallete";

.uploaderItem {
    background-color: var(--uploaderBg) !important;
    border-radius: 8px;
    transition: 0.3s;
    &:hover {
        background-color: rgba(56, 141, 255, 0.2) !important;
    }
    :global {
        .ant-upload-disabled {
            opacity: 0.5;
        }
        .ant-upload-list {
            display: none;
        }
        .ant-upload-drag {
            border: 0 !important;
        }
    }
    .uploadText {
        color: var(--black);
        * {
            @include fontRem(12, 400, 20);
            color: var(--black);
        }
        span {
            color: var(--blueWhite);
            text-decoration: underline;
        }
    }
}
