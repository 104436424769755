@import "/src/styles/mixins";
@import "/src/styles/pallete";

.modalDialog {
  max-width: 600px;
  width: 100% !important;
  :global {
    .ant-modal-content {
      padding: 24px 0 64px 44px;
      border: 1px solid var(--lightGray);
      background-color: var(--secondWhite);
      .ant-modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 28px;
        height: 28px;
        background: rgba(152, 162, 178, 0.1);
        border-radius: 3px;
        @include flex-center;
        svg {
          display: block;
          width: 16px;
          height: 16px;
        }
      }
      .ant-modal-header {
        background-color: transparent;
      }
      .ant-modal-title {
        margin-bottom: 0;
        @include fontRem(24, 600, 20);
        color: var(--black);
      }
      .ant-modal-body {
        button {
          width: auto;
          min-width: 168px;
          margin-right: 38px;
          
        }
        .ant-divider-horizontal {
          border-color: var(--lightBlue)
        }
      }
    }
  }
  .actionModalItem {
    @include flex-center-vert;
    flex-wrap: wrap;
    justify-content: space-between;
    .actionItemTextWrapper {
      flex: 1 0 auto;
      p {
        max-width: 300px;
        @include fontRem(14, 600, 20);
        color: var(--black);
      }
    }
  }
}
