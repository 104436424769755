@import "/src/styles/mixins";
@import "/src/styles/pallete";

.billingEmpty {
  padding-left: 30px;
  padding-top: 25px;
  color: var(--darkGrey);
  @include fontRem(16, 700, 20);
}

.filterBlock {
  width: 20%;
}
.reset {
  margin-top: 20px;
}
.billingMoneyRightBlock,
.billingMoneyBlock {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  .moneyInfo {
    align-items: baseline;
  }
  :global {
    .ant-divider-vertical {
      border-color:var(--darkGrey);
    }
  }
}
.billingMoneyRightBlock {
  justify-content: end;
  :global {
    .ant-divider-vertical {
      border-color:var(--darkGrey);
    }
  }
}
.totalInfo {
  @include fontRem(14, 600, 20);
  color: var(--black);
}
.totalCount {
  position: relative;
  color: var(--blue);
  &::after {
    content: none;
    position: absolute;
    top: 0;
    left: calc(100% + 9px);
    width: 1px;
    height: 100%;
    background-color: var(--lightGray);
    opacity: 0.2;
  }
  &:last-child {
    margin-left: 5px;
  }
}

.filtersWrapper {
  padding-bottom: 20px;
}
.tableHeaderTopInfo {
  min-height: 60px;
}