@import "/src/styles/mixins";
@import "/src/styles/pallete";

.switchMode {
    margin-left: 25px;
    background: var(--gray);
    border-radius: 32px;
    padding: 6px 7px;
    max-width: 190px;
    width: 100%;
    transition: 0.3s;
    input {
        display: none;
    }
    label {
        user-select: none;
        display: block;
        width: 100%;
        cursor: pointer;
        .themeItems {
            position: relative;
            padding: 9px 15px;
            @include flex-center-vert;
            border-radius: 24px;
            height: 38px;
            gap: 15px;

            &::after {
                content: "";
                position: absolute;
                width: 50%;
                @include absoluteCenter(vertical);
                left: 0;
                background-color: var(--white);
                box-shadow: 0 1px 3px rgba(96, 108, 128, 0.05);
                height: 100%;
                border-radius: 24px;
                transition: 0.3s;
            }
            .themeItem {
                position: relative;
                z-index: 1;
                flex: 1 0 50%;
                @include flex-center-vert;
                gap: 8px;
                span {
                    @include flex-center-vert;
                }
                p {
                    @include fontRem(12, 500, 16);
                    color: #9aa4b5;
                }
            }
        }
        input {
            &:checked {
                + .themeItems {
                    &::after {
                        left: 50%;
                    }
                }
            }
        }
        &:hover {
            .themeItems {
                &::after {
                    left: 0 !important;
                   width: 100%;
                }
            }
        }
    }
    &.switcherSize {
        margin-left: 7px;
        max-width: 86px;
        label {
            .themeItems {
                gap: 10px;
                padding: 6px 7px;
            }
        }
    }
}
.rotate{
    transform:rotate(360deg);
    transition: 0.3s;
}
