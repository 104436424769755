@import "/src/styles/mixins";
@import "/src/styles/pallete";

.datePickerInputWrapper {
    position: relative;

    .placeholder {
        pointer-events: none;
        transition: 0.3s;
        @include absoluteCenter(vertical);
        left: 17px;
        background-color: var(--secondWhite);
        padding: 2px 7px;
        @include fontRem(14, 500, 22);
        display: flex;
        color: #98a2b2;
        z-index: 1;
        &::after {
            content: none;
            color: #ff5454;
            @include fontRem(12, 500, 20);
            margin-top: -5px;
            margin-left: 3px;
            display: block;
        }
    }
    &.opened,
    &.filled {
        input {
            border-color: var(--black) !important;
        }
        .placeholder {
            top: 0;
            transform: translateY(calc(-50% - 4px));
        }
    }
    &.required {
        .placeholder {
            &::after {
                content: "*";
            }
        }
    }
    &.disabled {
        user-select: none;
        cursor: not-allowed;
        opacity: 0.5;
        *{
            cursor: not-allowed;
        }
    }
    input {
        padding-right: 37px;
        font-size: 14px;
        &:hover {
            border: 1px solid #379fff !important;
            box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
        }
    }

    &::after {
        content: "";
        height: 22px;
        width: 1px;
        background-color: #c1cada;
        @include absoluteCenter(vertical);
        right: 37px;
    }
    .datePickerControl {
        cursor: pointer;
        @include absoluteCenter(vertical);
        right: 12px;
        @include flex-center;
        svg {
            width: 16px;
            height: 16px;
        }

    }
}
