@import "/src/styles/pallete";
@import "/src/styles/mixins";

.tagsInputContainer {
    width: 100%;
    @include flex-center-vert;
    justify-content: flex-start;
    border: 1px solid var(--darkGrey);
    border-radius: 3px;
    position: relative;
    background-color: var(--secondWhite);
    padding-left: 17px;
    padding: 6px 40px 6px 17px;
    min-height: 40px;
    &:focus-within {
        border: 1.5px solid var(--darkGrey);
        .placeholder {
            top: 0;
            transform: translateY(calc(-50% - 4px));
        }
    }
    &.filled {
        border-color: var(--black);
    }
    .tagItemsWrapper {
        position: relative;
        display: flex;
        flex: auto;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 6px;
        .tag {
            @include flex-center-vert;
            padding: 5px 9px;
            background: rgba(102, 118, 149, 0.2);
            @include fontRem(14, 500, 17);
            height: 26px;
            gap: 15px;
            z-index: 2;
            button {
                padding: 0;
            }
        }
        .wrapperItem {
            flex: none;
            align-self: center;
            max-width: 100%;
            display: inline-flex;
        }
    }

    .inputWrapper {
        height: 100%;
        width: 100%;
        flex: 1 !important;
        position: relative;
        input {
            width: calc(100% - 1px);
            height: 100%;
            border: 0;
            @include fontRem(14, 500, 17);
            background-color: var(--secondWhite);
            color: var(--black);
            &:focus {
                + .placeholder {
                    top: 0;
                    transform: translateY(calc(-50% - 4px));
                }
            }
            &:-webkit-autofill {
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: var(--black);
              }
        }
        .inviteValidItem {
            cursor: pointer;
            width: max-content;
            position: absolute;
            top: calc(100% + 6px);
            padding: 12px 25px;
            right: 0;
            background: var(--lightPurple);
            border: 1px solid var(--blueGray);
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
            border-radius: 3px;
            @include flex-center;
            @include fontRem(12, 500, 15);
            color: #000;
            z-index: 9;
        }
    }
    .placeholder {
        pointer-events: none;
        transition: 0.3s;
        @include absoluteCenter(vertical);
        left: 17px;
        background-color: var(--secondWhite);
        padding: 2px 7px;
        @include fontRem(14, 500, 22);
        display: flex;
        color: var(--darkGrey);
        z-index: 1;
        pointer-events: none;

        &::after {
            content: none;
            color: var(--persimmonRed);
            @include fontRem(12, 500, 20);
            margin-top: -5px;
            margin-left: 3px;
            display: block;
        }
        &.filled {
            top: 0;
            transform: translateY(calc(-50% - 4px));
        }
        &.required {
            &::after {
                content: "*";
            }
        }
    }
}

.errorMessage {
    color: var(--persimmonRed);
}