@import "/src/styles/mixins";
@import "/src/styles/pallete";

.leftWrapper {
    background-color: var(--signinBlue);
    height: 100vh;
    padding: 83px 43px 0;
    overflow: hidden;
    @media only screen and (max-width: 1600px) {
        padding: 83px 20px 0;
    }
    .logo {
        width: 212px;
        height: 46px;
        * {
            object-fit: contain;
        }
    }
    .textInfo {
        margin-bottom: 71px;
        h1 {
            @include fontRem(46, 800);
            line-height: 1;
            margin-bottom: 35px;
            color: #fff;
            @media only screen and (max-width: 1600px) {
                @include fontRem(38, 800);
                line-height: normal;
                margin-bottom: 22px;
            }
        }
        p {
            @include fontRem(26, 500, 33);
            margin-bottom: 4px;
            color: #fff;
            @media only screen and (max-width: 1600px) {
                @include fontRem(22, 500);
                line-height: normal;
                margin-bottom: 0;
            }
        }
    }
}
