@import "src/styles/mixins";
@import "src/styles/pallete";

.columnsGap {
  padding-top: 14px;
}
[class*="tableRateItem"] {
  box-shadow: none;
  border: none;
}
.heading {
  @include fontRem(20, 700, 20);
  color: var(--black);
}
:global {
  .ant-divider {
    border-color: var(--gray);
  }
}
