@import "/src/styles/mixins";
@import "/src/styles/pallete";

.chargebackContainer {
    background: var(--white);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    border-radius: 3px;
    padding: 15px 21px 15px 13px;
    .subHeaderSummary {
        :global {
            .ant-typography-secondary {
                @include fontRem(14, 700, 20);
                color: var(--black);
                margin-bottom: 0;
            }
        }
        .percentage {
            &.negative {
                color: var(--persimmonRed);
            }
        }
    }
}

.differenceBlock {
    :global {
        .ant-typography-secondary {
            @include fontRem(14, 700, 20);
            color: var(--black);
            margin-bottom: 0;
        }
    }
    .difference {
        &.negative {
            color: var(--persimmonRed);
        }
    }
}
.paymentPlanSection {
    margin-top: 35px;
}