@import "/src/styles/mixins";
@import "/src/styles/pallete";

.inputMainWrapper {
    label {
        display: block;
        @include fontRem(14, 400, 20);
        margin-bottom: 4px;
        color: var(--black);
    }
    .warningText {
        color: var(--warning);
    }
}

.placeholder {
    pointer-events: none;
    transition: 0.3s;
    @include absoluteCenter(vertical);
    left: 17px;
    background-color: var(--secondWhite);
    padding: 2px 7px;
    @include fontRem(14, 500, 22);
    display: flex;
    color: #98a2b2;
    z-index: 1;
    &::after {
        content: none;
        color: var(--persimmonRed);
        @include fontRem(12, 500, 20);
        margin-top: -5px;
        margin-left: 3px;
        display: block;
    }
    &.errorLabel {
        color: var(--persimmonRed);
    }
}
.required {
    .placeholder {
        &::after {
            content: "*";
        }
    }
}
.disabled {
    opacity: 0.7;
    background-color: var(--gray);
    border-color: transparent !important;
    border-radius: 6px;
    input {
        background-color: var(--gray);
        border-color: transparent !important;
        &:-webkit-autofill {
            -webkit-text-fill-color: var(--black);
        }
        &:hover {
            box-shadow: none !important;
        }
    }
    .placeholder {
        background-color: var(--gray);
    }
    :global {
        .ant-input-suffix {
            cursor: not-allowed !important;
        }
    }
}
.filled {
    input {
        border: 1px solid var(--black) !important;
        &:-webkit-autofill {
            -webkit-text-fill-color: var(--black);
        }
    }
    .placeholder {
        z-index: 1;
        top: 0;
        transform: translateY(calc(-50% - 3px));
    }
}
.withMinus {
    input {
        color: var(--persimmonRed) !important;
    }
}
.input {
    width: 100%;
    position: relative;
    height: 40px;
    input {
        appearance: none;
        height: 100%;
        width: 100%;
        border: 1px solid #98a2b2;
        border-radius: 6px;
        padding: 12px 19px;
        color: var(--black);
        transition: 0.3s;
        -webkit-text-fill-color: var(--black);
        background-color: var(--secondWhite);
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        -moz-appearance: textfield;
        &:hover {
            border: 1px solid #379fff;
            box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
        }
        &:focus {
            border: 1px solid #379fff !important;
            + .placeholder {
                top: 0;
                transform: translateY(calc(-50% - 4px));
            }
        }
        &:required {
            + .placeholder {
                &::after {
                    content: "*";
                }
            }
        }
        &:disabled {
            background-color: var(--gray);
            border-color: transparent !important;
            color: var(--black);
            + .placeholder {
                background-color: var(--gray);
            }
        }
        &::placeholder {
            color: var(--lightGray);
        }
    }
    .noValue {
        @include flex-center-vert;
        pointer-events: none;
    }
}
.password {
    position: relative;
    height: 40px;
    input {
        background-color: var(--secondWhite) !important;
        &:required {
            ~ .placeholder {
                &::after {
                    content: "*";
                }
            }
        }
        &:disabled {
            background-color: var(--gray);
            border-color: transparent !important;
            color: var(--black);
            ~ .placeholder {
                background-color: var(--gray);
            }
        }
        &:-webkit-autofill {
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--black);
        }
    }
    :global {
        .ant-input-password {
            padding: 0;
            height: 100%;
            border: 0;
            box-shadow: none;
            background-color: transparent;

            input {
                height: 100%;
                width: 100%;
                border: 1px solid #98a2b2;
                border-radius: 6px;
                padding: 12px 24px;
                color: var(--black);
                transition: 0.3s;
                &::after {
                    content: none;
                }
                &:hover {
                    border: 1px solid #379fff;
                    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
                }
                &:focus {
                    border: 1px solid #379fff !important;
                    + .placeholder {
                        top: 0;
                        transform: translateY(calc(-50% - 4px));
                    }
                }

                &:disabled {
                    background-color: var(--gray);
                    border-color: transparent !important;
                    input {
                        color: var(--black);
                        &:-webkit-autofill {
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: var(--black);
                        }
                    }
                    + .placeholder {
                        background-color: var(--gray);
                    }
                }
                &:-webkit-autofill {
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: var(--black);
                }
            }
        }

        .ant-input-affix-wrapper-focused {
            ~ p {
                z-index: 1;
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }
        .ant-input-suffix {
            z-index: 3;
            cursor: pointer;
            @include absoluteCenter(vertical);
            right: 17px;
            height: 100%;
            width: 33px;
            span {
                @include flex-center-vert;
                height: 100%;
                padding: 0 17px;
                svg {
                    width: 21px;
                    height: 21px;
                }
            }
        }
    }
}
.suffix {
    :global {
        .ant-input-affix-wrapper {
            border: none;
            padding: 0;
            height: 100%;
            background-color: var(--secondWhite);
            input {
                height: 100%;
                width: 100%;
                border: 1px solid #98a2b2;
                border-radius: 6px;
                padding: 12px 50px 12px 24px;
                color: var(--black);
                transition: 0.3s;
                -webkit-text-fill-color: initial;

                &::after {
                    content: none;
                }
                &:hover {
                    border: 1px solid #379fff;
                    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
                }
                &:focus {
                    border: 1px solid #379fff !important;
                    + .placeholder {
                        top: 0;
                        transform: translateY(calc(-50% - 4px));
                    }
                }

                &:disabled {
                    background-color: var(--gray);
                    border-color: transparent !important;
                    input {
                        color: var(--black);
                        &:-webkit-autofill {
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: var(--black);
                        }
                    }
                    + .placeholder {
                        background-color: var(--gray);
                    }
                }
                &:-webkit-autofill {
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: var(--black);
                }
            }
            .ant-input-suffix {
                @include absoluteCenter(vertical);
                right: 0;
                height: 100%;
                z-index: 3;
                cursor: pointer;
                width: 33px;
            }
            &::after {
                content: "";
                height: 22px;
                width: 1px;
                background-color: #c1cada;
                @include absoluteCenter(vertical);
                right: 37px;
            }
        }
        .ant-input-affix-wrapper-focused {
            ~ p {
                z-index: 1;
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }
        .ant-input-suffix {
            @include absoluteCenter(vertical);
            right: 0;
            height: 100%;
            @include flex-center-vert;
            padding: 0 10px;
            z-index: 3;
            cursor: pointer;
            width: 33px;
            svg {
                width: 16px;
                height: 16px;
            }
            .ant-input-clear-icon {
                @include absoluteCenter(vertical);
                right: 0;
                height: 100%;
                @include flex-center;
                padding: 0 10px;
                visibility: visible !important;
                &.ant-input-clear-icon-hidden {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
}
.prefix {
    .placeholder {
        left: 33px;
    }
    :global {
        .ant-input-affix-wrapper {
            border: none;
            padding: 0;
            height: 100%;
            input {
                height: 100%;
                width: 100%;
                border: 1px solid #98a2b2;
                border-radius: 6px;
                padding: 12px 24px 12px 40px;
                color: var(--black);
                transition: 0.3s;
                &::after {
                    content: none;
                }
                &:hover {
                    border: 1px solid #379fff;
                    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
                }
                &:focus {
                    border: 1px solid #379fff !important;
                    + .placeholder {
                        top: 0;
                        transform: translateY(calc(-50% - 4px));
                    }
                }

                &:disabled {
                    background-color: var(--gray);
                    border-color: transparent !important;
                    input {
                        color: var(--black);
                        &:-webkit-autofill {
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: var(--black);
                        }
                    }
                    + .placeholder {
                        background-color: var(--gray);
                    }
                }
                &:-webkit-autofill {
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: var(--black);
                }
            }
            .ant-input-prefix {
                @include absoluteCenter(vertical);
                right: 0;
                height: 100%;
                z-index: 3;
                cursor: pointer;
                width: 33px;
                margin-inline-end: 0;
            }
            &::after {
                content: "";
                height: 22px;
                width: 1px;
                background-color: #c1cada;
                @include absoluteCenter(vertical);
                left: 32px;
            }
        }
        .ant-input-affix-wrapper-focused {
            ~ p {
                z-index: 1;
                top: 0;
                transform: translateY(calc(-50% - 3px));
            }
        }
        .ant-input-prefix {
            @include absoluteCenter(vertical);
            left: 0;
            height: 100%;
            @include flex-center-vert;
            padding: 0 10px;
            z-index: 3;
            cursor: pointer;
            width: 33px;
            svg {
                width: 16px;
                height: 16px;
            }
            .ant-input-clear-icon {
                @include absoluteCenter(vertical);
                right: 0;
                height: 100%;
                @include flex-center;
                padding: 0 10px;
                visibility: visible !important;
                &.ant-input-clear-icon-hidden {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
}
.errorsNested,
.errors {
    input {
        border-color: var(--persimmonRed) !important;
        &:focus {
            border-color: var(--persimmonRed) !important;
            box-shadow: 0 0 0 2px rgba(#ff5454, 0.1) !important;
        }
    }
    :global {
        .ant-input-affix-wrapper {
            &.ant-input-affix-wrapper-focused {
                box-shadow: none !important;
                input {
                    border-color: var(--persimmonRed) !important;
                    box-shadow: 0 0 0 2px rgba(#ff5454, 0.1) !important;
                }
            }
            input {
                border-color: var(--persimmonRed) !important;
            }
        }
    }
}
.errorsNested {
    input {
        color: var(--persimmonRed) !important;
    }
}
.warning {
    input {
        border-color: var(--warning) !important;
        color: var(--warning) !important;
    }
}
.success {
    input {
        border-color: var(--primaryBtn) !important;
    }
}
.inTableInput {
    input {
        border-radius: 0 !important;
        padding-left: 9px !important;
        padding-right: 30px !important;
        &:-webkit-autofill {
            background-clip: initial !important;
            -webkit-background-clip: initial !important;
            -webkit-text-fill-color: initial !important;
        }
        &::placeholder {
            color: #98a2b2 !important;
        }
    }
    .placeholder {
        left: 9px !important;
    }
    :global {
        .ant-input-affix-wrapper {
            &::after {
                content: none !important;
            }
        }
    }
}
