@import "/src/styles/mixins";
@import "/src/styles/pallete";

.tableWrapper {
  :global {
    .ant-table-wrapper {
      .ant-table-container {
        &::after,
        &::before {
          content: none !important;
        }
      }
    }
    .ant-table-body {
      min-height: 100px;
    }
    .ant-checkbox-input {
      display: none;
    }
    .ant-checkbox {
      top: 0;
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            background-color: #a1b0cc;
          }
          &::before {
            content: "";
          }
        }
      }
    }
    .ant-checkbox-wrapper {
      @include flex-center-vert;
    }
    .ant-checkbox-inner {
      background-color: var(--white);
      border-radius: 3px;
      width: 24px;
      height: 24px;
      &::before {
        @include absoluteCenter(both);
        background-color: var(--white);
        width: 8px;
        height: 1px;
        z-index: 1;
      }
      &::after {
        background-color: #379fff;
        @include absoluteCenter(both);
        border: 0;
        width: 14px;
        height: 14px;
      }
    }
  }

  :global {
    .ant-checkbox-wrapper-checked {
      &:hover {
        .ant-checkbox-inner {
          background-color: var(--white) !important;
          border-color: #379fff !important;
        }
      }
    }
    .ant-checkbox-checked {
      &::after {
        content: none;
      }
      .ant-checkbox-inner {
        border-color: #379fff;
        background-color: var(--white);
      }
    }
  }

  :global {
    .ant-table {
      overflow: auto;
      background-color: transparent;
      table {
        padding-top: 12px;
      }
    }
    .ant-table-thead {
      tr {
        td {
          background-color: var(--white) !important;
          border-color: var(--white) !important;
        }
        th {
          color: var(--blueGray);
          background: transparent;
          padding: 0 8px;
          @include fontRem(14, 500, 20);
          height: 33px;
          border: 0;
          &::before {
            display: none;
          }
          &.ant-table-cell-fix-left,
          &.ant-table-cell-fix-right {
            background: var(--white);
          }
          &.ant-table-selection-column {
            text-align: initial;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        &.enteredRow {
          background: rgba(193, 202, 218, 0.7);
          > td {
            position: relative;
            &:not(:last-child) {
              &::after {
                content: "";
                @include absoluteCenter(vertical);
                left: 5px;
                height: 1px;
                background-color: var(--black);
                width: 100%;
                z-index: 2;
              }
              opacity: 0.7;
            }
            &:nth-last-child(2) {
              pointer-events: none;
              &::after {
                width: 90%;
              }
            }
          }
        }
        &.editingRow {
          td {
            background-color: rgba(55, 159, 255, 0.2) !important;
          }
        }
        &.activeRow {
          td {
            background-color: rgba(55, 159, 255, 0.2) !important;
          }
        }
        &.activeRowTruck {
          td {
            background-color: rgba(55, 159, 255, 0.2) !important;
          }
        }
        &.activeRowFuel {
          td {
            background-color: rgba(55, 159, 255, 0.2) !important;
          }
        }
        td {
          background-color: var(--white) !important;
          padding: 8px;
          color: var(--black);
          border: 0;
          @include fontRem(14, 500, 20);
          cursor: pointer;
          div {
            margin: 0;
          }
        }
        &:hover {
          td {
            background-color: var(--tableHoverColor) !important;
          }
        }
      }
    }
    .ant-pagination {
      color: var(--blueGray);
      @include fontRem(14, 500);
      li {
        @include flex-center-vert;
        height: 30px;
        width: 30px !important;
        justify-content: center;
        button {
          color: var(--blueGrayWhite);
          span {
            @include fontRem(14, 900);
          }
        }
        a {
          color: var(--blueGrayWhite);
          padding: 0;
        }

        &.ant-pagination-total-text {
          order: 3;
          width: auto !important;
          color: var(--blueGrayWhite);
        }
        &.ant-pagination-item {
          width: auto;
          min-width: unset;
          margin-inline-end: 6px;
        }
        &.ant-pagination-item-active {
          background-color: transparent;
          border-color: var(--paginationActive);
          border-width: 2px;
          &:hover {
            color: var(--black);
          }
        }
        &.ant-pagination-prev,
        &.ant-pagination-next {
          background-color: var(--gray);

          a {
            @include flex-center-vert;
            justify-content: center;
            width: 100%;
            height: 100%;
            svg {
              width: 14px;
              height: 14px;
            }
          }
          &.ant-pagination-next {
            a {
              transform: rotate(180deg);
            }
          }
        }

        &.ant-pagination-options {
          width: auto;
          margin-inline: 20px;
          .ant-select-selector {
            border: none;
            color: var(--black);
            @include fontRem(14, 500);
            background-color: transparent;
            .ant-select-selection-item {
              color: var(--black);
              padding-inline-end: 14px;
            }
          }
          .ant-select-suffix {
            width: 9px;
            height: 5px;
            background-size: contain;
            background: no-repeat;
            mask: url("data:image/svg+xml;charset=utf8,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.43416 0L4.49414 3.0904L7.55412 0L8.49414 0.951402L4.49414 5L0.494141 0.951402L1.43416 0Z' fill='currentColor'/%3E%3C/svg%3E");
            background-color: var(--black);
            svg {
              display: none;
            }
          }
        }
        &.ant-pagination-jump-next,
        &.ant-pagination-jump-prev {
          align-items: flex-end;
          span {
            color: var(--black) !important;
          }
          .ant-pagination-item-ellipsis {
            color: var(--black);
          }
        }
      }
      .ant-select-dropdown {
        background-color: var(--white);
        .ant-select-item-option {
          @include flex-center;
          justify-content: center;
          * {
            color: var(--black);
            text-align: center;
          }
          &.ant-select-item-option-active {
            background-color: var(--gray);
          }
          &.ant-select-item-option-selected {
            background-color: var(--gray);
          }
        }
      }
    }
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.actionBlock {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  .trashIcon {
    width: 30px;
    height: 30px;
    @include flex-center-vert;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    background: var(--white);
    cursor: pointer;
  }
  .disabledTrashIcon {
    box-shadow: none;
    opacity: 0.6;
  }
}

.phoneLink {
  @include fontRem(14, 600, 20);
  color: var(--blue);
  margin: 0;
  text-decoration: underline;
}
.tdContent {
  @include flex-center-vert;
  gap: 8px;
  cursor: pointer;
  p {
    display: flex;
  }
  .infoIconText {
    @include flex-center-vert;
    gap: 8px;
  }
}
.downloadWithDate {
  justify-content: space-between;
}
.deletedBlock {
  @include flex-column;
  padding: 10px;
  width: 100%;
  .secondRow,
  .firstRow {
    display: flex;
    gap: 20px;
    span:first-child {
      @include fontRem(14, 500, 20);
      color: var(--darkGrey);
    }
    span {
      color: var(--black);
    }
  }
}

.labelBlock {
  width: 80px;
  height: 30px;
  padding: 5px 10px;
  text-align: center;
  background: rgba(240, 242, 245, 1);
  border-radius: 3px;
  span {
    color: var(--black);
    @include fontRem(12, 600, 20);
  }
  &.red {
    background: rgba(255, 84, 84, 0.2);
    span {
      color: var(--persimmonRed);
    }
  }
  &.blue {
    background: rgba(55, 159, 255, 0.2);
    span {
      color: var(--signinBlue);
    }
  }
}

.deletedRow {
  position: relative;
  z-index: 1;
  @include flex-center-vert;
  gap: 7px;
}

.commentBlock {
  @include flex-column;
  padding: 10px 15px 15px 15px;
  span:first-child {
    @include fontRem(14, 700, 22);
    color: var(--black);
  }
  span {
    @include fontRem(12, 400, 20);
    color: var(--lightGray);
  }
  p {
    @include fontRem(14, 500, 22);
    color: var(--black);
  }
}

.commentText {
  cursor: pointer;
  text-decoration: underline;
}

.emailBlock {
  margin-right: 3px;
  @include fontRem(14, 500, 17);
  color: var(--black);
}

.RowTextType {
  @include fontRem(14, 500, 20);
  color: var(--black);
}

.HeaderTitleWithSort {
  @include flex-center-vert;
  gap: 10px;
}

.headingCellText {
  @include fontRem(14, 500);
  color: var(--blueGray);
  margin: 0;
}

.poligonWrapper {
  @include flex-center-column;
  gap: 1px;
  cursor: pointer;
}

.status {
  @include fontRem(14, 500, 20);
  color: var(--black);
}
.deptAmount {
  @include fontRem(14, 500, 20);
  color: var(--persimmonRed);
}

:global .ant-tooltip .ant-tooltip-inner {
  color: var(--black);
}

.tooltipMain {
  min-width: 320px;
  :global {
    .ant-tooltip-inner {
      padding: 30px 22px;
    }
  }
}

.tooltipTitle {
  @include fontRem(14, 500, 20);
  color: var(--darkGrey);
}
.tooltipText {
  display: block;
  @include fontRem(14, 600, 20);
  color: var(--black);
  &.tooltipTextRed {
    color: var(--persimmonRed);
  }
}

.popoverOverlay {
  :global {
    .ant-popover-inner {
      padding: 27px 15px 30px 29px;
      background-color: var(--white);
      border-radius: 6px;
      max-height: 400px;
      height: 350px;
      overflow: auto;
    }
    .ant-popover-arrow {
      &::before {
        background-color: var(--white);
      }
    }
  }
  .dividerinPopover {
    margin-left: -29px;
    width: calc(100% + 44px);
  }
  .popoverTitle {
    text-decoration: underline;
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: var(--blue) !important;
      cursor: pointer;
    }
  }
  .popoverInnerTitle {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    text-align: left;
  }
  .popoverValue {
    @include fontRem(14, 500, 20);
    color: var(--black);
    &.debt {
      color: var(--persimmonRed);
    }
  }
}
.datagridDownolad {
  padding: 7px;
  width: 30px;
  height: 30px;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 4px 10px rgba(102, 118, 149, 0.25);
}
.authoritesDataGrid {
  :global {
    .ant-table-cell {
      border-bottom: 1px solid var(--longBorder) !important;
    }
  }
}
.authoritesViewDataGrid {
  width: calc(100% + 50px);
  :global {
    .ant-table {
      table {
        padding-top: 0;
        tr {
          &:hover {
            .ant-table-cell {
              background-color: transparent !important;
              cursor: default;
            }
          }
          .ant-table-cell {
            border-bottom: 1px solid var(--longBorder) !important;
          }
        }
        tbody {
          tr {
            &:last-child {
              .ant-table-cell {
                border-bottom: 1px solid transparent !important;
              }
            }
          }
        }
      }
    }
  }
}
.truckFuelcardDataGrid {
  :global {
    .ant-table-cell {
      border-bottom: 1px solid var(--longBorder) !important;
    }
    .ant-pagination {
      padding-right: 50px;
    }
  }
}
.truckFuelcardDataGrid {
  margin-top: 30px;
  width: calc(100% + 50px);
  :global {
    .ant-table {
      table {
        padding-top: 0;
        tr {
          &:hover {
            .ant-table-cell {
              background-color: transparent !important;
              cursor: default;
            }
          }
          .ant-table-cell {
            border-bottom: 1px solid var(--longBorder) !important;
          }
        }
        thead {
          tr {
            .ant-table-cell {
              &:last-child {
                padding-right: 50px;
              }
            }
          }
        }
        tbody {
          tr {
            .ant-table-cell {
              &:last-child {
                padding-right: 50px;
              }
            }
          }
        }
      }
    }
  }
}
.claimed {
  opacity: 0.3;
  &.claimedHasAccess {
    opacity: 1;
    td {
      &:not(:last-child) {
        opacity: 0.3;
      }
    }
  }
}

.payableReceivableDataGrid {
  height: 100%;
  :global {
    .ant-checkbox-wrapper-disabled {
      .ant-checkbox-inner {
        background-color: var(--gray);
      }
    }
    .ant-table-wrapper {
      height: 100%;
      .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
          height: 100%;
          .ant-table {
            height: 100%;
            .ant-table-container {
              height: 100%;
              .ant-table-content {
                height: 100%;
                thead {
                  tr {
                    th {
                      padding: 0 8px;
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      padding: 0;
                      .ant-checkbox-wrapper {
                        padding: 0 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .ant-table-empty {
        table {
          height: 100%;
          .ant-table-placeholder {
            .ant-table-cell {
              height: 100%;
              display: flex !important;
              align-items: center;
              position: sticky;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  .customTableCell {
    padding: 8px;
  }
}
.pickupDropoffsDataGrid {
  :global {
    .ant-table {
      table {
        thead {
          .ant-table-cell-scrollbar {
            opacity: 0;
          }
        }
        tbody {
          tr {
            &.invalidRows {
              position: relative;
              td {
                &:first-child {
                  position: relative;
                  &::after {
                    content: "";
                    @include absoluteCenter(vertical);
                    left: 0;
                    width: 3px;
                    height: 80%;
                    background-color: red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.spinWrapper {
  height: 350px;
  @include flex-center;
}
