@import "/src/styles/mixins";
@import "/src/styles/pallete";

.officeContainer {
    .officeTopBlock {
        margin-bottom: 20px;
        .officeMainSelect {
            .officeRestBtn {
                margin-top: 25px;
            }
        }
    }
}

.officeEmpty {
    padding-top: 25px;
    color: var(--darkGrey);
    @include fontRem(16, 700, 20);
}
.trashIcon {
    width: 30px;
    height: 30px;
    @include flex-center-vert;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
    border-radius: 3px;
    background: var(--white);
    cursor: pointer;
}
.RowTextType {
    @include fontRem(14, 500, 20);
    color: var(--black);
}
.HeaderTitleWithSort {
    @include flex-center-vert;
    gap: 10px;
}
.headingCellText {
    @include fontRem(14, 500);
    color: var(--blueGray);
    margin: 0;
}
.poligonWrapper {
    @include flex-center-column;
    gap: 1px;
    cursor: pointer;
}
