@import "src/styles/mixins";
@import "src/styles/pallete";

.slider {
    overflow: hidden;
    .slideTrack {
        @include flex-column;
        &.ready {
            animation: scroll 25s linear infinite;
        }
        @keyframes scroll {
            0% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(calc(var(--scrollArea)));
            }
        }
    }
    .slide {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
        img {
            width: 232px;
            height: auto;
            object-fit: cover;
            border-radius: 6px;
            border-radius: 6px;
            max-width: calc(50% - 15px);
            display: block;
            flex: 1 0 calc(-50% - 15px);
            &:nth-child(odd) {
                transform: translateY(-70px);
            }
        }
    }
}
