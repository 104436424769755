@import "/src/styles/mixins";
@import "/src/styles/pallete";

.customizeColumnsContainer {
    width: 100%;
    .customizeHeader {
        @include flex-column;
        justify-content: flex-start;
        gap: 17px;
        h1 {
            @include fontRem(24, 700, 20);
            color: var(---black);
        }
        p {
            @include fontRem(16, 400, 20);
            color: var(---black);
        }
    }
    .customizeTopBlock {
        display: flex;
        justify-content: space-between;
        margin-bottom: 45px;
        .customizeTopBlockActions {
            display: flex;
            gap: 12px;
        }
    }
    .columnsContainer {
        display: grid;
        grid-template-columns: repeat(3, 340px);
        grid-template-rows: repeat(var(--columnsCount, 10), auto);
        grid-auto-flow: column;
        gap: 12px;
        height: 100%;
        .columnItem {
            width: 340px;
            background: var(--white);
            height: 60px;
            border: 1px solid var(--lightBlue);
            border-radius: 6px;
            padding: 12px 20px 8px 22px;
            @include flex-center-vert;
            justify-content: space-between;
            cursor: pointer;
            .columnTextWrapper {
                @include flex-center-vert;
                .draggableIcon {
                    margin-right: 20px;
                }
                .columnTextBlock {
                    p {
                        @include fontRem(14, 500, 20);
                        color: var(---black);
                    }
                    span {
                        @include fontRem(12, 500, 20);
                        color: var(--blueGray);
                    }
                }
            }
            .columnEyeDisabled,
            .columnEye {
                @include flex-center-vert;
                justify-content: center;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                background: var(--white);
                box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
                transition: 0.3s;
                &:hover {
                    box-shadow: 0px 8px 20px rgba(102, 118, 149, 0.4);
                    transform: scale(1.05);
                }
            }
            .columnEyeDisabled {
                background: var(--gray);
            }
            &.hidden {
                background-color: transparent;
                border-color: transparent;
            }
            &:hover {
                transform: scale(1.05);
            }
            &:active {
                transform: scale(1.07) rotate(1deg);
            }
        }
    }
}
.columnItem {
    transition: transform 0.1s linear;
}

.columnItem.dragging {
    transform: scale(1.05);
}

.columnItem.draggingUndo {
    transform: translateX(57.5%);
}

.columnItem.draggingUndoPrev {
    transform: translateX(-46%);
}

.columnItem.draggingUndoToFrom {
    transform: translateX(-46%);
}

.columnItem.draggingUndoPrevFromTo {
    transform: translateX(57.5%);
}

.columnItem.draggingUndoVertical {
    transform: translateX(57.5%) translatey(57.5%);
}

.columnItem.draggingUndoPrevVertical {
    transform: translateX(-46%) translatey(-46%);
}

.columnItem.draggingUndoVerticalTo {
    transform: translateX(-46%) translatey(-46%);
}

.columnItem.draggingUndoPrevVerticalFrom {
    transform: translateX(57.5%) translatey(57.5%);
}
