@import "/src/styles/mixins";
@import "/src/styles/pallete";

.viewMain {
  @include flex-column;
  .topbannerPairs {
    @include flex-center-vert;
    padding-right: 8px;
  }
  .branches {
    background: var(--white);
    border: 1px solid var(--longBorder);
    border-radius: 12px;
    padding: 31px 61px 61px 48px;
    :global {
      .ant-typography-secondary {
        @include fontRem(20, 700, 20);
        color: var(--black);
      }
    }
    .addedBlock {
      @include fontRem(14, 600, 20);
      color: var(--black);
      margin-top: 40px;
      margin-bottom: 25px;
    }
    .disabledTrashIcon {
      opacity: 0.5;
    }
    .rowsGap {
      padding-top: 40px;
    }
    .rows {
      padding-top: 10px;
    }

    .allowedFormat {
      & > h2 {
        @include fontRem(12, 400, 20);
        color: var(--darkGrey);
        padding-top: 18px;
      }
    }
    .bracked {
      &:last-child {
        :global {
          .ant-divider {
            display: none;
          }
        }
      }
    }
    .brackedButtonWrapper {
      button {
        height: 40px;
      }
    }
    .uploadText * {
      @include fontRem(12, 400, 20);
      color: var(--black);
    }
    .branchesAddAuthorities,
    .payoutBlock {
      margin-top: 50px;
      p {
        margin-bottom: 64px;
        @include fontRem(14, 600, 20);
        color: var(--black);
      }
      p::after {
        content: "*";
        @include fontRem(12, 500, 20);
        margin-left: 3px;
        display: inline-block;
        color: var(--persimmonRed);
      }
    }
    .branchesAddAuthorities {
      margin-top: 30px;
      margin-bottom: 30px;
      p {
        margin-bottom: 0;
        @include fontRem(18, 700, 20);
      }
    }
    .heading {
      margin-bottom: 30px;
    }
  }
  .textWithLinks {
    margin-left: 4px;
    display: flex;
    gap: 4px;
    a {
      text-decoration: underline;
    }
  }

  .phoneLinkInArray {
    @include fontRem(14, 600, 20);
    color: var(--blue);
    margin-right: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
  .phoneNameInArray {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
  .fieldName {
    color: var(--black);
    @include fontRem(14, 600, 20);
  }

  .heading {
    @include fontRem(20, 700, 20);
    color: var(--black);
  }
  .infoHeading {
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
    margin: 0;
  }
  .infoParagraphBracket,
  .infoParagraph {
    @include fontRem(14, 600, 20);
    color: var(--black);
    margin: 0;
    & > a {
      text-decoration: underline;
      color: var(--blueRadio);
    }
  }
  a.infoParagraph {
    color: var(--blue);
  }
  .infoParagraphBracket::after {
    content: "|";
  }
  .infoParagraphBracket:last-child::after {
    display: none;
  }
  .textWithLinks {
    display: flex;
    width: 100%;
    gap: 4px;
    align-items: center;
    @include fontRem(14, 600, 20);
    a {
      
      text-decoration: underline;
    }
    p {
      margin: 0;
    }
  }
  .editBtn {
    text-align: right;
  }
  .agentInfo,
  .payroll {
    margin-top: 43px;
  }
  .copyIconText {
    @include flex-center-vert;
    display: inline-flex;
    gap: 12px;
    .copyIcon {
      padding: 7px;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      cursor: pointer;
      background: var(--white);
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.copyActive {
      > .infoParagraph {
        color: var(--activeLink);
      }
    }
    &:hover {
      .copyIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .viewHeaderItem {
    margin-bottom: 11px;
  }
}
.popoverOverlay {
  .phoneLinkInArray {
    @include fontRem(14, 600, 20);
    color: var(--blue);
    margin-right: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
  .phoneNameInArray {
    @include fontRem(14, 600, 20);
    color: var(--black);
  }
}
