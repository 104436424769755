@import "/src/styles/mixins";
@import "/src/styles/pallete";

.favoriteWrapper {
  width: 32px;
  height: 32px;
  .favorite {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
    @include flex-center;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
    &.selectedFavorite {
      svg {
        path {
          stroke: transparent;
          fill: #388dff;
        }
      }
    }
    svg {
      path {
        fill: none;
        stroke: #667695;
        stroke-width: 1px;
      }
    }
  }
}
