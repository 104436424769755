@import "/src/styles/mixins";
@import "/src/styles/pallete";

.uploadedList {
  padding-top: 12px;
  width: 100%;
  @include flex-center-vert;
  .addedByName {
    margin-top: 2px;
  }
  .uploadedButtons {
    @include flex-center-vert;
    justify-content: flex-end;
    gap: 20px;
    button {
      width: 30px;
      height: 30px;
      background-color: var(--white);
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.25);
      border-radius: 3px;
      @include flex-center;
      border: 0;
      outline: none;
      transition: 0.1s;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed !important;
        svg {
          cursor: not-allowed;
        }
        &:hover {
          transform: scale(1);
        }
      }
      &:hover {
        transform: scale(1.1);
      }
      &:nth-child(2) {
        svg {
          path {
            fill: var(--blue);
          }
        }
      }
    }
  }
  p {
    @include fontRem(14, 500, 17);
    color: var(--black);
  }
  .dateBlock {
    @include flex-center;
    gap: 10px;
  }
}

.infoBlock {
  padding: 10px;
  .rightColumn,
  .leftColumn {
    @include flex-column;
    @include fontRem(14, 500, 20);
    color: var(--darkGrey);
  }
  .rightColumn {
    color: var(--black);
  }
}
