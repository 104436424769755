@import "/src/styles/mixins";


.permission {
  background: var(--white);
  border: 1px solid var(--lightBlue);
  border-radius: 12px;
  margin-top: 20px;
  padding: 31px 61px 61px 48px;
}

.subHeading {
  @include fontRem(18, 700, 20);
  margin-bottom: 20px;
}