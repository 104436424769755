@import "/src/styles/mixins";
@import "/src/styles/pallete";

.headerSelect {
    min-width: 155px;
    height: 40px;
    pointer-events: all;
    [class*="placeholder"] {
        display: none !important;
    }
    [class*="selectWrapper"] {
        &::after {
            content: none !important;
        }
    }
    :global {
        .ant-select {
            width: 100%;
            height: 100%;
            &.ant-select-open {
                .ant-select-selector {
                    border-radius: 6px 0 0 0;
                }
            }
            .ant-select-selector {
                height: 100% !important;
                border-color: var(--headerInputColor) !important;
                border-radius: 6px 0px 0px 6px;
                background-color: var(--white);
                @include flex-center-vert;
                border-right: 0;
                input {
                    height: 100% !important;
                    &:-webkit-autofill {
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: var(--black);
                      }
                }
                .ant-select-selection-item {
                    @include fontRem(16, 600, 19);
                    color: var(--black);
                }
            }
            .ant-select-arrow {
                svg {
                    path {
                        fill: var(--black);
                    }
                }
            }
        }
        .ant-select-dropdown {
            top: 60px !important;
            border-radius: 0 0 0 6px;
            padding: 0;
            .ant-select-item-option {
                border-radius: 0;
                padding: 10px 20px;
                background-color: var(--secondWhite);
                color: var(--black);

                &.ant-select-item-option-selected {
                    background-color: transparent;
                    background-color: var(--secondWhite);
                    color: var(--black);
                }
                &:hover {
                    background-color: var(--gray);
                    color: var(--black);
                }
            }
        }
    }
}
