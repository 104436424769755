@import "/src/styles/mixins";
@import "/src/styles/pallete";

.monthPickerWrapper {
    position: relative;
    label {
        margin-bottom: 4px;
        @include fontRem(14, 400, 20);
        color: var(--black);
    }
    :global {
        .react-datepicker-wrapper {
            display: none;
        }

        .react-datepicker-popper {
            transform: none !important;
            top: 100% !important;
            left: 0 !important;
        }
    }
    .monthPickerList {
        list-style-type: none;
        .monthPickerItem {
            position: relative;
            &:hover {
                .selectMonth {
                    border-color: var(--blue);
                    filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
                }
            }
            .selectMonth {
                background-color: var(--secondWhite);
                height: 40px;
                @include flex-center-vert;
                padding: 11px;
                border-radius: 6px;
                border: 1px solid var(--black);
                position: relative;
                cursor: pointer;
                @include fontRem(14, 400, 17);
                &.openedPicker {
                    border-color: var(--blue);
                    border-radius: 6px 6px 0 0;
                    filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
                }
                &::after {
                    content: "";
                    @include absoluteCenter(vertical);
                    height: 22px;
                    width: 1px;
                    background-color: #c1cada;
                    right: 37px;
                    user-select: none;
                    pointer-events: none;
                }
                span {
                    @include absoluteCenter(vertical);
                    user-select: none;
                    pointer-events: none;
                    right: 11px;
                    @include flex-center-vert;
                }
            }
            .subList {
                list-style-type: none;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: var(--white);
                z-index: 890;
                border: 1px solid var(--black);
                border-radius: 0 0 6px 6px;
                padding-bottom: 19px;
                display: none;
                border-top: 0;
                &.openedList {
                    display: block;
                    border-color: var(--blue);
                }
                .yearPicker {
                    margin: 15px;
                    padding: 8px 12px;
                    @include flex-center;
                    gap: 12px;
                    background: #f0f2f5;
                    border-radius: 3px;
                    color: var(--blueGray);
                    span {
                        cursor: pointer;
                        &:first-child {
                            transform: rotate(90deg);
                        }

                        &:last-child {
                            transform: rotate(-90deg);
                        }
                        &.disabledBtn {
                            opacity: 0.5;
                            cursor: not-allowed;
                        }
                    }
                    p {
                        @include fontRem(14, 600, 17);
                    }
                }
                .monthItem {
                    padding: 12px 28px;
                    @include fontRem(14, 500, 17);
                    cursor: pointer;
                    border-top:0.2px solid var(--white);
                    border-bottom: 0.2px solid var(--white);
                    &.selectedMonth {
                        background-color: var(--gray);
                        border-top:0.2px solid var(--gray);
                        border-bottom: 0.2px solid var(--gray);
                    }
                    &:hover {
                        box-sizing: border-box;
                        background-color: var(--gray);
                        border-top:0.2px solid var(--blue);
                        border-bottom: 0.2px solid var(--blue);
                    }
                }
                .customButton {
                    padding: 8px 28px;
                    @include fontRem(14, 500, 17);
                    cursor: pointer;
                    text-align: center;
                    background: var(--lightBlue);
                    border-radius: 6px;
                    margin: 0 15px;
                }
                .allButton {
                    padding: 8px 28px;
                    @include fontRem(14, 500, 17);
                    cursor: pointer;
                    text-align: center;
                    border: 1px solid var(--lightBlue);
                    border-radius: 6px;
                    margin: 0 15px 10px;
                }
            }
        }
    }
    .rangePicker {
        :global {
            .ant-picker {
                width: 100%;
                height: 40px;
                padding-left: 17px;
                border-color: var(--lightGray);
                &:hover {
                    filter: drop-shadow(0px 4px 10px rgba(102, 118, 149, 0.2));
                }
                &::after {
                    content: "";
                    @include absoluteCenter(vertical);
                    height: 22px;
                    width: 1px;
                    background-color: #c1cada;
                    right: 37px;
                    user-select: none;
                    pointer-events: none;
                }
            }
            .ant-picker-time-panel {
                display: none !important;
            }
        }
    }
    .goBackLabelWrapper {
        margin-bottom: 8px;
        @include flex-center-vert;
        justify-content: space-between;
        padding: 4px 18px 4px 12px;
        border-bottom: 1px solid var(--gray);
        border-top: 1px solid var(--gray);
        p {
            font-weight: 600;
        }
        button {
            padding-left: 0 !important;
            svg {
                width: 16px;
            }
        }
    }
}
