@import "src/styles/mixins";
@import "src/styles/pallete";

.blacklistOverlay {
  padding: 9px 0 18px 0;
  .blacklist {
    background: var(--white);
    box-shadow: 0 4px 10px rgba(102, 118, 149, 0.2);
    padding: 24px 37px 32px;
    [class*="dangerBtn"] {
      button {
        height: 42px;
        @include flex-center-vert;
        @include fontRem(16, 600, 19);
      }
    }
  }
  .blackListButtons {
    @include flex-center-vert;
    justify-content: flex-end;
  }
}
